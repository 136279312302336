import {
  ghostDark,
  ghostLight,
  primary,
  primarySmall,
  secondary,
  secondarySmall,
  tertiary,
  tertiarySmall,
} from './buttons';
import { TStyles } from './moduleTypes';

const defaultStyle = {
  color: 'link',
  textDecoration: 'none' as const,
  '&.active, &.storybook_pseudo_class--active': {
    color: 'linkActive',
  },
  '&:hover, &.storybook_pseudo_class--hover': {
    color: 'linkHover',
  },
};

const bold = {
  ...defaultStyle,
  fontWeight: 'bold',
};

const uppercase = {
  ...defaultStyle,
  textTransform: 'uppercase' as const,
};

const boldUppercase = {
  ...defaultStyle,
  ...bold,
  ...uppercase,
};

const cloudPdf = {
  ...boldUppercase,
};

const cloudPdfBook = {
  ...cloudPdf,
  color: 'linkOnCloudPdfBackgroundVariantBook',
  '&.active, &.storybook_pseudo_class--active': {
    color: 'linkOnCloudPdfBackgroundVariantBookActive',
  },
  '&:hover, &.storybook_pseudo_class--hover': {
    color: 'linkOnCloudPdfBackgroundVariantBookHover',
  },
};

const nav = {
  variant: 'text.nav',
  mr: 6,
  py: 2,
  display: 'inline-block',
  '&:hover, &:focus, &.active': {
    color: 'text',
    borderColor: 'currentColor',
  },
  borderRadius: 0,
};

const inputLinks = {
  ...defaultStyle,
  ...boldUppercase,

  pl: [2, 3, 4],
};

const footerLinks = {
  ...defaultStyle,
  '&:hover, &.storybook_pseudo_class--hover': {
    color: 'footerLinkHover',
  },
  '&.active, &.storybook_pseudo_class--active': {
    fontWeight: 'bold',
    color: 'footerLinkActive',
  },
};

const baseStyleButton = {
  display: ['block', 'inline-block', 'inline-block'],
  textAlign: 'center' as const,
  textDecoration: 'none' as const,
};

const buttonPrimary = {
  ...baseStyleButton,
  ...primary,
};

const buttonSecondary = {
  ...baseStyleButton,
  ...secondary,
};

const buttonTertiary = {
  ...baseStyleButton,
  ...tertiary,
};

const buttonPrimarySmall = {
  ...baseStyleButton,
  ...primarySmall,
};

const buttonSecondarySmall = {
  ...baseStyleButton,
  ...secondarySmall,
};

const buttonTertiarySmall = {
  ...baseStyleButton,
  ...tertiarySmall,
};

const buttonGhostLight = {
  ...baseStyleButton,
  ...ghostLight,
};

const buttonGhostDark = {
  ...baseStyleButton,
  ...ghostDark,
};

const styles = {
  default: defaultStyle,

  bold,
  uppercase,
  boldUppercase,
  cloudPdf,
  cloudPdfBook,
  nav,
  inputLinks,
  footerLinks,
  buttonPrimary,
  buttonSecondary,
  buttonTertiary,
  buttonPrimarySmall,
  buttonSecondarySmall,
  buttonTertiarySmall,
  buttonGhostLight,
  buttonGhostDark,
};

const links: TStyles<typeof styles> = styles;
type TLinkVariant = `links.${keyof typeof links}`;

export { links as default, TLinkVariant, nav };
