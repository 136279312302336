import { ThemeUIStyleObject } from 'theme-ui';
import { text } from './typography';

// ⚠️
// ⚠️ Also look at the formInputGrid, there are styles for form elements as well! ⚠️
// ⚠️
// The gridRow and gridColumn of the input elements INSIDE a formInputGrid are set
// in the styles of the formInputGrid.
// @see packages/ui-library/src/gatsby-plugin-theme-ui/grids.ts

const resetThemeUiFormStyles: ThemeUIStyleObject = {
  display: 'block',

  m: 0,
  p: 0,
  minWidth: 0,
  width: '100%',

  appearance: 'none',

  backgroundColor: 'white',
  boxShadow: 'none',

  borderWidth: 'px',
  borderStyle: 'solid',
  borderColor: 'gray',

  borderRadius: 0,
};

const baseStyle: ThemeUIStyleObject = {
  ...resetThemeUiFormStyles,

  ...text.default,

  height: [12, 14, 14],
  pt: 2,
  pl: [3, 4, 4],

  fontFamily: 'body',

  '&:focus': {
    borderColor: 'primary',
  },

  '&::placeholder, &:invalid': {
    color: 'gray.7',
    opacity: 1,
    transition: 'all 0.3s',
  },

  '&:placeholder-shown:not(:focus)::placeholder': {
    opacity: 0,
  },

  // This styles the label!
  '&:placeholder-shown:not(:focus) + label': {
    ...text.default,
    pt: [3, 4, 4],
    color: 'gray.7',
  },
};

const input: ThemeUIStyleObject = {
  ...baseStyle,
  color: 'inputTextColor',
};

const select: ThemeUIStyleObject = {
  ...baseStyle,
  backgroundImage:
    'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23100F17%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 20px top 50%',
  backgroundSize: '0.5em auto',
  /* Hide arrow icon in IE browsers */
  '&::-ms-expand': {
    display: 'none',
  },

  '& + svg': {
    display: 'none',
  },
};

const forms: Record<string, ThemeUIStyleObject> = {
  label: {
    ...text.tiny,
    color: 'formLabel',

    m: 0,
    p: 0,
    pl: [3, 4, 4],
    pt: 1,

    gridRow: 1,
    gridColumn: '1 / -1',
    zIndex: 1,

    pointerEvents: 'none',
    transition: 'all 0.32s 0.1s',
  },

  input,
  inputWithoutLabel: {
    ...input,
    pt: 0,
  },

  select,
  selectWithoutLabel: {
    ...select,
    pt: 0,
  },

  textarea: {
    ...baseStyle,
    height: 'auto',
    fontFamily: 'body',
    pt: 5,
    color: 'textAreaColor',
  },

  radio: {
    // Not implemented yet.
  },

  checkbox: {
    // Not implemented yet.
  },

  slider: {
    mt: 8,
    height: 1,
    appearance: 'auto',

    '&::-webkit-slider-thumb, &::-moz-range-thumb': {
      appearance: 'none',

      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'gray.3',

      height: 6,
      width: 6,
      borderRadius: 2,

      background: 'white',
      cursor: 'pointer',
    },
  },
};

export { forms as default };
