import { NoParameterError, NoResponseReceivedError } from '../errors';
import { ILocale, TCountryCode, TCurrency } from '../locale';
import { API_ENDPOINTS } from '../utils';

interface IProductResponseData {
  productId: string;
  country: TCountryCode;
  name: string;
  price: number | null;
  available: boolean;
  currency: TCurrency;
}

const getProducts = async (
  locale: ILocale
): Promise<IProductResponseData[]> => {
  const DEBUG_NAME = 'getProducts';

  if (!locale?.countryCode) {
    throw new NoParameterError();
  }

  const url = `${API_ENDPOINTS.BEMER_MARKETING}/v1/product?country=${locale.countryCode}`;

  console.log(`[${DEBUG_NAME}] url:`, url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log(`[${DEBUG_NAME}] response:`, response);
    if (!response) {
      throw new NoResponseReceivedError();
    }

    return data;
  } catch (error) {
    console.log(`[${DEBUG_NAME}] error`, error);
    throw error;
  }
};

const getProduct = async (
  locale: ILocale,
  productId: string
): Promise<IProductResponseData> => {
  const DEBUG_NAME = 'getProduct';

  if (!locale?.id || !productId) {
    throw new NoParameterError();
  }

  const url = `${API_ENDPOINTS.BEMER_MARKETING}/v1/product/${locale.id}/${productId}`;

  console.log(`[${DEBUG_NAME}] url:`, url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log(`[${DEBUG_NAME}] response:`, response);
    if (!response) {
      throw new NoResponseReceivedError();
    }

    return data;
  } catch (error) {
    console.log(`[${DEBUG_NAME}] error`, error);
    throw error;
  }
};

export { getProduct, getProducts, IProductResponseData };
