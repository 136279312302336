import { aspectRatios, RATIOS, roundAspectRatio } from './ascpectRatios';
import {
  CLOUDINARY_HOSTNAME,
  FUNCTIONS_ENDPOINT_URL_PREFIX,
  MUX_IMAGE_HOSTNAME,
  MUX_STREAM_HOSTNAME,
} from './globals';
import { IMuxVideoAssetData } from './types';

const CLOUDINARY_MAPPED_FILES_FOLDER =
  process.env.CLOUDINARY_MAPPED_FILES_FOLDER ||
  process.env.STORYBOOK_CLOUDINARY_MAPPED_FILES_FOLDER ||
  process.env.GATSBY_CLOUDINARY_MAPPED_FILES_FOLDER ||
  process.env.SANITY_STUDIO_CLOUDINARY_MAPPED_FILES_FOLDER ||
  process.env.SANITY_STUDIO_PARTNER_LIBRARY_CLOUDINARY_MAPPED_FILES_FOLDER;

const CLOUDINARY_MAPPED_IMAGES_FOLDER =
  process.env.CLOUDINARY_MAPPED_IMAGES_FOLDER ||
  process.env.STORYBOOK_CLOUDINARY_MAPPED_IMAGES_FOLDER ||
  process.env.GATSBY_CLOUDINARY_MAPPED_IMAGES_FOLDER ||
  process.env.SANITY_STUDIO_CLOUDINARY_MAPPED_IMAGES_FOLDER ||
  process.env.SANITY_STUDIO_PARTNER_LIBRARY_CLOUDINARY_MAPPED_IMAGES_FOLDER;

const CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER =
  process.env.CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER ||
  process.env.STORYBOOK_CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER ||
  process.env.GATSBY_CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER ||
  process.env.SANITY_STUDIO_CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER ||
  'partner_profile_images';

const CLOUDINARY_CLOUD_NAME =
  process.env.CLOUDINARY_CLOUD_NAME ||
  process.env.STORYBOOK_CLOUDINARY_CLOUD_NAME ||
  process.env.GATSBY_CLOUDINARY_CLOUD_NAME ||
  process.env.SANITY_STUDIO_CLOUDINARY_CLOUD_NAME ||
  process.env.SANITY_STUDIO_PARTNER_LIBRARY_CLOUDINARY_CLOUD_NAME;

const CLOUDINARY_BASE_URL = `${CLOUDINARY_HOSTNAME}/${CLOUDINARY_CLOUD_NAME}/image/upload`;

const CLOUDINARY_FALLBACK_IMAGE = 'fallback_emn3wg';

const MUX_DEFAULT_THUMBNAIL_TIMESTAMP = 6;

const MUX_VIDEO_UPLOAD_ENDPOINT = `${FUNCTIONS_ENDPOINT_URL_PREFIX}muxVideoUpload`;

// Get DEVICE_PIXEL_RATIO from device.
const FALLBACK_DPR = 2.0;
const DEVICE_PIXEL_RATIO =
  typeof window !== 'undefined'
    ? window.devicePixelRatio || FALLBACK_DPR
    : FALLBACK_DPR;

/**
 * Gets the width of the given HTML element and rounds it to full 100 pixels if the element is
 * wider than 100px or rounds to full 10 pixel if the element is smaller.
 *
 * @param element
 */
const getMediaWidth = (element?: HTMLElement): number => {
  const FALLBACK_WIDTH = 500;
  const ROUNDING_SCALE_LARGE = 100;
  const ROUNDING_SCALE_SMALL = 10;

  const elementWidth = (element && element.scrollWidth) || FALLBACK_WIDTH;
  const widthRoundingScale =
    elementWidth > 100 ? ROUNDING_SCALE_LARGE : ROUNDING_SCALE_SMALL;

  return Math.ceil(elementWidth / widthRoundingScale) * widthRoundingScale;
};

interface IVideoUrls {
  videoUrl: string;
  videoPreviewUrl: string;
  previewImageUrl: string;
}

/**
 * Returns the urls for requesting a video, video preview and preview image from mux.com.
 *
 * @param playbackId
 * @param previewPlaybackId
 * @param thumbTime
 */
const getVideoUrls = (
  playbackId: string,
  previewPlaybackId: string,
  thumbTime = MUX_DEFAULT_THUMBNAIL_TIMESTAMP
): IVideoUrls => ({
  videoUrl: `${MUX_STREAM_HOSTNAME}/${playbackId}.m3u8`,
  videoPreviewUrl: `${MUX_STREAM_HOSTNAME}/${previewPlaybackId}.m3u8`,
  previewImageUrl: `${MUX_IMAGE_HOSTNAME}/${playbackId}/thumbnail.png?time=${thumbTime}`,
});

interface IPdfUrls {
  pdfUrl: string;
  previewImageUrl: string;
  previewImageFallbackUrl: string;
  previewImageNotFoundUrl: string;
}

/**
 * Returns the urls for requesting a pdf from cloudinary.
 *
 * @param url
 * @param originalFilename
 * @param aspectRatio
 */
const getPdfUrls = (
  url: string,
  originalFilename: string,
  aspectRatio = aspectRatios[RATIOS.RATIO_5_7].ratio
): IPdfUrls => {
  const pdfFileName = url.split('/').pop();
  const attachmentFileName =
    originalFilename && originalFilename.replace(/\.pdf/, '');
  const previewFileName = pdfFileName && pdfFileName.replace(/\.pdf/, '');

  // Limits the size of the preview image.
  const PREVIEW_IMAGE_WIDTH = 640;
  const previewImageHeight = Math.ceil(PREVIEW_IMAGE_WIDTH * aspectRatio);

  const IMAGE_FORMAT_TRANSFORMATIONS = 'q_auto,f_png';

  const pdfUrl = `${CLOUDINARY_BASE_URL}/fl_attachment:${attachmentFileName}/${CLOUDINARY_MAPPED_FILES_FOLDER}/${pdfFileName}`;

  const previewImageUrl = `${CLOUDINARY_BASE_URL}/c_limit,w_${PREVIEW_IMAGE_WIDTH},h_${previewImageHeight}/ar_${aspectRatio},c_fill,g_auto/${IMAGE_FORMAT_TRANSFORMATIONS}/v1/${CLOUDINARY_MAPPED_FILES_FOLDER}/${pdfFileName}`;
  const previewImageFallbackUrl = `${CLOUDINARY_BASE_URL}/c_limit,w_${PREVIEW_IMAGE_WIDTH},h_${previewImageHeight}/ar_${aspectRatio},c_fill,g_auto/${IMAGE_FORMAT_TRANSFORMATIONS}/v1/${CLOUDINARY_MAPPED_FILES_FOLDER}/${previewFileName}`;
  const previewImageNotFoundUrl = `${CLOUDINARY_BASE_URL}/ar_${aspectRatio},c_fill/${IMAGE_FORMAT_TRANSFORMATIONS}/v1/${CLOUDINARY_FALLBACK_IMAGE}`;
  return {
    pdfUrl,
    previewImageUrl,
    previewImageFallbackUrl,
    previewImageNotFoundUrl,
  };
};

interface IImageUrls {
  imageUrl: string;
}

/**
 * Returns the url for requesting the profile image of the given partner from cloudinary.
 *
 * @param partnerId
 * @param imageWidth
 */
const getPartnerImageUrls = (
  partnerId: string,
  imageWidth = 100
): IImageUrls => {
  const imageFileName = `${CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER}/${partnerId}.jpg`;

  const imageUrl = `${CLOUDINARY_BASE_URL}/d_${CLOUDINARY_MAPPED_PARTNER_IMAGES_FOLDER}:fallback.png/ar_1,w_${imageWidth},dpr_${DEVICE_PIXEL_RATIO},c_thumb,g_face,z_0.8,q_auto,f_auto/v1/${imageFileName}`;
  return {
    imageUrl,
  };
};

/**
 * Returns the url for requesting the sanity image asset from cloudinary.
 *
 * For more infos about the structure of the image asset see:
 * https://www.sanity.io/docs/image-type#df3f768ce073
 *
 * @param url - string looks like https://cdn.sanity.io/images/3do82whm/production/223c27c1f0e75fe1ef494333738e2d16a8539e6a-1365x1364.svg
 * @param imageElement
 * @param aspectRatio
 * @param withTransparentBackground
 */
const getImageUrls = (
  url: string,
  imageElement?: HTMLImageElement,
  aspectRatio = aspectRatios[RATIOS.RATIO_16_9].ratio,
  withTransparentBackground = false
): IImageUrls => {
  const imageFileName = url.split('/').pop();
  const roundedAspectRatio = roundAspectRatio(aspectRatio);

  const imageWidth = getMediaWidth(imageElement);

  const transparentTransformation = withTransparentBackground
    ? ',e_make_transparent:8'
    : '';

  const imageUrl = `${CLOUDINARY_BASE_URL}/d_${CLOUDINARY_FALLBACK_IMAGE}.png/ar_${roundedAspectRatio},w_${imageWidth}${transparentTransformation},dpr_${DEVICE_PIXEL_RATIO},c_fill,g_auto,q_auto,f_auto/v1/${CLOUDINARY_MAPPED_IMAGES_FOLDER}/${imageFileName}`;
  return {
    imageUrl,
  };
};

/**
 * Returns the encrypted header to perform API calls to mux.com.
 */
const getMuxRequestHeaders = (): Record<string, string> => ({
  'Content-Type': 'application/json',
});

/**
 * Fetches the available asset info for the given MUX video asset id from mux.com.
 *
 * @param assetId
 * @param fetchFunction
 */
const getAssetDataFromMuxFunction = async (
  assetId: string,
  fetchFunction = fetch
): Promise<any> => {
  const requestInit = {
    method: 'GET',
    headers: getMuxRequestHeaders(),
  };

  let parsedResponse;
  try {
    const response = await fetchFunction(
      `${MUX_VIDEO_UPLOAD_ENDPOINT}?assetId=${assetId}`,
      requestInit
    );
    parsedResponse = await response.json();
    if (!response || !parsedResponse) {
      throw new Error('No response received.');
    }
  } catch (error) {
    console.error(error);
  }

  return parsedResponse;
};

/**
 * Returns the available asset info for the given MUX video asset id from mux.com.
 * This function will wait until the asset on mux.com is in state `ready` before resolving.
 *
 * @param assetId
 * @param filename
 * @param fetchFunction
 */
const getAssetDataFromMuxForCompletedUpload = async (
  assetId: string,
  filename: string,
  fetchFunction = fetch
): Promise<IMuxVideoAssetData> => {
  // 1. Wait until the asset is in the state `ready` and then ...
  let intervalId: number;
  return new Promise((resolve) => {
    const check = async () => {
      const assetData = await getAssetDataFromMuxFunction(
        assetId,
        fetchFunction
      );
      if (assetData.status !== 'ready') {
        console.log(
          ` ... Waiting for complete upload and processing of video "${filename}" at mux.com`
        );
      } else {
        // 2. ... return the asset data.
        window.clearInterval(intervalId);
        resolve(assetData);
      }
    };
    check();
    intervalId = window.setInterval(check, 2000);
  });
};

/**
 * Uses a netlify function to create a new asset by pulling the video from
 * the sanityAssetUrl to MUX.
 *
 * @see https://docs.mux.com/api-reference/video#operation/create-asset
 *
 * @param sanityAssetUrl
 * @param fetchFunction
 */
const pullVideoFromSanityToMux = async (
  sanityAssetUrl: URL,
  fetchFunction = fetch
): Promise<any> => {
  const pullVideoFromSanityToMuxPayload = {
    input: [
      {
        url: sanityAssetUrl,
      },
    ],
  };

  const requestInit = {
    method: 'POST',
    headers: getMuxRequestHeaders(),
    body: JSON.stringify(pullVideoFromSanityToMuxPayload),
  };

  try {
    return await fetchFunction(MUX_VIDEO_UPLOAD_ENDPOINT, requestInit);
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * Uses the given URL to create a video asset on mux.com.
 * This function will wait until the asset on mux.com is in state `ready` before resolving.
 *
 * @see https://docs.mux.com/guides/video/stream-video-files
 *
 * @param sanityAssetUrl
 * @param filename
 * @param fetchFunction
 */
const createVideoAssetOnMux = async (
  sanityAssetUrl: URL,
  filename: string,
  fetchFunction = fetch
): Promise<IMuxVideoAssetData | null> => {
  try {
    const response = await pullVideoFromSanityToMux(
      sanityAssetUrl,
      fetchFunction
    );

    const parsedResponse = await response.json();

    const muxAssetId = parsedResponse.data && parsedResponse.data.id;
    return await getAssetDataFromMuxForCompletedUpload(
      muxAssetId,
      filename,
      fetchFunction
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Creates a preview video of an existing MUX video asset at mux.com.
 *
 * @see https://docs.mux.com/guides/video/create-clips-from-your-videos
 *
 * @param assetId
 * @param startTime
 * @param fetchFunction
 */
const createPreviewVideoAssetOnMux = async (
  assetId: string,
  startTime: number,
  fetchFunction = fetch
): Promise<IMuxVideoAssetData> => {
  const createPreviewPayload = {
    input: [
      {
        url: `mux://assets/${assetId}`,
        start_time: startTime,
        end_time: startTime + 5,
      },
    ],
    playback_policy: ['public'],
    mp4_support: 'standard',
  };

  const requestInit = {
    method: 'PUT',
    headers: getMuxRequestHeaders(),
    body: JSON.stringify(createPreviewPayload),
  };
  let parsedResponse;
  try {
    const response = await fetchFunction(
      MUX_VIDEO_UPLOAD_ENDPOINT,
      requestInit
    );
    parsedResponse = await response.json();
  } catch (error) {
    console.error(error);
  }

  return getAssetDataFromMuxForCompletedUpload(
    parsedResponse.data.id,
    `Preview video for ${assetId}`,
    fetchFunction
  );
};

/**
 * Helper to format the given seconds to the format hh:mm:ss
 *
 * @param seconds
 */
const getFormattedDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor(seconds / 60) - hours * 60;
  const remainingSeconds = Math.floor(seconds % 60);

  const padTime = (value: number): string => value.toString().padStart(2, '0');

  return `${padTime(hours)}:${padTime(minutes)}:${padTime(remainingSeconds)}`;
};

export {
  MUX_DEFAULT_THUMBNAIL_TIMESTAMP,
  getMuxRequestHeaders,
  createVideoAssetOnMux,
  getVideoUrls,
  getPdfUrls,
  getImageUrls,
  getPartnerImageUrls,
  createPreviewVideoAssetOnMux,
  getFormattedDuration,
};
