import {
  NoDataFoundError,
  NoHandleError,
  NoLatitudeError,
  NoLocaleError,
  NoLongitudeError,
  NoParameterError,
  NoPartnerFoundError,
  NoResponseReceivedError,
} from '../errors';
import { BEMER_PARTNER_LEVELS } from '../globals';
import { ILocale, TCountryCode } from '../locale';
import { API_ENDPOINTS } from '../utils';

interface IPartnerFinderData {
  finderData: {
    countryCode?: TCountryCode;
    latitude?: number;
    longitude?: number;
    partnerFinderAddress?: string;
    shortAddress?: string;
    partnerFinderCompany?: string;
    partnerFinderName: string;
    partnerFinderPhone?: string;
  };
  partnerData: {
    customerNo: string;
    handle: string;
    levelName?: string;
    level?: number;
    name: string;
  };
}
const { BPPLUS: defaultPartnerLevel } = BEMER_PARTNER_LEVELS;
const INCLUDE_MANUALLY_CREATED_PARTNERS_IN_QUERY = true;

/**
 * Fetches  a list of partner data from the backend for the given searchString.
 *
 * @param searchString
 * @param locale
 * @param partnerLevel
 */
const fetchPartnerSuggestions = async (
  searchString?: string,
  locale?: ILocale,
  partnerLevel = defaultPartnerLevel
): Promise<IPartnerFinderData[]> => {
  if (!searchString) {
    throw new NoParameterError('No name given.');
  }
  if (!locale) {
    throw new NoLocaleError();
  }

  const url = `${API_ENDPOINTS.PARTNER_FINDER}/selectBox`;
  const query = `
  {
    publicPartnerByName(query: "${searchString}", level: "${partnerLevel}", externalCountries: ${INCLUDE_MANUALLY_CREATED_PARTNERS_IN_QUERY}){
      finderData {
        countryCode
        latitude
        longitude
        partnerFinderAddress
        shortAddress
        partnerFinderCompany
        partnerFinderName
        partnerFinderPhone
      }

      partnerData {
        customerNo
        handle
        levelName
        level
        name
      }
    }
  }`;

  const requestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  try {
    const response = await fetch(url, requestInit);
    const parsedResponse = await response.json();

    if (!response) {
      throw new NoResponseReceivedError();
    }
    if (!parsedResponse) {
      throw new NoPartnerFoundError();
    }
    if (!parsedResponse.data) {
      throw new NoDataFoundError();
    }
    if (!parsedResponse.data.publicPartnerByName) {
      throw new Error('No publicPartnerByName in response found');
    }
    return parsedResponse.data.publicPartnerByName;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Fetches the partner data from the backend for the given partner handle and optional locale.
 *
 * @param latitude
 * @param longitude
 * @param locale
 * @param partnerLevel
 */
const fetchPartnersByLocation = async (
  latitude?: number,
  longitude?: number,
  locale?: ILocale,
  partnerLevel = defaultPartnerLevel
): Promise<IPartnerFinderData[]> => {
  if (!latitude) {
    throw new NoLatitudeError();
  }
  if (!longitude) {
    throw new NoLongitudeError();
  }
  if (!locale) {
    throw new NoLocaleError();
  }
  const url = `${API_ENDPOINTS.PARTNER_FINDER}/selectBox`;
  const query = `
  {
    publicPartnerByLocation(level: "${partnerLevel}", latitude: ${latitude}, longitude: ${longitude}, countryCode: "${locale.countryCode.toUpperCase()}", externalCountries: ${INCLUDE_MANUALLY_CREATED_PARTNERS_IN_QUERY}) {
      finderData {
        countryCode
        latitude
        longitude
        partnerFinderAddress
        shortAddress
        partnerFinderCompany
        partnerFinderName
        partnerFinderPhone
      }

      partnerData {
        customerNo
        handle
        levelName
        level
        name
      }
    }
  }`;

  const requestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  try {
    const response = await fetch(url, requestInit);
    const parsedResponse = await response.json();

    if (!response) {
      throw new NoResponseReceivedError();
    }
    if (!parsedResponse) {
      throw new NoPartnerFoundError();
    }
    if (!parsedResponse.data) {
      throw new NoDataFoundError();
    }
    if (!parsedResponse.data.publicPartnerByLocation) {
      throw new Error('No publicPartnerByLocation in response found');
    }
    return parsedResponse.data.publicPartnerByLocation;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Fetches the partner data from the backend for the given partner handle and optional locale.
 *
 * HINT: resolver does not use the locale param -> partnerHandles are unique across all regions
 *
 * @param handle
 * @param locale
 */
const fetchPartnerByHandle = async (
  handle?: string,
  locale?: ILocale
): Promise<IPartnerFinderData> => {
  if (!handle) {
    throw new NoHandleError();
  }
  const url = `${API_ENDPOINTS.PARTNER_FINDER}/selectBox`;
  const localeParam = locale ? `, locale: "${locale.id}"` : '';
  const query = `
  {
    publicPartnerByHandle(handle: "${handle}", externalCountries: ${INCLUDE_MANUALLY_CREATED_PARTNERS_IN_QUERY}${localeParam}) {
      finderData {
        countryCode
        latitude
        longitude
        partnerFinderAddress
        shortAddress
        partnerFinderCompany
        partnerFinderName
        partnerFinderPhone
      }

      partnerData {
        customerNo
        handle
        levelName
        level
        name
      }
    }
  }`;

  const requestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  try {
    const response = await fetch(url, requestInit);
    const parsedResponse = await response.json();
    if (!response) {
      throw new NoResponseReceivedError();
    }
    if (!parsedResponse) {
      throw new NoPartnerFoundError();
    }
    if (!parsedResponse.data) {
      throw new NoDataFoundError();
    }
    if (!parsedResponse.data.publicPartnerByHandle) {
      throw new Error('No publicPartnerByHandle in response found');
    }
    return parsedResponse.data.publicPartnerByHandle;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Checks if a partner handle exists in the backend.
 *
 * @param handle
 * @param locale
 */
const doesPartnerHandleExist = async (
  handle?: string,
  locale?: ILocale
): Promise<boolean> => {
  if (!handle) {
    return false;
  }
  try {
    const partnerData = await fetchPartnerByHandle(handle, locale);

    return !!partnerData.partnerData.handle;
  } catch (error) {
    return false;
  }
};

/**
 * Checks if a user can be redirected to the signup page of a given partner.
 */
const doesPartnerSupportSignup = (partner?: IPartnerFinderData): boolean => {
  if (!partner?.finderData || !partner?.partnerData) {
    return false;
  }
  const { countryCode } = partner.finderData;
  const { level } = partner.partnerData;

  if (!countryCode || level === undefined) {
    return false;
  }

  return level >= 40;
};

export {
  fetchPartnerByHandle,
  fetchPartnerSuggestions,
  fetchPartnersByLocation,
  doesPartnerHandleExist,
  doesPartnerSupportSignup,
  IPartnerFinderData,
};
