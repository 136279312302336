import { v4 as uuidv4 } from 'uuid';
import {
  BadRequestError,
  NoResponseReceivedError,
  UnknownError,
} from '../errors';
import { NoWindowError } from '../errors/NoWindowError';
import { TLocaleId } from '../locale';
import {
  API_ENDPOINTS,
  defaultLocale,
  getCookieDomain,
  HttpStatusCode,
} from '../utils';

/**
 * Base interface for visitor settings.
 *
 * It defines all fields in the context of the visitor settings.
 * It is not used by any code (not exported).
 * The exported types are created by using this interface.
 *
 * New fields are only required to be added in this interface.
 *
 * @see https://dev.bemer.services/openapi/#/visitor
 */

interface IVisitorSettingsData {
  locale?: TLocaleId;
  partnerHandle?: string;
  error?: boolean;
}

// All fields are returned by the backend.
type TVisitorSettingsResponseData = IVisitorSettingsData;

// It is possible to update only one field.
type TVisitorSettingsUpdateData = Partial<IVisitorSettingsData>;

// Keys of the fields which can be deleted in the backend.
type TVisitorSettingsDeleteKey = keyof IVisitorSettingsData;

// For the payload to modify (update, delete) the the fields in the backend:
// Also, it is possible to only update a part of the fields.
type TVisitorSettingsUpdatePayload = {
  [P in keyof IVisitorSettingsData]?: { newValue: IVisitorSettingsData[P] };
};
type TVisitorSettingsDeletePayload = {
  [P in keyof IVisitorSettingsData]?: { newValue: null };
};

/**
 * Base interface for visitor send-contact-request.
 *
 * @see https://dev.bemer.services/openapi/#/visitor
 */
interface IVisitorSendContactRequestPayload {
  name: string;
  email: string;
  message: string;
  log?: boolean;
}

type TVisitorSendContactRequestResponseData = boolean;

/**
 * Helper to get the visitorId from the domain cookie.
 *
 * Since a visitor can open multiple subdomains of different Bemer partners,
 * the visitorId is stored in a cookie, not in localStorage to allow
 * the visitorId to be available across multiple subdomains.
 * - partnerABC.bemergroup.com
 * - partnerYXZ.bemergroup.com
 *
 * If there is no visitor id in the cookie, a new UUID will
 * be created and set in the cookie as new visitor id.
 */
const getVisitorId = (): string => {
  // Do nothing when SSR.
  if (typeof window === 'undefined') {
    throw new NoWindowError();
  }

  const COOKIE_VISITOR_ID_KEY = 'visitorId';

  let visitorId = document?.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith(`${COOKIE_VISITOR_ID_KEY}=`))
    ?.split('=')[1];

  if (!visitorId) {
    visitorId = uuidv4();
    const maxAgeInSeconds = 60 * 60 * 24 * 365; // one year.

    document.cookie = `${COOKIE_VISITOR_ID_KEY}=${visitorId}${getCookieDomain()};max-age=${maxAgeInSeconds};path=/;samesite=strict`;
  }

  return visitorId;
};

/**
 * Fetches the visitor settings from the backend.
 */
const getVisitorSettings = async (): Promise<TVisitorSettingsResponseData> => {
  const url = `${
    API_ENDPOINTS.BEMER_MARKETING
  }/v1/visitor/${getVisitorId()}/settings`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (!response) {
      throw new NoResponseReceivedError();
    }

    return data;
  } catch (error) {
    return {
      locale: defaultLocale.id,
      partnerHandle: undefined,
      error: true,
    };
  }
};

/**
 * Helper to sent PATCH events to the backend to update visitor settings.
 * Can be used to change and to delete values.
 *
 * @param payload
 */
const sendPatchRequest = async (
  payload: TVisitorSettingsDeletePayload | TVisitorSettingsUpdatePayload
): Promise<TVisitorSettingsResponseData> => {
  const url = `${
    API_ENDPOINTS.BEMER_MARKETING
  }/v1/visitor/${getVisitorId()}/settings`;

  const requestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(url, requestInit);
    const data = await response.json();

    if (!response) {
      throw new NoResponseReceivedError();
    }

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Updates properties of the visitor settings in the backend.
 *
 * @param updateData An object containing only the keys (+ new values) to update.
 */
const updateVisitorSettings = (
  updateData: TVisitorSettingsUpdateData
): Promise<TVisitorSettingsResponseData> => {
  if (Object.keys(updateData).length === 0) {
    throw new Error('No data to update the visitor settings given.');
  }

  if (updateData.partnerHandle === '') {
    throw new Error(
      'partnerHandle can not be an empty string. To remove the partnerHandle, use deleteVisitorSettings(["partnerHandle"]) instead.'
    );
  }

  const payload: TVisitorSettingsUpdatePayload = {};
  if (updateData.locale) {
    payload.locale = { newValue: updateData.locale };
  }
  if (updateData.partnerHandle) {
    payload.partnerHandle = { newValue: updateData.partnerHandle };
  }

  if (Object.keys(payload).length === 0) {
    throw new Error('No data to update the visitor settings set.');
  }

  return sendPatchRequest(payload);
};

/**
 * Deletes properties of the visitor settings in the backend.
 *
 * @param keys The keys to delete.
 */
const deleteVisitorSettings = (
  keys: TVisitorSettingsDeleteKey[]
): Promise<TVisitorSettingsResponseData> => {
  if (!keys || keys.length === 0) {
    throw new Error('No keys to delete in the visitor settings given.');
  }

  const payload: TVisitorSettingsDeletePayload = {};
  keys.forEach((key) => {
    payload[key as TVisitorSettingsDeleteKey] = { newValue: null };
  });

  return sendPatchRequest(payload);
};

/**
 * Sends a send-contact-request request to the backend to send an e-mail with the given message.
 *
 * @param payload
 */
const sendContactRequest = async (
  payload: IVisitorSendContactRequestPayload
): Promise<TVisitorSendContactRequestResponseData> => {
  const url = `${
    API_ENDPOINTS.BEMER_MARKETING
  }/v1/visitor/${getVisitorId()}/send-contact-request`;

  const requestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(url, requestInit);

  if (!response) {
    throw new NoResponseReceivedError();
  }

  if (response.status === HttpStatusCode.BAD_REQUEST) {
    throw new BadRequestError(
      ' Call PATCH /v1/visitor/{visitorId}/settings to link a partner first.'
    );
  }

  if (response.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
    throw new Error(
      'The partner handle linked to the visitor could not be resolved to a partner.'
    );
  }

  if (response.status !== HttpStatusCode.OK) {
    throw new UnknownError(`Response status code is: ${response.status}`);
  }
  return true;
};

export {
  getVisitorSettings,
  updateVisitorSettings,
  deleteVisitorSettings,
  sendContactRequest,
  TVisitorSettingsResponseData,
  TVisitorSettingsUpdateData,
  TVisitorSettingsDeleteKey,
  TVisitorSettingsUpdatePayload,
  TVisitorSettingsDeletePayload,
  IVisitorSendContactRequestPayload,
  TVisitorSendContactRequestResponseData,
};
