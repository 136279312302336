const THEME_NAMES = {
  DEFAULT: 'DEFAULT',
  DEFAULT_VETERINARY: 'DEFAULT_VETERINARY',
  HUMAN_LINE: 'HUMAN_LINE',
  VETERINARY_LINE: 'VETERINARY_LINE',
  NEUTRAL: 'NEUTRAL',
  LIGHT_TEXT: 'Light',
  DARK_TEXT: 'Dark',
  LIGHT_TEXT_DEFAULT_VETERINARY: 'LIGHT_TEXT_DEFAULT_VETERINARY',
  DARK_TEXT_DEFAULT_VETERINARY: 'DARK_TEXT_DEFAULT_VETERINARY',
} as const;

type TThemeNames = typeof THEME_NAMES;
type TThemeNamesKeys = keyof TThemeNames;
type TThemeName = TThemeNames[TThemeNamesKeys];

/**
 * Themes in this list will not be available for selection in theme selectors within Sanity
 */
const UNAVAILABLE_THEMES: TThemeNamesKeys[] = [
  'LIGHT_TEXT',
  'DARK_TEXT',
  'DEFAULT_VETERINARY',
  'DARK_TEXT_DEFAULT_VETERINARY',
  'LIGHT_TEXT_DEFAULT_VETERINARY',
];

interface IThemeObject {
  theme: TThemeName;
  parentPage: IThemeObject;
}

const nextTheme = (
  themes: IThemeObject,
  prevThemeHierarchy?: TThemeName[]
): TThemeName[] => {
  const themeHierarchy: TThemeName[] = prevThemeHierarchy || [];
  themeHierarchy.push(themes.theme || undefined);
  if (themes.parentPage) {
    nextTheme(themes.parentPage, themeHierarchy);
  }

  return themeHierarchy;
};

const buildThemes = (themes: IThemeObject): TThemeName[] =>
  nextTheme(themes).filter(Boolean);

const determineUsedImageTheme = (theme: TThemeName, pageTheme: TThemeName) => {
  switch (theme) {
    case THEME_NAMES.LIGHT_TEXT:
      switch (pageTheme) {
        case THEME_NAMES.VETERINARY_LINE:
          return THEME_NAMES.LIGHT_TEXT_DEFAULT_VETERINARY;
        default:
          return THEME_NAMES.LIGHT_TEXT;
      }
    case THEME_NAMES.DARK_TEXT:
      switch (pageTheme) {
        case THEME_NAMES.VETERINARY_LINE:
          return THEME_NAMES.DARK_TEXT_DEFAULT_VETERINARY;
        default:
          return THEME_NAMES.DARK_TEXT;
      }
    default:
      return THEME_NAMES.DEFAULT;
  }
};

const determineUsedComponentTheme = (
  pageTheme: TThemeName,
  moduleTheme?: TThemeName,
  parentComponentTheme?: TThemeName
) => {
  const usedTheme = parentComponentTheme || moduleTheme || pageTheme;
  let usedThemeName: TThemeName;
  /**
   * 1. case: ParentModule can have its own theme. Which can also be not set
   * (i.e. option INHERIT sets moduleTheme to '' [empty string] to let the module use the theme of the page).
   */
  if (moduleTheme !== undefined) {
    switch (usedTheme) {
      case THEME_NAMES.VETERINARY_LINE:
      case THEME_NAMES.HUMAN_LINE:
      case THEME_NAMES.NEUTRAL:
      case THEME_NAMES.DEFAULT_VETERINARY:
      case THEME_NAMES.DEFAULT:
        usedThemeName = usedTheme;
        break;
      case THEME_NAMES.LIGHT_TEXT:
      case THEME_NAMES.DARK_TEXT:
        usedThemeName = determineUsedImageTheme(usedTheme, pageTheme);
        break;
      default:
        switch (pageTheme) {
          case THEME_NAMES.HUMAN_LINE:
            usedThemeName = THEME_NAMES.DEFAULT;
            break;
          case THEME_NAMES.VETERINARY_LINE:
            usedThemeName = THEME_NAMES.DEFAULT_VETERINARY;
            break;
          default:
            usedThemeName = THEME_NAMES.DEFAULT;
            break;
        }
    }
    /**
     * 2. case: ParentModule can't have its own theme (the field is not availabe in the Schema of this module).
     */
  } else {
    switch (usedTheme) {
      case THEME_NAMES.HUMAN_LINE:
        usedThemeName = THEME_NAMES.DEFAULT;
        break;
      case THEME_NAMES.VETERINARY_LINE:
        usedThemeName = THEME_NAMES.DEFAULT_VETERINARY;
        break;
      case THEME_NAMES.NEUTRAL:
        usedThemeName = THEME_NAMES.NEUTRAL;
        break;
      case THEME_NAMES.LIGHT_TEXT:
      case THEME_NAMES.DARK_TEXT:
        usedThemeName = determineUsedImageTheme(usedTheme, pageTheme);
        break;
      default:
        usedThemeName = THEME_NAMES.DEFAULT;
        break;
    }
  }

  return usedThemeName;
};

export {
  buildThemes,
  determineUsedImageTheme,
  determineUsedComponentTheme,
  THEME_NAMES,
  TThemeName,
  UNAVAILABLE_THEMES,
  IThemeObject,
};
