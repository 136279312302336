import { IStylesObject } from './moduleTypes';

const GRID_GAP_MOBILE = 2;
const GRID_GAP_TABLET = 3;
const GRID_GAP_DESKTOP = 4;

const defaultGridGap = [GRID_GAP_MOBILE, GRID_GAP_TABLET, GRID_GAP_DESKTOP];

/**
 * This grid contains 14 columns.
 * The left and right column are able to grow from 0px to 1fr.
 * The 12 middle columns are about 1120px wide.
 *
 * The calculation:
 * In a 16px base font page is 1rem = 16px.
 * The wireframes define a max width of the grid of 1120px.
 * 1120px / 16px = 70rem
 *
 * The grid gap is 1rem (16px).
 * A 12 column grid has 11 gaps (=11rem).
 * The total width of 12 columns is 59rem (70rem - 11rem).
 * So finally one column has a width of 59rem / 12.
 */

const MAX_CONTENT_WIDTH = '1400px';
const NUMBER_MAIN_COLUMNS = 12;

const MAIN_COLUMN_WIDTH = `minmax(0, calc(59rem / ${NUMBER_MAIN_COLUMNS}))`;

const MAX_WIDTH_MAIN_COLUMNS = '70rem';

const fullWidthGrid = {
  gridTemplateColumns: `minmax(0, 1fr) repeat(${NUMBER_MAIN_COLUMNS}, ${MAIN_COLUMN_WIDTH}) minmax(0, 1fr)`,
  gap: defaultGridGap,
  rowGap: [0, GRID_GAP_TABLET, GRID_GAP_DESKTOP],
  maxWidth: MAX_CONTENT_WIDTH,
  m: 'auto',
  bg: 'background',
};

const contentGrid = {
  gridColumn: '2 / -2',
  gridTemplateColumns: `repeat(${NUMBER_MAIN_COLUMNS}, 1fr)`,
  gap: defaultGridGap,
  rowGap: [0, GRID_GAP_TABLET, GRID_GAP_DESKTOP],
};

const formGrid = {
  gridColumn: '2 / -2',
  gridTemplateColumns: '1fr 1fr',
  gap: defaultGridGap,
};

const formInputGrid = {
  gridColumn: '1 / -1',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content min-content',
  gap: 0,

  // ⚠️
  // ⚠️ THIS DEFINES THE GRID ROW AND COLUMN OF THE CHILDREN OF THE formInputGrid! ⚠️
  // ⚠️
  // The gridRow and gridColumn of the elements INSIDE the formInputGrid is set here,
  // since the Theme UI Select renders a <Box> which wraps the select element and there
  // is no way to pass any styles to that Box via sx props.
  // @see https://github.com/system-ui/theme-ui/blob/stable/packages/components/src/Select.js
  '& > *:first-of-type': {
    gridRow: 1,
    gridColumn: '1 / -1',
  },
  // All form field errors (formInputError) need to be rendered as <em>!
  '& > em:first-of-type': {
    gridRow: 2,
  },
};

const grids: IStylesObject = {
  fullWidthGrid,
  contentGrid,
  formGrid,
  formInputGrid,
};

export {
  grids as default,
  defaultGridGap,
  GRID_GAP_MOBILE,
  GRID_GAP_TABLET,
  GRID_GAP_DESKTOP,
  MAIN_COLUMN_WIDTH,
  MAX_CONTENT_WIDTH,
  MAX_WIDTH_MAIN_COLUMNS,
};
