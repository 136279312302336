import {
  TCountryCode,
  TCountryName,
  TRegionCode,
  TRegionName,
} from '../locale';
import {
  IGraphqlBaseObject,
  IGraphqlLocaleIdentifier,
  IGraphqlPageEdge,
} from '../types';
import { buildInternalRoute } from './buildInternalRoute';
import { getMarketingSiteHostUrlForRegion } from './environments';

interface ICountryLanguageAssignment {
  pageUrl: string;
  localeId: string;
  isRegionMasterPrimaryLanguage: boolean | undefined;
}
interface IRegionsWithCountries {
  regions: IRegionWithCountries[];
  countryLanguageAssignmentsForRegion: ICountryLanguageAssignment[];
  _key?: string;
}
interface IRegionWithCountries {
  regionName: TRegionName;
  regionCode: TRegionCode;
  countries: ICountryWithLocales[];
}
interface ICountryWithLocales {
  countryCode: TCountryCode;
  countryName: TCountryName;
  isRegionMaster: boolean;
  localeIdentifiers: IGraphqlLocaleIdentifier[];
}

interface IGraphqlAllRegionCountryLanguageAssignment {
  edges: IGraphqlAllRegionCountryLanguageAssignmentEdge[];
}
interface IGraphqlAllRegionCountryLanguageAssignmentEdge {
  node: IGraphqlRegionCountryLanguageAssignment;
}

interface IGraphqlRegionCountryLanguageAssignment extends IGraphqlBaseObject {
  _type: 'region';
  _id: string;
  regionCode: TRegionCode;
  regionName: TRegionName;
  countries: IGraphqlCountryLanguageAssignments[];
}
interface IGraphqlCountryLanguageAssignments extends IGraphqlBaseObject {
  _type: 'country';
  _id: string;
  countryCode: TCountryCode;
  countryName: TCountryName;
  isRegionMaster: boolean;
  localeIdentifiers: IGraphqlLocaleIdentifier[];
}

const getCountryLanguageAssignmentsForRegion = (
  regionCode: TRegionCode,
  localeIdentifiers: IGraphqlLocaleIdentifier[],
  existingPagesInOtherLocaleIdentifiers?: IGraphqlPageEdge[]
): ICountryLanguageAssignment[] => {
  if (!existingPagesInOtherLocaleIdentifiers) {
    return [];
  }

  const hostUrl = getMarketingSiteHostUrlForRegion(regionCode);
  const activeLocaleIdentifiersForRegion = localeIdentifiers.filter(
    (localeIdentifier) =>
      localeIdentifier?.country?.region?.regionCode === regionCode
  );

  const countryLanguageAssignmentsForRegion =
    existingPagesInOtherLocaleIdentifiers.map((existingPage) => {
      const { node: page } = existingPage;

      return activeLocaleIdentifiersForRegion
        .filter(
          (activeLocale) =>
            activeLocale.languageCode === page.localeIdentifier.languageCode
        )
        .map((locale) => ({
          pageUrl: `${hostUrl}${buildInternalRoute(page, locale)}`,
          localeId: locale.localeId,
          isRegionMasterPrimaryLanguage:
            page.localeIdentifier.isRegionMasterPrimaryLanguage,
        }));
    });

  return countryLanguageAssignmentsForRegion.flat();
};

export {
  getCountryLanguageAssignmentsForRegion,
  IRegionWithCountries,
  IRegionsWithCountries,
  ICountryWithLocales,
  ICountryLanguageAssignment,
  IGraphqlAllRegionCountryLanguageAssignment,
  IGraphqlCountryLanguageAssignments,
  IGraphqlAllRegionCountryLanguageAssignmentEdge,
  IGraphqlRegionCountryLanguageAssignment,
};
