import { NoResponseReceivedError } from '../errors';
import {
  API_ENDPOINTS,
  extendEventsWithCoords,
  extendEventTypeWithIconUrl,
} from '../utils';
import { eventMapCountries } from '../utils/eventMapCountries';
import { IEventTypeResponse, TEvent } from '../utils/eventsApi';

const getEvents = async (
  onlineOnly: boolean
): Promise<{ events: TEvent[] }> => {
  const DEBUG_NAME = 'getEvents';
  const url = `${API_ENDPOINTS.EVENTS}/bemerEvents/jsonMap?onlineOnly=${onlineOnly}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (!response) {
      throw new NoResponseReceivedError();
    }

    const events = extendEventsWithCoords(data);

    return { events };
  } catch (error) {
    console.log(`[${DEBUG_NAME}] error`, error);
    throw error;
  }
};

const getEventTypes = async (): Promise<IEventTypeResponse[]> => {
  const DEBUG_NAME = 'getEventTypes';
  const url = `${API_ENDPOINTS.EVENTS}/search/selectBox`;

  console.log(`[${DEBUG_NAME}] url:`, url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log(`[${DEBUG_NAME}] response:`, response);

    if (!response) {
      throw new NoResponseReceivedError();
    }
    const formattedData: IEventTypeResponse[] = extendEventTypeWithIconUrl(
      // eslint-disable-next-line no-underscore-dangle
      data._embedded.eventTypeCategories
    );
    return formattedData;
  } catch (error) {
    console.log(`[${DEBUG_NAME}] error`, error);
    throw error;
  }
};

export { eventMapCountries, getEvents, getEventTypes };
