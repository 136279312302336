const primary = {
  __default: '#2BB7E7',
  '0': '#E6F6FC',
  '1': '#B7E7F7',
  '2': '#88D7F2',
  '3': '#2DBAE8',
  '4': '#2BB7E7',
  '5': '#259EC8',
  '6': '#1797CB',
  '7': '#1A6C89',
  '8': '#145369',
  '9': '#203b4a',
} as const;

const secondary = {
  __default: '#ff6400',
  '0': '#fff8f4',
  '1': '#ffeadc',
  '2': '#ffd9c2',
  '3': '#ffc7a5',
  '4': '#ffb383',
  '5': '#ff9a5a',
  '6': '#ff6400',
  '7': '#f34211',
  '8': '#e63200',
  '9': '#8d1f01',
} as const;

const gray = {
  __default: '#F2F2F2',
  '0': '#FFFFFF',
  '1': '#F9F9F9',
  '2': '#F2F2F2',
  '3': '#BABABA',
  '4': '#A6A6A6',
  '5': '#909090',
  '6': '#777777',
  '7': '#5C5C5C',
  '8': '#363636',
  '9': '#101010',
} as const;

const danger = {
  __default: '#db0000',
  '0': '#fff8f8',
  '1': '#ffe8e8',
  '2': '#ffd7d7',
  '3': '#ffc5c5',
  '4': '#ffafaf',
  '5': '#ff9595',
  '6': '#ff7474',
  '7': '#ff3e3e',
  '8': '#db0000',
  '9': '#850000',
} as const;

const warning = {
  __default: '##fffbe6',
  '0': '#fffbe6',
  '1': '#fff4c1',
  '2': '#feec97',
  '3': '#fde56d',
  '4': '#fcdf4d',
  '5': '#fbda2c',
  '6': '#f9c92a',
  '7': '#f6b125',
  '8': '#f39920',
  '9': '#ee7018',
} as const;

const success = {
  __default: '#00ba00',
  '0': '#eaffea',
  '1': '#b7ffb7',
  '2': '#62ff62',
  '3': '#00f400',
  '4': '#00e300',
  '5': '#00d000',
  '6': '#00ba00',
  '7': '#00a000',
  '8': '#007e00',
  '9': '#004b00',
} as const;

const darkPetrol = {
  __default: '#244444',
  '0': '#809292',
  '1': '#6D8282',
  '2': '#5B7373',
  '3': '#496363',
  '4': '#375454',
  '5': '#244444',
  '6': '#123535',
  '7': '#002525',
  '8': '#001C1C',
  '9': '#000007',
} as const;

const rose = {
  __default: '#F0DDD4',
  '9': '#FFFCFA',
  '8': '#FAF3F0',
  '7': '#FAF3F0',
  '6': '#F7EDE8',
  '5': '#F3E4DD',
  '4': '#F0DDD4',
  '3': '#E2BDAC',
  '2': '#CA8768',
  '1': '#A65D3A',
  '0': '#713F28',
} as const;

const bluishGray = {
  __default: '#5C626F',
  '0': '#D3DBD9',
  '1': '#BBC6C6',
  '2': '#A3AEB0',
  '3': '#8B969B',
  '4': '#737C85',
  '5': '#5C626F',
  '6': '#454858',
  '7': '#2E2E42',
  '8': '#1A172b',
  '9': '#0D0C16',
} as const;

const bodyBackground = gray['0'];

/**
 * Default Theme
 */
const logoColorOnPageBackground = '#4A4A49';
const logoAccentColorOnPageBackground = '#EE7203';

const pageBackground = gray['0'];
const textOnPageBackground = bluishGray['8'];
const textSecondaryOnPageBackground = gray['4'];
const textTinyCaptionOnPageBackground = gray['6'];
const textToplineOnPageBackground = gray['4'];
const textNavWithoutHoverOnPageBackground = gray['6'];
const textMutedOnPageBackground = gray['3'];

const linkOnPageBackground = primary['7'];
const linkOnPageBackgroundHover = primary['8'];
const linkOnPageBackgroundActive = primary['8'];

const footerLinkOnAccentBackgroundHover = secondary['6'];
const footerLinkOnAccentBackgroundActive = secondary['6'];

const buttonPseudoStatesTextOnPageBackground = 'white';
const buttonPseudoStatesTextOnPageBackgroundFocus = 'white';
const buttonPseudoStatesTextOnPageBackgroundActive = 'white';
const buttonPseudoStatesTextOnPageBackgroundDisabled = gray['7'];

const buttonSecondaryPseudoStatesTextOnPageBackground = 'white';
const buttonSecondaryPseudoStatesTextOnPageBackgroundFocus = 'white';
const buttonSecondaryPseudoStatesTextOnPageBackgroundActive = 'white';
const buttonSecondaryPseudoStatesTextOnPageBackgroundDisabled = gray['7'];

const buttonSecondaryPseudoStatesBackgroundOnPageBackground = primary['3'];
const buttonSecondaryPseudoStatesBackgroundOnPageBackgroundFocus = primary['6'];
const buttonSecondaryPseudoStatesBackgroundOnPageBackgroundActive =
  primary['6'];
const buttonSecondaryPseudoStatesBackgroundOnPageBackgroundDisabled = gray['2'];

const buttonPseudoStatesBackgroundOnPageBackground = secondary['6'];
const buttonPseudoStatesBackgroundOnPageBackgroundFocus = '#EE5D00';
const buttonPseudoStatesBackgroundOnPageBackgroundActive = '#EE5D00';
const buttonPseudoStatesBackgroundOnPageBackgroundDisabled = gray['2'];

const buttonPseudoStatesBorderOnPageBackground = 'transparent';
const buttonPseudoStatesBorderOnPageBackgroundFocus = 'transparent';
const buttonPseudoStatesBorderOnPageBackgroundActive = 'transparent';
const buttonPseudoStatesBorderOnPageBackgroundDisabled = 'transparent';

const buttonPrimaryTextOnPageBackground = 'white';
const buttonPrimaryBackgroundOnPageBackground = secondary['6'];
const buttonPrimaryBorderOnPageBackground = 'transparent';

const buttonSecondaryTextOnPageBackground = secondary['6'];
const buttonSecondaryBackgroundOnPageBackground = 'white';
const buttonSecondaryBorderOnPageBackground = secondary['6'];

const buttonTertiaryTextOnPageBackground = primary;
const buttonTertiaryBackgroundOnPageBackground = 'transparent';
const buttonTertiaryBorderOnPageBackground = 'transparent';

const buttonLightTextOnPageBackground = primary;
const buttonLightTextOnPageBackgroundDisabled = gray['3'];
const buttonLightBackgroundOnPageBackground = gray['2'];

const ghostLightButtonTextOnPageBackground = textOnPageBackground;
const ghostLightButtonTextOnPageBackgroundDisabled = textMutedOnPageBackground;
const ghostLightButtonBackgroundOnPageBackground = 'transparent';
const ghostLightButtonBackgroundOnPageBackgroundHover = 'rgb(0, 0, 0, 0.4)';
const ghostLightButtonBorderOnPageBackground = bluishGray['8'];

const ghostDarkButtonTextOnPageBackground = textOnPageBackground;
const ghostDarkButtonTextOnPageBackgroundDisabled = textMutedOnPageBackground;
const ghostDarkButtonBackgroundOnPageBackground = 'transparent';
const ghostDarkButtonBackgroundOnPageBackgroundHover = 'rgb(0, 0, 0, 0.02)';
const ghostDarkButtonBorderOnPageBackground = gray['3'];

const breadCrumbLinkOnPageBackground = gray['3'];
const breadCrumbTextOnPageBackground = textOnPageBackground;

const horizontalDividerOnPageBackground = gray['2'];
const backdropOnPageBackground = gray['6'];

const productCardBackgroundOnPageBackground = gray['2'];

const spinnerBackgroundOnPageBackground = gray['2'];
const spinnerStrokeOnPageBackground = primary;

const formLabelOnPageBackground = gray['7'];

const cloudPdfBackground = 'white';
const cloudPdfBorderLeft = secondary['5'];
const cloudPdfBackgroundVariantBook = gray['8'];
const linkOnCloudPdfBackgroundVariantBook = primary['2'];
const linkOnCloudPdfBackgroundVariantBookHover = primary['1'];
const linkOnCloudPdfBackgroundVariantBookActive = primary['1'];

const headingSeparatorLineOnPageBackground = '#ff6400';

/**
 * Human Line Theme
 */
const logoColorOnAccentBackgroundHumanLine = 'white';
const logoAccentColorOnAccentBackgroundHumanLine = logoColorOnPageBackground;

const textOnAccentBackgroundHumanLine = gray['0'];
const textSecondaryOnAccentBackgroundHumanLine = secondary['3'];
const textTinyCaptionOnAccentBackgroundHumanLine = secondary['2'];
const textToplineOnAccentBackgroundHumanLine = secondary['3'];
const textNavWithoutHoverOnAccentBackgroundHumanLine = secondary['2'];
const textMutedOnAccentBackgroundHumanLine = gray['3'];
const accentBackgroundHumanLine = secondary['6'];
const accentBackgroundGradientStartHumanLine = secondary['6'];
const accentBackgroundGradientEndHumanLine = secondary['8'];

const linkOnAccentBackgroundHumanLine = secondary['1'];
const linkOnAccentBackgroundHumanLineHover = secondary['0'];
const linkOnAccentBackgroundHumanLineActive = secondary['0'];

const footerLinkOnAccentBackgroundHumanLineHover = primary['9'];
const footerLinkOnAccentBackgroundHumanLineActive = primary['9'];

const buttonPrimaryBackgroundOnAccentBackgroundHumanLineDisabled = gray['1'];

const buttonPrimaryTextOnAccentBackgroundHumanLine = secondary['6'];
const buttonPrimaryBackgroundOnAccentBackgroundHumanLine = 'white';
const buttonPrimaryBorderOnAccentBackgroundHumanLine = 'transparent';
const buttonPrimaryBackgroundOnAccentBackgroundHumanLineHover = '#f7f7f7';
const buttonPrimaryBackgroundOnAccentBackgroundHumanLineFocus = gray['2'];
const buttonSecondaryBackgroundOnAccentBackgroundHumanLine = gray['2'];
const buttonTertiaryTextOnAccentBackgroundHumanLine = primary['2'];

const ghostLightButtonTextOnAccentBackgroundHumanLine = 'white';
const ghostLightButtonTextOnAccentBackgroundHumanLineDisabled = gray['3'];
const ghostLightButtonBorderOnAccentBackgroundHumanLine = 'white';
const ghostLightButtonBackgroundOnAccentBackgroundHoverHumanLine =
  'rgb(0, 0, 0, 0.4)';

const ghostDarkButtonTextOnAccentBackgroundHumanLine = 'white';
const ghostDarkButtonBorderOnAccentBackgroundHumanLine = gray['3'];
const ghostDarkButtonBackgroundOnAccentBackgroundHumanLine = 'transparent';

const breadCrumbLinkOnAccentBackgroundHumanLine = secondary['3'];
const breadCrumbTextOnAccentBackgroundHumanLine = 'white';

const headingSeparatorLineOnAccentBackgroundHumanLine = bluishGray['6'];

/**
 * Veterinary Line Theme
 */
const logoColorOnAccentBackgroundVeterinaryLine = 'white';
const logoAccentColorOnAccentBackgroundVeterinaryLine =
  logoAccentColorOnPageBackground;

const textOnAccentBackgroundVeterinaryLine = gray['0'];
const textSecondaryOnAccentBackgroundVeterinaryLine = gray['5'];
const textTinyCaptionOnAccentBackgroundVeterinaryLine = gray['3'];
const textToplineOnAccentBackgroundVeterinaryLine = gray['4'];
const textNavWithoutHoverOnAccentBackgroundVeterinaryLine = gray['3'];
const textMutedOnAccentBackgroundVeterinaryLine = gray['5'];
const accentBackgroundVeterinaryLine = darkPetrol['7'];
const accentBackgroundGradientStartVeterinaryLine = darkPetrol['7'];
const accentBackgroundGradientEndVeterinaryLine = darkPetrol['9'];

const linkOnAccentBackgroundVeterinaryLine = primary['1'];
const linkOnAccentBackgroundVeterinaryLineHover = primary['0'];
const linkOnAccentBackgroundVeterinaryLineActive = primary['0'];

const ghostLightButtonTextOnAccentBackgroundVeterinaryLine = 'white';
const ghostLightButtonTextOnAccentBackgroundVeterinaryLineDisabled = gray['7'];
const ghostLightButtonBorderOnAccentBackgroundVeterinaryLine = 'white';
const ghostLightButtonBackgroundOnAccentBackgroundVeterinaryLineHover =
  'rgb(255, 255, 255, 0.2)';

const ghostDarkButtonTextOnAccentBackgroundVeterinaryLine = 'white';
const ghostDarkButtonTextOnAccentBackgroundVeterinaryLineDisabled = gray['6'];
const ghostDarkButtonBorderOnAccentBackgroundVeterinaryLine = gray['3'];
const ghostDarkButtonBackgroundOnAccentBackgroundVeterinaryLine = 'transparent';
const ghostDarkButtonBackgroundOnAccentBackgroundVeterinaryLineHover =
  'rgb(255, 255, 255, 0.05)';

const breadCrumbLinkOnAccentBackgroundVeterinaryLine = gray['3'];
const breadCrumbTextOnAccentBackgroundVeterinaryLine = 'white';

/**
 * Default Veterinary Line
 */
const buttonPrimaryTextOnAccentBackgroundDefaultVeterinary = 'white';
const buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinary =
  darkPetrol['7'];
const buttonPrimaryBorderOnAccentBackgroundDefaultVeterinary = 'transparent';
const buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryHover =
  darkPetrol['7'];
const buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryFocus =
  darkPetrol['8'];

/**
 * Neutral
 */
const textOnAccentBackgroundNeutral = bluishGray['8'];
const textSecondaryOnAccentBackgroundNeutral = gray['6'];
const textTinyCaptionOnAccentBackgroundNeutral = gray['7'];
const textToplineOnAccentBackgroundNeutral = gray['6'];
const textNavWithoutHoverOnAccentBackgroundNeutral = gray['7'];
const textMutedOnAccentBackgroundNeutral = gray['3'];
const accentBackgroundNeutral = gray['2'];
const accentBackgroundGradientStartNeutral = gray['2'];
const accentBackgroundGradientEndNeutral = gray['3'];

const buttonPrimaryBackgroundOnPageBackgroundNeutral = primary['9'];

const buttonPrimaryTextOnAccentBackgroundNeutral = 'white';
const buttonPrimaryBorderOnAccentBackgroundNeutral = 'transparent';
const buttonPrimaryBackgroundOnAccentBackgroundNeutralHover = primary['3'];
const buttonPrimaryBackgroundOnAccentBackgroundNeutralFocus = primary['6'];

const linkOnAccentBackgroundNeutral = primary['7'];
const linkOnAccentBackgroundNeutralHover = primary['8'];
const linkOnAccentBackgroundNeutralActive = primary['8'];

// buttons
const ghostLightButtonTextOnAccentBackgroundNeutral = 'white';
const ghostLightButtonTextOnAccentBackgroundNeutralDisabled = gray['4'];
const ghostLightButtonBorderOnAccentBackgroundNeutral = 'white';
const ghostLightButtonBackgroundOnAccentBackgroundNeutralHover =
  'rgb(0, 0, 0, 0.4)';
const ghostDarkButtonTextOnAccentBackgroundNeutral = textOnPageBackground;
const ghostDarkButtonTextOnAccentBackgroundNeutralDisabled = gray['4'];
const ghostDarkButtonBorderOnAccentBackgroundNeutral = gray['4'];
const ghostDarkButtonBackgroundOnAccentBackgroundNeutral = 'transparent';

const breadCrumbLinkOnAccentBackgroundNeutral = gray['6'];

const colors = {
  primary,
  secondary,
  darkPetrol,
  rose,
  bluishGray,
  gray,
  danger,
  warning,
  success,

  // Aliases
  black: gray['9'],
  white: gray['0'],
  bodyBackground,

  logoColor: logoColorOnPageBackground,
  logoAccentColor: logoAccentColorOnPageBackground,

  // Default Theme text and background color.
  text: textOnPageBackground,
  textSecondary: textSecondaryOnPageBackground,
  textTinyCaption: textTinyCaptionOnPageBackground,
  textTopline: textToplineOnPageBackground,
  textNavWithoutHover: textNavWithoutHoverOnPageBackground,
  background: pageBackground,
  textMuted: textMutedOnPageBackground,

  link: linkOnPageBackground,
  linkHover: linkOnPageBackgroundHover,
  linkActive: linkOnPageBackgroundActive,

  footerLinkHover: footerLinkOnAccentBackgroundHover,
  footerLinkActive: footerLinkOnAccentBackgroundActive,

  // Buttons - default
  buttonPseudoStatesText: buttonPseudoStatesTextOnPageBackground,
  buttonPseudoStatesTextFocus: buttonPseudoStatesTextOnPageBackgroundFocus,
  buttonPseudoStatesTextActive: buttonPseudoStatesTextOnPageBackgroundActive,
  buttonPseudoStatesTextDisabled:
    buttonPseudoStatesTextOnPageBackgroundDisabled,

  buttonSecondaryPseudoStatesText:
    buttonSecondaryPseudoStatesTextOnPageBackground,
  buttonSecondaryPseudoStatesTextFocus:
    buttonSecondaryPseudoStatesTextOnPageBackgroundFocus,
  buttonSecondaryPseudoStatesTextActive:
    buttonSecondaryPseudoStatesTextOnPageBackgroundActive,
  buttonSecondaryPseudoStatesTextDisabled:
    buttonSecondaryPseudoStatesTextOnPageBackgroundDisabled,

  buttonPseudoStatesBackground: buttonPseudoStatesBackgroundOnPageBackground,
  buttonPseudoStatesBackgroundFocus:
    buttonPseudoStatesBackgroundOnPageBackgroundFocus,
  buttonPseudoStatesBackgroundActive:
    buttonPseudoStatesBackgroundOnPageBackgroundActive,
  buttonPseudoStatesBackgroundDisabled:
    buttonPseudoStatesBackgroundOnPageBackgroundDisabled,

  buttonSecondaryPseudoStatesBackground:
    buttonSecondaryPseudoStatesBackgroundOnPageBackground,
  buttonSecondaryPseudoStatesBackgroundFocus:
    buttonSecondaryPseudoStatesBackgroundOnPageBackgroundFocus,
  buttonSecondaryPseudoStatesBackgroundActive:
    buttonSecondaryPseudoStatesBackgroundOnPageBackgroundActive,
  buttonSecondaryPseudoStatesBackgroundDisabled:
    buttonSecondaryPseudoStatesBackgroundOnPageBackgroundDisabled,

  buttonPseudoStatesBorder: buttonPseudoStatesBorderOnPageBackground,
  buttonPseudoStatesBorderFocus: buttonPseudoStatesBorderOnPageBackgroundFocus,
  buttonPseudoStatesBorderActive:
    buttonPseudoStatesBorderOnPageBackgroundActive,
  buttonPseudoStatesBorderDisabled:
    buttonPseudoStatesBorderOnPageBackgroundDisabled,

  buttonPrimaryText: buttonPrimaryTextOnPageBackground,
  buttonPrimaryBackground: buttonPrimaryBackgroundOnPageBackground,
  buttonPrimaryBorder: buttonPrimaryBorderOnPageBackground,

  buttonSecondaryText: buttonSecondaryTextOnPageBackground,
  buttonSecondaryBackground: buttonSecondaryBackgroundOnPageBackground,
  buttonSecondaryBorder: buttonSecondaryBorderOnPageBackground,

  buttonTertiaryText: buttonTertiaryTextOnPageBackground,
  buttonTertiaryBackground: buttonTertiaryBackgroundOnPageBackground,
  buttonTertiaryBorder: buttonTertiaryBorderOnPageBackground,

  buttonLightText: buttonLightTextOnPageBackground,
  buttonLightBackground: buttonLightBackgroundOnPageBackground,
  buttonLightDisabled: buttonLightTextOnPageBackgroundDisabled,

  ghostLightButtonText: ghostLightButtonTextOnPageBackground,
  ghostLightButtonTextDisabled: ghostLightButtonTextOnPageBackgroundDisabled,
  ghostLightButtonBackground: ghostLightButtonBackgroundOnPageBackground,
  ghostLightButtonBackgroundHover:
    ghostLightButtonBackgroundOnPageBackgroundHover,
  ghostLightButtonBorder: ghostLightButtonBorderOnPageBackground,

  ghostDarkButtonText: ghostDarkButtonTextOnPageBackground,
  ghostDarkButtonTextDisabled: ghostDarkButtonTextOnPageBackgroundDisabled,
  ghostDarkButtonBackground: ghostDarkButtonBackgroundOnPageBackground,
  ghostDarkButtonBackgroundHover:
    ghostDarkButtonBackgroundOnPageBackgroundHover,
  ghostDarkButtonBorder: ghostDarkButtonBorderOnPageBackground,

  breadCrumbLink: breadCrumbLinkOnPageBackground,
  breadCrumbText: breadCrumbTextOnPageBackground,

  horizontalDivider: horizontalDividerOnPageBackground,

  backdrop: backdropOnPageBackground,

  productCardBackground: productCardBackgroundOnPageBackground,

  spinnerBackground: spinnerBackgroundOnPageBackground,
  spinnerStroke: spinnerStrokeOnPageBackground,

  // Forms
  formLabel: formLabelOnPageBackground,
  inputTextColor: textOnPageBackground,
  textAreaColor: textOnPageBackground,

  cloudPdfBackground,
  cloudPdfBackgroundVariantBook,
  cloudPdfBorderLeft,
  linkOnCloudPdfBackgroundVariantBook,
  linkOnCloudPdfBackgroundVariantBookHover,
  linkOnCloudPdfBackgroundVariantBookActive,

  headingSeparatorLine: headingSeparatorLineOnPageBackground,

  // Colors which are used in different themes to overwrite the text and background colors.
  pageBackground,

  // Human Line
  logoColorOnAccentBackgroundHumanLine,
  logoAccentColorOnAccentBackgroundHumanLine,
  textOnAccentBackgroundHumanLine,
  textSecondaryOnAccentBackgroundHumanLine,
  textTinyCaptionOnAccentBackgroundHumanLine,
  textToplineOnAccentBackgroundHumanLine,
  textNavWithoutHoverOnAccentBackgroundHumanLine,
  textMutedOnAccentBackgroundHumanLine,
  accentBackgroundHumanLine,
  accentBackgroundGradientStartHumanLine,
  accentBackgroundGradientEndHumanLine,

  buttonPrimaryTextOnAccentBackgroundHumanLine,
  buttonPrimaryBackgroundOnAccentBackgroundHumanLine,
  buttonPrimaryBorderOnAccentBackgroundHumanLine,
  buttonPrimaryBackgroundOnAccentBackgroundHumanLineHover,
  buttonPrimaryBackgroundOnAccentBackgroundHumanLineFocus,
  buttonSecondaryBackgroundOnAccentBackgroundHumanLine,

  buttonPrimaryBackgroundOnAccentBackgroundHumanLineDisabled,
  ghostLightButtonTextOnAccentBackgroundHumanLine,
  ghostLightButtonBorderOnAccentBackgroundHumanLine,
  ghostLightButtonTextOnAccentBackgroundHumanLineDisabled,
  ghostLightButtonBackgroundOnAccentBackgroundHoverHumanLine,
  buttonTertiaryTextOnAccentBackgroundHumanLine,
  ghostDarkButtonTextOnAccentBackgroundHumanLine,
  ghostDarkButtonBorderOnAccentBackgroundHumanLine,
  ghostDarkButtonBackgroundOnAccentBackgroundHumanLine,
  breadCrumbLinkOnAccentBackgroundHumanLine,
  breadCrumbTextOnAccentBackgroundHumanLine,
  linkOnAccentBackgroundHumanLine,
  linkOnAccentBackgroundHumanLineHover,
  linkOnAccentBackgroundHumanLineActive,
  footerLinkOnAccentBackgroundHumanLineHover,
  footerLinkOnAccentBackgroundHumanLineActive,
  headingSeparatorLineOnAccentBackgroundHumanLine,

  // Veterinary Line
  logoColorOnAccentBackgroundVeterinaryLine,
  logoAccentColorOnAccentBackgroundVeterinaryLine,
  textOnAccentBackgroundVeterinaryLine,
  textSecondaryOnAccentBackgroundVeterinaryLine,
  textTinyCaptionOnAccentBackgroundVeterinaryLine,
  textToplineOnAccentBackgroundVeterinaryLine,
  textNavWithoutHoverOnAccentBackgroundVeterinaryLine,
  textMutedOnAccentBackgroundVeterinaryLine,
  accentBackgroundVeterinaryLine,
  accentBackgroundGradientStartVeterinaryLine,
  accentBackgroundGradientEndVeterinaryLine,
  ghostLightButtonTextOnAccentBackgroundVeterinaryLine,
  ghostLightButtonBorderOnAccentBackgroundVeterinaryLine,
  ghostLightButtonTextOnAccentBackgroundVeterinaryLineDisabled,
  ghostLightButtonBackgroundOnAccentBackgroundVeterinaryLineHover,
  ghostDarkButtonTextOnAccentBackgroundVeterinaryLine,
  ghostDarkButtonTextOnAccentBackgroundVeterinaryLineDisabled,
  ghostDarkButtonBorderOnAccentBackgroundVeterinaryLine,
  ghostDarkButtonBackgroundOnAccentBackgroundVeterinaryLine,
  ghostDarkButtonBackgroundOnAccentBackgroundVeterinaryLineHover,
  breadCrumbLinkOnAccentBackgroundVeterinaryLine,
  breadCrumbTextOnAccentBackgroundVeterinaryLine,
  linkOnAccentBackgroundVeterinaryLine,
  linkOnAccentBackgroundVeterinaryLineHover,
  linkOnAccentBackgroundVeterinaryLineActive,

  // Default Veterinary Line
  buttonPrimaryTextOnAccentBackgroundDefaultVeterinary,
  buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinary,
  buttonPrimaryBorderOnAccentBackgroundDefaultVeterinary,
  buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryHover,
  buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryFocus,

  // Neutral
  textOnAccentBackgroundNeutral,
  textSecondaryOnAccentBackgroundNeutral,
  textTinyCaptionOnAccentBackgroundNeutral,
  textToplineOnAccentBackgroundNeutral,
  textNavWithoutHoverOnAccentBackgroundNeutral,
  textMutedOnAccentBackgroundNeutral,
  accentBackgroundNeutral,
  accentBackgroundGradientStartNeutral,
  accentBackgroundGradientEndNeutral,
  buttonPrimaryBackgroundOnPageBackgroundNeutral,

  buttonPrimaryTextOnAccentBackgroundNeutral,
  buttonPrimaryBorderOnAccentBackgroundNeutral,
  buttonPrimaryBackgroundOnAccentBackgroundNeutralHover,
  buttonPrimaryBackgroundOnAccentBackgroundNeutralFocus,

  ghostLightButtonTextOnAccentBackgroundNeutral,
  ghostLightButtonBorderOnAccentBackgroundNeutral,
  ghostLightButtonTextOnAccentBackgroundNeutralDisabled,
  ghostLightButtonBackgroundOnAccentBackgroundNeutralHover,
  ghostDarkButtonBorderOnAccentBackgroundNeutral,
  ghostDarkButtonTextOnAccentBackgroundNeutral,
  ghostDarkButtonTextOnAccentBackgroundNeutralDisabled,
  ghostDarkButtonBackgroundOnAccentBackgroundNeutral,
  breadCrumbLinkOnAccentBackgroundNeutral,
  linkOnAccentBackgroundNeutral,
  linkOnAccentBackgroundNeutralHover,
  linkOnAccentBackgroundNeutralActive,

  // Used for Favicons or in Sanity to give a visual hint about the environment.
  environments: {
    playground: {
      backgroundColor: success['2'],
      color: gray['1'],
    },
    development: {
      backgroundColor: success['8'],
      color: gray['1'],
    },
    stage: {
      backgroundColor: primary['8'],
      color: gray['1'],
    },
    production: {
      backgroundColor: danger['8'],
      color: gray['1'],
    },
    prod: {
      backgroundColor: danger['8'],
      color: gray['1'],
    },
  },
};

export { colors as default };
