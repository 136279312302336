import { imageDescription } from './image';

const RATIOS = {
  RATIO_1_1: '1:1',
  RATIO_2_1: '2:1',
  RATIO_2_3: '2:3',
  RATIO_3_2: '3:2',
  RATIO_4_1: '4:1',
  RATIO_4_3: '4:3',
  RATIO_5_2: '5:2',
  RATIO_5_3: '5:3',
  RATIO_5_4: '5:4',
  RATIO_5_6: '5:6',
  RATIO_5_7: '5:7',
  RATIO_5_9: '5:9',
  RATIO_6_5: '6:5',
  RATIO_7_2: '7:2',
  RATIO_7_3: '7:3',
  RATIO_7_5: '7:5',
  RATIO_8_9: '8:9',
  RATIO_10_9: '10:9',
  RATIO_14_5: '14:5',
  RATIO_15_8: '15:8',
  RATIO_16_9: '16:9',
  RATIO_17_8: '17:8',
} as const;

type TRatioKey = keyof typeof RATIOS;
type TRatioValue = typeof RATIOS[TRatioKey];

interface IAspectRatio {
  ratio: number;
  label: TRatioValue;
}

type TAspectRatios = {
  [key in TRatioValue]: IAspectRatio;
};

const roundAspectRatio = (aspectRatio: number): number =>
  Number(Number.parseFloat(aspectRatio.toString()).toFixed(2));

const aspectRatios: TAspectRatios = {
  [RATIOS.RATIO_1_1]: {
    ratio: roundAspectRatio(1 / 1),
    label: RATIOS.RATIO_1_1,
  },
  [RATIOS.RATIO_2_1]: {
    ratio: roundAspectRatio(2 / 1),
    label: RATIOS.RATIO_2_1,
  },
  [RATIOS.RATIO_2_3]: {
    ratio: roundAspectRatio(2 / 3),
    label: RATIOS.RATIO_2_3,
  },
  [RATIOS.RATIO_3_2]: {
    ratio: roundAspectRatio(3 / 2),
    label: RATIOS.RATIO_3_2,
  },
  [RATIOS.RATIO_4_1]: {
    ratio: roundAspectRatio(4 / 1),
    label: RATIOS.RATIO_4_1,
  },
  [RATIOS.RATIO_4_3]: {
    ratio: roundAspectRatio(4 / 3),
    label: RATIOS.RATIO_4_3,
  },
  [RATIOS.RATIO_5_2]: {
    ratio: roundAspectRatio(5 / 2),
    label: RATIOS.RATIO_5_2,
  },
  [RATIOS.RATIO_5_3]: {
    ratio: roundAspectRatio(5 / 3),
    label: RATIOS.RATIO_5_3,
  },
  [RATIOS.RATIO_5_4]: {
    ratio: roundAspectRatio(5 / 4),
    label: RATIOS.RATIO_5_4,
  },
  [RATIOS.RATIO_5_6]: {
    ratio: roundAspectRatio(5 / 6),
    label: RATIOS.RATIO_5_6,
  },
  [RATIOS.RATIO_5_7]: {
    ratio: roundAspectRatio(5 / 7),
    label: RATIOS.RATIO_5_7,
  },
  [RATIOS.RATIO_5_9]: {
    ratio: roundAspectRatio(5 / 9),
    label: RATIOS.RATIO_5_9,
  },
  [RATIOS.RATIO_6_5]: {
    ratio: roundAspectRatio(6 / 5),
    label: RATIOS.RATIO_6_5,
  },
  [RATIOS.RATIO_7_2]: {
    ratio: roundAspectRatio(7 / 2),
    label: RATIOS.RATIO_7_2,
  },
  [RATIOS.RATIO_7_3]: {
    ratio: roundAspectRatio(7 / 3),
    label: RATIOS.RATIO_7_3,
  },
  [RATIOS.RATIO_7_5]: {
    ratio: roundAspectRatio(7 / 5),
    label: RATIOS.RATIO_7_5,
  },
  [RATIOS.RATIO_8_9]: {
    ratio: roundAspectRatio(8 / 9),
    label: RATIOS.RATIO_8_9,
  },
  [RATIOS.RATIO_10_9]: {
    ratio: roundAspectRatio(10 / 9),
    label: RATIOS.RATIO_10_9,
  },
  [RATIOS.RATIO_14_5]: {
    ratio: roundAspectRatio(14 / 5),
    label: RATIOS.RATIO_14_5,
  },
  [RATIOS.RATIO_15_8]: {
    ratio: roundAspectRatio(15 / 8),
    label: RATIOS.RATIO_15_8,
  },
  [RATIOS.RATIO_16_9]: {
    ratio: roundAspectRatio(16 / 9),
    label: RATIOS.RATIO_16_9,
  },
  [RATIOS.RATIO_17_8]: {
    ratio: roundAspectRatio(17 / 8),
    label: RATIOS.RATIO_17_8,
  },
};

const aspectRatioSchemaFieldDescription = (ratio: TRatioValue): string =>
  `${imageDescription}. Only upload media with an aspect ratio of ${aspectRatios[ratio].label}.`;

export {
  IAspectRatio,
  RATIOS,
  aspectRatios,
  aspectRatioSchemaFieldDescription,
  roundAspectRatio,
};
