/* eslint-disable prefer-destructuring */
/* eslint-disable unused-imports/no-unused-vars */
// @ts-nocheck
/* eslint-disable unused-imports/no-unused-imports */
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700.css"
import PiwikPro, { PageViews } from '@piwikpro/react-piwik-pro';
import type { GatsbyBrowser } from 'gatsby';

// Piwik PRO implementation
const GATSBY_PIWIK_SITE_ID = process.env.GATSBY_PIWIK_SITE_ID;
const GATSBY_PIWIK_CONTAINER_URL = process.env.GATSBY_PIWIK_CONTAINER_URL;
const consentmanagerIsActive = process.env.GATSBY_DISABLE_CONSENTMANAGER !== 'true';
const piwikProIsActive = process.env.GATSBY_DISABLE_PIWIK_PRO !== 'true';

console.log('***gatsby-browser-base process.env.GATSBY_DISABLE_CONSENTMANAGER: ', process.env.GATSBY_DISABLE_CONSENTMANAGER);
console.log('***gatsby-browser-base process.env.GATSBY_DISABLE_PIWIK_PRO: ', process.env.GATSBY_DISABLE_PIWIK_PRO);

/**
 * Consentmanager cmp vendor id for "Piwik Pro - Tag Management"
 */
const CMP_VENDOR_ID_PIWIK_TAG_MANAGEMENT = 's2589';

/**
 * Consentmanager cmp vendor id for "Piwik PRO – Web Analytics"
 */
const CMP_VENDOR_ID_PIWIK_WEB_ANALYTICS = 'c36785';


const isVendorConsentGiven = (vendorId) => {
  if (!__cmp) {
    console.warn('Consentmanager was not initialized.');
    return false;
  }

  const cmpData = __cmp('getCMPData');
  if (!("vendorConsents" in cmpData)) {
    console.warn(`vendorConsent for ${vendorId} was not given.`);
    return false;
  }

  return vendorId in cmpData.vendorConsents && cmpData.vendorConsents[vendorId];
};

// This function is defined in gatsby-ssr-base.tsx during runtime
declare function doPiwikDataLayerPush(page?: any, event?: string): void;

const getOnRouteUpdateFunction = () => {
  if (!consentmanagerIsActive) {
    // without consentmanager piwik is also not allowed
    return ({ location, prevLocation }) => {
      console.log('***gatsby-browser-base consentmanager is disabled.');
    };
  }

  if (piwikProIsActive) {
    return ({ location, prevLocation }) => {
      console.log('***gatsby-browser-base  Piwik Pro is enabled.');
      // only track pageViews if there is a prevLocation and consent is given by the use
      // initial pageViews are tracked during initilization which is currently done via PiwikProInitScript in gatsby-ssr.tsx
      if (prevLocation && isVendorConsentGiven(CMP_VENDOR_ID_PIWIK_TAG_MANAGEMENT)) {
        if (isVendorConsentGiven(CMP_VENDOR_ID_PIWIK_WEB_ANALYTICS)) {
          if (typeof doPiwikDataLayerPush !== 'function') {
            return console.warn('function "doPiwikDataLayerPush" is not defined! Route update could not be tracked!');
          }
          doPiwikDataLayerPush(document.title);
        }
      }
    };
  }

  // nothing to do
  return ({ location, prevLocation }) => {
    console.log('***gatsby-browser-base Piwik Pro is disabled.');
  };
};

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = getOnRouteUpdateFunction();

export {
  onRouteUpdate,
};
