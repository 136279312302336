import sanityClient from '@sanity/client';
import { NoResponseReceivedError } from '../errors';
import { TCountryCode, TLanguageCode } from '../locale';

interface ILegalTextResponseData {
  _id: string;
  category: string;
  country: TCountryCode;
  language: TLanguageCode;
  title: string;
}

const getLegalTexts = async (): Promise<ILegalTextResponseData[]> => {
  const DEBUG_NAME = 'getLegalTexts';
  const projectId = process.env.SANITY_STUDIO_LEGAL_API_PROJECT_ID;
  const dataset = process.env.SANITY_STUDIO_LEGAL_API_DATASET;

  console.log(`[${DEBUG_NAME}] projectId:`, projectId);
  console.log(`[${DEBUG_NAME}] dataset:`, dataset);

  try {
    const client = sanityClient({
      projectId,
      dataset,
      useCdn: false,
    });

    const query = `
    *[
      _type == "legalDocument" &&
     !(_id in path("drafts.**"))
     ]{
      country,
      language,
      category,
       "title": coalesce(title, "untitled document"),
       _id
     }
     `;
    const response = await client.fetch(query, {});

    if (!response) {
      throw new NoResponseReceivedError();
    }

    return response;
  } catch (error) {
    console.log(`[${DEBUG_NAME}] error`, error);
    throw error;
  }
};

export { getLegalTexts, ILegalTextResponseData };
