import { eventMapCountries } from './eventMapCountries';
import {
  IEventType,
  IEventTypeCategory,
  IEventTypeResponse,
  TEvent,
  TEventResponseData,
} from './eventsApi';

const eventTypeIdToIconMap = {
  ACADEMY: 'book',
  BEMER_SATURDAY: 'medkit',
  BUSINESS_BUILDING: 'chart-line',
  BUSINESS_BUILDING_TRAINING: 'chart-line',
  BUSINESS_PRESENTATION: 'tv-retro',
  BUSINESS_SEMINAR: 'briefcase',
  DISG_PERSONALITY: 'sliders-h',
  ENTREPRENEUR_DAYS: 'microphone-alt',
  FAIR: 'bullhorn',
  HEALTH_LECTURE: 'user-md',
  IN_HOUSE_FAIR: 'newspaper',
  LIM_WORKSHOP: 'adjust',
  LUNCH_AND_LEARN: 'bullhorn',
  MEDICAL_TRAINING: 'hospital',
  MED_LECTURE: 'heart',
  MED_START_UP: 'heart',
  MED_SYMPOSIUM: 'heart',
  MPC: 'medkit',
  PERSONALITY_TRAINING: 'child',
  PRESENTATION_TRANING: 'paint-brush',
  REFERENT_REFRESHER: 'street-view',
  REFERENT_TRAINING: 'street-view',
  SALES_TRAINING: 'bell',
  TRAINING_VET: 'paw',
  TRAIN_THE_TRAINER: 'american-sign-language-interpreting',
  ALL: 'dot-circle',
};

const buildEventTypeUrl = (eventType: IEventType): string =>
  `https://res.cloudinary.com/bemergroup/image/upload/c_fit,h_80,e_colorize,co_rgb:1eb4e6/e_outline:outer:1,co_black/l_bemer-atomic-lib:icons:light:${
    eventTypeIdToIconMap[eventType.id]
  },c_fit,h_40,e_colorize,co_white,g_north,y_14/v1510479819/bemer-atomic-lib/icons/solid/map-marker.png`;

const extendEventsWithCoords = (events: TEventResponseData[]): TEvent[] =>
  events.map((event) => {
    if (event.venue?.lat && event.venue?.lng) {
      return event as TEvent;
    }
    const matchingCountry = eventMapCountries.find(
      (country) => country.country_code === event.venue?.countryCode
    );
    if (!matchingCountry) {
      console.warn(
        `No country found for ${event.venue?.countryCode}. (Event ID "${event.id}")`
      );
      return event as TEvent;
    }
    return {
      ...event,
      venue: {
        ...event.venue,
        lat: matchingCountry.lat,
        lng: matchingCountry.lng,
      },
    } as TEvent;
  });

const extendEventTypeWithIconUrl = (
  eventTypeCategories: IEventTypeCategory[]
): IEventTypeResponse[] =>
  eventTypeCategories
    .flatMap((cat: IEventTypeCategory) => cat.eventTypes)
    .map((type: IEventType) => ({ ...type, url: buildEventTypeUrl(type) }));

export {
  eventTypeIdToIconMap,
  buildEventTypeUrl,
  extendEventsWithCoords,
  extendEventTypeWithIconUrl,
};
