import baseSpacingScale from './base-spacing-scale';

const sizes = {
  ...baseSpacingScale,
  auto: 'auto',
  min: 'min-content',
  max: 'max-content',
};

export { sizes as default };
