import { allCountries, ILocale, TCountryKey } from '../locale';

// TODO: get currencies from Backend??

const getCurrencyForLocale = (locale: ILocale) => {
  const countryKey = Object.keys(allCountries).find(
    (key) => allCountries[key as TCountryKey].countryCode === locale.countryCode
  ) as TCountryKey;
  return allCountries[countryKey].currency;
};

export { getCurrencyForLocale };
