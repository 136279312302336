const eventMapCountries = [
  {
    country_code: 'AD',
    lat: 42.546245,
    lng: 1.601554,
    country_name: 'Andorra',
  },
  {
    country_code: 'AE',
    lat: 23.424076,
    lng: 53.847818,
    country_name: 'United Arab Emirates',
  },
  {
    country_code: 'AF',
    lat: 33.93911,
    lng: 67.709953,
    country_name: 'Afghanistan',
  },
  {
    country_code: 'AG',
    lat: 17.060816,
    lng: -61.796428,
    country_name: 'Antigua and Barbuda',
  },
  {
    country_code: 'AI',
    lat: 18.220554,
    lng: -63.068615,
    country_name: 'Anguilla',
  },
  {
    country_code: 'AL',
    lat: 41.153332,
    lng: 20.168331,
    country_name: 'Albania',
  },
  {
    country_code: 'AM',
    lat: 40.069099,
    lng: 45.038189,
    country_name: 'Armenia',
  },
  {
    country_code: 'AN',
    lat: 12.226079,
    lng: -69.060087,
    country_name: 'Netherlands Antilles',
  },
  {
    country_code: 'AO',
    lat: -11.202692,
    lng: 17.873887,
    country_name: 'Angola',
  },
  {
    country_code: 'AQ',
    lat: -75.250973,
    lng: -0.071389,
    country_name: 'Antarctica',
  },
  {
    country_code: 'AR',
    lat: -38.416097,
    lng: -63.616672,
    country_name: 'Argentina',
  },
  {
    country_code: 'AS',
    lat: -14.270972,
    lng: -170.132217,
    country_name: 'American Samoa',
  },
  {
    country_code: 'AT',
    lat: 47.516231,
    lng: 14.550072,
    country_name: 'Austria',
  },
  {
    country_code: 'AU',
    lat: -25.274398,
    lng: 133.775136,
    country_name: 'Australia',
  },
  {
    country_code: 'AW',
    lat: 12.52111,
    lng: -69.968338,
    country_name: 'Aruba',
  },
  {
    country_code: 'AZ',
    lat: 40.143105,
    lng: 47.576927,
    country_name: 'Azerbaijan',
  },
  {
    country_code: 'BA',
    lat: 43.915886,
    lng: 17.679076,
    country_name: 'Bosnia and Herzegovina',
  },
  {
    country_code: 'BB',
    lat: 13.193887,
    lng: -59.543198,
    country_name: 'Barbados',
  },
  {
    country_code: 'BD',
    lat: 23.684994,
    lng: 90.356331,
    country_name: 'Bangladesh',
  },
  {
    country_code: 'BE',
    lat: 50.503887,
    lng: 4.469936,
    country_name: 'Belgium',
  },
  {
    country_code: 'BF',
    lat: 12.238333,
    lng: -1.561593,
    country_name: 'Burkina Faso',
  },
  {
    country_code: 'BG',
    lat: 42.733883,
    lng: 25.48583,
    country_name: 'Bulgaria',
  },
  {
    country_code: 'BH',
    lat: 25.930414,
    lng: 50.637772,
    country_name: 'Bahrain',
  },
  {
    country_code: 'BI',
    lat: -3.373056,
    lng: 29.918886,
    country_name: 'Burundi',
  },
  {
    country_code: 'BJ',
    lat: 9.30769,
    lng: 2.315834,
    country_name: 'Benin',
  },
  {
    country_code: 'BM',
    lat: 32.321384,
    lng: -64.75737,
    country_name: 'Bermuda',
  },
  {
    country_code: 'BN',
    lat: 4.535277,
    lng: 114.727669,
    country_name: 'Brunei',
  },
  {
    country_code: 'BO',
    lat: -16.290154,
    lng: -63.588653,
    country_name: 'Bolivia',
  },
  {
    country_code: 'BR',
    lat: -14.235004,
    lng: -51.92528,
    country_name: 'Brazil',
  },
  {
    country_code: 'BS',
    lat: 25.03428,
    lng: -77.39628,
    country_name: 'Bahamas',
  },
  {
    country_code: 'BT',
    lat: 27.514162,
    lng: 90.433601,
    country_name: 'Bhutan',
  },
  {
    country_code: 'BV',
    lat: -54.423199,
    lng: 3.413194,
    country_name: 'Bouvet Island',
  },
  {
    country_code: 'BW',
    lat: -22.328474,
    lng: 24.684866,
    country_name: 'Botswana',
  },
  {
    country_code: 'BY',
    lat: 53.709807,
    lng: 27.953389,
    country_name: 'Belarus',
  },
  {
    country_code: 'BZ',
    lat: 17.189877,
    lng: -88.49765,
    country_name: 'Belize',
  },
  {
    country_code: 'CA',
    lat: 56.130366,
    lng: -106.346771,
    country_name: 'Canada',
  },
  {
    country_code: 'CC',
    lat: -12.164165,
    lng: 96.870956,
    country_name: 'Cocos [Keeling] Islands',
  },
  {
    country_code: 'CD',
    lat: -4.038333,
    lng: 21.758664,
    country_name: 'Congo [DRC]',
  },
  {
    country_code: 'CF',
    lat: 6.611111,
    lng: 20.939444,
    country_name: 'Central African Republic',
  },
  {
    country_code: 'CG',
    lat: -0.228021,
    lng: 15.827659,
    country_name: 'Congo [Republic]',
  },
  {
    country_code: 'CH',
    lat: 46.818188,
    lng: 8.227512,
    country_name: 'Switzerland',
  },
  {
    country_code: 'CI',
    lat: 7.539989,
    lng: -5.54708,
    country_name: "Côte d'Ivoire",
  },
  {
    country_code: 'CK',
    lat: -21.236736,
    lng: -159.777671,
    country_name: 'Cook Islands',
  },
  {
    country_code: 'CL',
    lat: -35.675147,
    lng: -71.542969,
    country_name: 'Chile',
  },
  {
    country_code: 'CM',
    lat: 7.369722,
    lng: 12.354722,
    country_name: 'Cameroon',
  },
  {
    country_code: 'CN',
    lat: 35.86166,
    lng: 104.195397,
    country_name: 'China',
  },
  {
    country_code: 'CO',
    lat: 4.570868,
    lng: -74.297333,
    country_name: 'Colombia',
  },
  {
    country_code: 'CR',
    lat: 9.748917,
    lng: -83.753428,
    country_name: 'Costa Rica',
  },
  {
    country_code: 'CU',
    lat: 21.521757,
    lng: -77.781167,
    country_name: 'Cuba',
  },
  {
    country_code: 'CV',
    lat: 16.002082,
    lng: -24.013197,
    country_name: 'Cape Verde',
  },
  {
    country_code: 'CX',
    lat: -10.447525,
    lng: 105.690449,
    country_name: 'Christmas Island',
  },
  {
    country_code: 'CY',
    lat: 35.126413,
    lng: 33.429859,
    country_name: 'Cyprus',
  },
  {
    country_code: 'CZ',
    lat: 49.817492,
    lng: 15.472962,
    country_name: 'Czech Republic',
  },
  {
    country_code: 'DE',
    lat: 51.165691,
    lng: 10.451526,
    country_name: 'Germany',
  },
  {
    country_code: 'DJ',
    lat: 11.825138,
    lng: 42.590275,
    country_name: 'Djibouti',
  },
  {
    country_code: 'DK',
    lat: 56.26392,
    lng: 9.501785,
    country_name: 'Denmark',
  },
  {
    country_code: 'DM',
    lat: 15.414999,
    lng: -61.370976,
    country_name: 'Dominica',
  },
  {
    country_code: 'DO',
    lat: 18.735693,
    lng: -70.162651,
    country_name: 'Dominican Republic',
  },
  {
    country_code: 'DZ',
    lat: 28.033886,
    lng: 1.659626,
    country_name: 'Algeria',
  },
  {
    country_code: 'EC',
    lat: -1.831239,
    lng: -78.183406,
    country_name: 'Ecuador',
  },
  {
    country_code: 'EE',
    lat: 58.595272,
    lng: 25.013607,
    country_name: 'Estonia',
  },
  {
    country_code: 'EG',
    lat: 26.820553,
    lng: 30.802498,
    country_name: 'Egypt',
  },
  {
    country_code: 'EH',
    lat: 24.215527,
    lng: -12.885834,
    country_name: 'Western Sahara',
  },
  {
    country_code: 'ER',
    lat: 15.179384,
    lng: 39.782334,
    country_name: 'Eritrea',
  },
  {
    country_code: 'ES',
    lat: 40.463667,
    lng: -3.74922,
    country_name: 'Spain',
  },
  {
    country_code: 'ET',
    lat: 9.145,
    lng: 40.489673,
    country_name: 'Ethiopia',
  },
  {
    country_code: 'FI',
    lat: 61.92411,
    lng: 25.748151,
    country_name: 'Finland',
  },
  {
    country_code: 'FJ',
    lat: -16.578193,
    lng: 179.414413,
    country_name: 'Fiji',
  },
  {
    country_code: 'FK',
    lat: -51.796253,
    lng: -59.523613,
    country_name: 'Falkland Islands [Islas Malvinas]',
  },
  {
    country_code: 'FM',
    lat: 7.425554,
    lng: 150.550812,
    country_name: 'Micronesia',
  },
  {
    country_code: 'FO',
    lat: 61.892635,
    lng: -6.911806,
    country_name: 'Faroe Islands',
  },
  {
    country_code: 'FR',
    lat: 46.227638,
    lng: 2.213749,
    country_name: 'France',
  },
  {
    country_code: 'GA',
    lat: -0.803689,
    lng: 11.609444,
    country_name: 'Gabon',
  },
  {
    country_code: 'GB',
    lat: 55.378051,
    lng: -3.435973,
    country_name: 'United Kingdom',
  },
  {
    country_code: 'GD',
    lat: 12.262776,
    lng: -61.604171,
    country_name: 'Grenada',
  },
  {
    country_code: 'GE',
    lat: 42.315407,
    lng: 43.356892,
    country_name: 'Georgia',
  },
  {
    country_code: 'GF',
    lat: 3.933889,
    lng: -53.125782,
    country_name: 'French Guiana',
  },
  {
    country_code: 'GG',
    lat: 49.465691,
    lng: -2.585278,
    country_name: 'Guernsey',
  },
  {
    country_code: 'GH',
    lat: 7.946527,
    lng: -1.023194,
    country_name: 'Ghana',
  },
  {
    country_code: 'GI',
    lat: 36.137741,
    lng: -5.345374,
    country_name: 'Gibraltar',
  },
  {
    country_code: 'GL',
    lat: 71.706936,
    lng: -42.604303,
    country_name: 'Greenland',
  },
  {
    country_code: 'GM',
    lat: 13.443182,
    lng: -15.310139,
    country_name: 'Gambia',
  },
  {
    country_code: 'GN',
    lat: 9.945587,
    lng: -9.696645,
    country_name: 'Guinea',
  },
  {
    country_code: 'GP',
    lat: 16.995971,
    lng: -62.067641,
    country_name: 'Guadeloupe',
  },
  {
    country_code: 'GQ',
    lat: 1.650801,
    lng: 10.267895,
    country_name: 'Equatorial Guinea',
  },
  {
    country_code: 'GR',
    lat: 39.074208,
    lng: 21.824312,
    country_name: 'Greece',
  },
  {
    country_code: 'GS',
    lat: -54.429579,
    lng: -36.587909,
    country_name: 'South Georgia and the South Sandwich Islands',
  },
  {
    country_code: 'GT',
    lat: 15.783471,
    lng: -90.230759,
    country_name: 'Guatemala',
  },
  {
    country_code: 'GU',
    lat: 13.444304,
    lng: 144.793731,
    country_name: 'Guam',
  },
  {
    country_code: 'GW',
    lat: 11.803749,
    lng: -15.180413,
    country_name: 'Guinea-Bissau',
  },
  {
    country_code: 'GY',
    lat: 4.860416,
    lng: -58.93018,
    country_name: 'Guyana',
  },
  {
    country_code: 'GZ',
    lat: 31.354676,
    lng: 34.308825,
    country_name: 'Gaza Strip',
  },
  {
    country_code: 'HK',
    lat: 22.396428,
    lng: 114.109497,
    country_name: 'Hong Kong',
  },
  {
    country_code: 'HM',
    lat: -53.08181,
    lng: 73.504158,
    country_name: 'Heard Island and McDonald Islands',
  },
  {
    country_code: 'HN',
    lat: 15.199999,
    lng: -86.241905,
    country_name: 'Honduras',
  },
  {
    country_code: 'HR',
    lat: 45.1,
    lng: 15.2,
    country_name: 'Croatia',
  },
  {
    country_code: 'HT',
    lat: 18.971187,
    lng: -72.285215,
    country_name: 'Haiti',
  },
  {
    country_code: 'HU',
    lat: 47.162494,
    lng: 19.503304,
    country_name: 'Hungary',
  },
  {
    country_code: 'ID',
    lat: -0.789275,
    lng: 113.921327,
    country_name: 'Indonesia',
  },
  {
    country_code: 'IE',
    lat: 53.41291,
    lng: -8.24389,
    country_name: 'Ireland',
  },
  {
    country_code: 'IL',
    lat: 31.046051,
    lng: 34.851612,
    country_name: 'Israel',
  },
  {
    country_code: 'IM',
    lat: 54.236107,
    lng: -4.548056,
    country_name: 'Isle of Man',
  },
  {
    country_code: 'IN',
    lat: 20.593684,
    lng: 78.96288,
    country_name: 'India',
  },
  {
    country_code: 'IO',
    lat: -6.343194,
    lng: 71.876519,
    country_name: 'British Indian Ocean Territory',
  },
  {
    country_code: 'IQ',
    lat: 33.223191,
    lng: 43.679291,
    country_name: 'Iraq',
  },
  {
    country_code: 'IR',
    lat: 32.427908,
    lng: 53.688046,
    country_name: 'Iran',
  },
  {
    country_code: 'IS',
    lat: 64.963051,
    lng: -19.020835,
    country_name: 'Iceland',
  },
  {
    country_code: 'IT',
    lat: 41.87194,
    lng: 12.56738,
    country_name: 'Italy',
  },
  {
    country_code: 'JE',
    lat: 49.214439,
    lng: -2.13125,
    country_name: 'Jersey',
  },
  {
    country_code: 'JM',
    lat: 18.109581,
    lng: -77.297508,
    country_name: 'Jamaica',
  },
  {
    country_code: 'JO',
    lat: 30.585164,
    lng: 36.238414,
    country_name: 'Jordan',
  },
  {
    country_code: 'JP',
    lat: 36.204824,
    lng: 138.252924,
    country_name: 'Japan',
  },
  {
    country_code: 'KE',
    lat: -0.023559,
    lng: 37.906193,
    country_name: 'Kenya',
  },
  {
    country_code: 'KG',
    lat: 41.20438,
    lng: 74.766098,
    country_name: 'Kyrgyzstan',
  },
  {
    country_code: 'KH',
    lat: 12.565679,
    lng: 104.990963,
    country_name: 'Cambodia',
  },
  {
    country_code: 'KI',
    lat: -3.370417,
    lng: -168.734039,
    country_name: 'Kiribati',
  },
  {
    country_code: 'KM',
    lat: -11.875001,
    lng: 43.872219,
    country_name: 'Comoros',
  },
  {
    country_code: 'KN',
    lat: 17.357822,
    lng: -62.782998,
    country_name: 'Saint Kitts and Nevis',
  },
  {
    country_code: 'KP',
    lat: 40.339852,
    lng: 127.510093,
    country_name: 'North Korea',
  },
  {
    country_code: 'KR',
    lat: 35.907757,
    lng: 127.766922,
    country_name: 'South Korea',
  },
  {
    country_code: 'KW',
    lat: 29.31166,
    lng: 47.481766,
    country_name: 'Kuwait',
  },
  {
    country_code: 'KY',
    lat: 19.513469,
    lng: -80.566956,
    country_name: 'Cayman Islands',
  },
  {
    country_code: 'KZ',
    lat: 48.019573,
    lng: 66.923684,
    country_name: 'Kazakhstan',
  },
  {
    country_code: 'LA',
    lat: 19.85627,
    lng: 102.495496,
    country_name: 'Laos',
  },
  {
    country_code: 'LB',
    lat: 33.854721,
    lng: 35.862285,
    country_name: 'Lebanon',
  },
  {
    country_code: 'LC',
    lat: 13.909444,
    lng: -60.978893,
    country_name: 'Saint Lucia',
  },
  {
    country_code: 'LI',
    lat: 47.166,
    lng: 9.555373,
    country_name: 'Liechtenstein',
  },
  {
    country_code: 'LK',
    lat: 7.873054,
    lng: 80.771797,
    country_name: 'Sri Lanka',
  },
  {
    country_code: 'LR',
    lat: 6.428055,
    lng: -9.429499,
    country_name: 'Liberia',
  },
  {
    country_code: 'LS',
    lat: -29.609988,
    lng: 28.233608,
    country_name: 'Lesotho',
  },
  {
    country_code: 'LT',
    lat: 55.169438,
    lng: 23.881275,
    country_name: 'Lithuania',
  },
  {
    country_code: 'LU',
    lat: 49.815273,
    lng: 6.129583,
    country_name: 'Luxembourg',
  },
  {
    country_code: 'LV',
    lat: 56.879635,
    lng: 24.603189,
    country_name: 'Latvia',
  },
  {
    country_code: 'LY',
    lat: 26.3351,
    lng: 17.228331,
    country_name: 'Libya',
  },
  {
    country_code: 'MA',
    lat: 31.791702,
    lng: -7.09262,
    country_name: 'Morocco',
  },
  {
    country_code: 'MC',
    lat: 43.750298,
    lng: 7.412841,
    country_name: 'Monaco',
  },
  {
    country_code: 'MD',
    lat: 47.411631,
    lng: 28.369885,
    country_name: 'Moldova',
  },
  {
    country_code: 'ME',
    lat: 42.708678,
    lng: 19.37439,
    country_name: 'Montenegro',
  },
  {
    country_code: 'MG',
    lat: -18.766947,
    lng: 46.869107,
    country_name: 'Madagascar',
  },
  {
    country_code: 'MH',
    lat: 7.131474,
    lng: 171.184478,
    country_name: 'Marshall Islands',
  },
  {
    country_code: 'MK',
    lat: 41.608635,
    lng: 21.745275,
    country_name: 'Macedonia [FYROM]',
  },
  {
    country_code: 'ML',
    lat: 17.570692,
    lng: -3.996166,
    country_name: 'Mali',
  },
  {
    country_code: 'MM',
    lat: 21.913965,
    lng: 95.956223,
    country_name: 'Myanmar [Burma]',
  },
  {
    country_code: 'MN',
    lat: 46.862496,
    lng: 103.846656,
    country_name: 'Mongolia',
  },
  {
    country_code: 'MO',
    lat: 22.198745,
    lng: 113.543873,
    country_name: 'Macau',
  },
  {
    country_code: 'MP',
    lat: 17.33083,
    lng: 145.38469,
    country_name: 'Northern Mariana Islands',
  },
  {
    country_code: 'MQ',
    lat: 14.641528,
    lng: -61.024174,
    country_name: 'Martinique',
  },
  {
    country_code: 'MR',
    lat: 21.00789,
    lng: -10.940835,
    country_name: 'Mauritania',
  },
  {
    country_code: 'MS',
    lat: 16.742498,
    lng: -62.187366,
    country_name: 'Montserrat',
  },
  {
    country_code: 'MT',
    lat: 35.937496,
    lng: 14.375416,
    country_name: 'Malta',
  },
  {
    country_code: 'MU',
    lat: -20.348404,
    lng: 57.552152,
    country_name: 'Mauritius',
  },
  {
    country_code: 'MV',
    lat: 3.202778,
    lng: 73.22068,
    country_name: 'Maldives',
  },
  {
    country_code: 'MW',
    lat: -13.254308,
    lng: 34.301525,
    country_name: 'Malawi',
  },
  {
    country_code: 'MX',
    lat: 23.634501,
    lng: -102.552784,
    country_name: 'Mexico',
  },
  {
    country_code: 'MY',
    lat: 4.210484,
    lng: 101.975766,
    country_name: 'Malaysia',
  },
  {
    country_code: 'MZ',
    lat: -18.665695,
    lng: 35.529562,
    country_name: 'Mozambique',
  },
  {
    country_code: 'NA',
    lat: -22.95764,
    lng: 18.49041,
    country_name: 'Namibia',
  },
  {
    country_code: 'NC',
    lat: -20.904305,
    lng: 165.618042,
    country_name: 'New Caledonia',
  },
  {
    country_code: 'NE',
    lat: 17.607789,
    lng: 8.081666,
    country_name: 'Niger',
  },
  {
    country_code: 'NF',
    lat: -29.040835,
    lng: 167.954712,
    country_name: 'Norfolk Island',
  },
  {
    country_code: 'NG',
    lat: 9.081999,
    lng: 8.675277,
    country_name: 'Nigeria',
  },
  {
    country_code: 'NI',
    lat: 12.865416,
    lng: -85.207229,
    country_name: 'Nicaragua',
  },
  {
    country_code: 'NL',
    lat: 52.132633,
    lng: 5.291266,
    country_name: 'Netherlands',
  },
  {
    country_code: 'NO',
    lat: 60.472024,
    lng: 8.468946,
    country_name: 'Norway',
  },
  {
    country_code: 'NP',
    lat: 28.394857,
    lng: 84.124008,
    country_name: 'Nepal',
  },
  {
    country_code: 'NR',
    lat: -0.522778,
    lng: 166.931503,
    country_name: 'Nauru',
  },
  {
    country_code: 'NU',
    lat: -19.054445,
    lng: -169.867233,
    country_name: 'Niue',
  },
  {
    country_code: 'NZ',
    lat: -40.900557,
    lng: 174.885971,
    country_name: 'New Zealand',
  },
  {
    country_code: 'OM',
    lat: 21.512583,
    lng: 55.923255,
    country_name: 'Oman',
  },
  {
    country_code: 'PA',
    lat: 8.537981,
    lng: -80.782127,
    country_name: 'Panama',
  },
  {
    country_code: 'PE',
    lat: -9.189967,
    lng: -75.015152,
    country_name: 'Peru',
  },
  {
    country_code: 'PF',
    lat: -17.679742,
    lng: -149.406843,
    country_name: 'French Polynesia',
  },
  {
    country_code: 'PG',
    lat: -6.314993,
    lng: 143.95555,
    country_name: 'Papua New Guinea',
  },
  {
    country_code: 'PH',
    lat: 12.879721,
    lng: 121.774017,
    country_name: 'Philippines',
  },
  {
    country_code: 'PK',
    lat: 30.375321,
    lng: 69.345116,
    country_name: 'Pakistan',
  },
  {
    country_code: 'PL',
    lat: 51.919438,
    lng: 19.145136,
    country_name: 'Poland',
  },
  {
    country_code: 'PM',
    lat: 46.941936,
    lng: -56.27111,
    country_name: 'Saint Pierre and Miquelon',
  },
  {
    country_code: 'PN',
    lat: -24.703615,
    lng: -127.439308,
    country_name: 'Pitcairn Islands',
  },
  {
    country_code: 'PR',
    lat: 18.220833,
    lng: -66.590149,
    country_name: 'Puerto Rico',
  },
  {
    country_code: 'PS',
    lat: 31.952162,
    lng: 35.233154,
    country_name: 'Palestinian Territories',
  },
  {
    country_code: 'PT',
    lat: 39.399872,
    lng: -8.224454,
    country_name: 'Portugal',
  },
  {
    country_code: 'PW',
    lat: 7.51498,
    lng: 134.58252,
    country_name: 'Palau',
  },
  {
    country_code: 'PY',
    lat: -23.442503,
    lng: -58.443832,
    country_name: 'Paraguay',
  },
  {
    country_code: 'QA',
    lat: 25.354826,
    lng: 51.183884,
    country_name: 'Qatar',
  },
  {
    country_code: 'RE',
    lat: -21.115141,
    lng: 55.536384,
    country_name: 'Réunion',
  },
  {
    country_code: 'RO',
    lat: 45.943161,
    lng: 24.96676,
    country_name: 'Romania',
  },
  {
    country_code: 'RS',
    lat: 44.016521,
    lng: 21.005859,
    country_name: 'Serbia',
  },
  {
    country_code: 'RU',
    lat: 61.52401,
    lng: 105.318756,
    country_name: 'Russia',
  },
  {
    country_code: 'RW',
    lat: -1.940278,
    lng: 29.873888,
    country_name: 'Rwanda',
  },
  {
    country_code: 'SA',
    lat: 23.885942,
    lng: 45.079162,
    country_name: 'Saudi Arabia',
  },
  {
    country_code: 'SB',
    lat: -9.64571,
    lng: 160.156194,
    country_name: 'Solomon Islands',
  },
  {
    country_code: 'SC',
    lat: -4.679574,
    lng: 55.491977,
    country_name: 'Seychelles',
  },
  {
    country_code: 'SD',
    lat: 12.862807,
    lng: 30.217636,
    country_name: 'Sudan',
  },
  {
    country_code: 'SE',
    lat: 60.128161,
    lng: 18.643501,
    country_name: 'Sweden',
  },
  {
    country_code: 'SG',
    lat: 1.352083,
    lng: 103.819836,
    country_name: 'Singapore',
  },
  {
    country_code: 'SH',
    lat: -24.143474,
    lng: -10.030696,
    country_name: 'Saint Helena',
  },
  {
    country_code: 'SI',
    lat: 46.151241,
    lng: 14.995463,
    country_name: 'Slovenia',
  },
  {
    country_code: 'SJ',
    lat: 77.553604,
    lng: 23.670272,
    country_name: 'Svalbard and Jan Mayen',
  },
  {
    country_code: 'SK',
    lat: 48.669026,
    lng: 19.699024,
    country_name: 'Slovakia',
  },
  {
    country_code: 'SL',
    lat: 8.460555,
    lng: -11.779889,
    country_name: 'Sierra Leone',
  },
  {
    country_code: 'SM',
    lat: 43.94236,
    lng: 12.457777,
    country_name: 'San Marino',
  },
  {
    country_code: 'SN',
    lat: 14.497401,
    lng: -14.452362,
    country_name: 'Senegal',
  },
  {
    country_code: 'SO',
    lat: 5.152149,
    lng: 46.199616,
    country_name: 'Somalia',
  },
  {
    country_code: 'SR',
    lat: 3.919305,
    lng: -56.027783,
    country_name: 'Suriname',
  },
  {
    country_code: 'ST',
    lat: 0.18636,
    lng: 6.613081,
    country_name: 'São Tomé and Príncipe',
  },
  {
    country_code: 'SV',
    lat: 13.794185,
    lng: -88.89653,
    country_name: 'El Salvador',
  },
  {
    country_code: 'SY',
    lat: 34.802075,
    lng: 38.996815,
    country_name: 'Syria',
  },
  {
    country_code: 'SZ',
    lat: -26.522503,
    lng: 31.465866,
    country_name: 'Swaziland',
  },
  {
    country_code: 'TC',
    lat: 21.694025,
    lng: -71.797928,
    country_name: 'Turks and Caicos Islands',
  },
  {
    country_code: 'TD',
    lat: 15.454166,
    lng: 18.732207,
    country_name: 'Chad',
  },
  {
    country_code: 'TF',
    lat: -49.280366,
    lng: 69.348557,
    country_name: 'French Southern Territories',
  },
  {
    country_code: 'TG',
    lat: 8.619543,
    lng: 0.824782,
    country_name: 'Togo',
  },
  {
    country_code: 'TH',
    lat: 15.870032,
    lng: 100.992541,
    country_name: 'Thailand',
  },
  {
    country_code: 'TJ',
    lat: 38.861034,
    lng: 71.276093,
    country_name: 'Tajikistan',
  },
  {
    country_code: 'TK',
    lat: -8.967363,
    lng: -171.855881,
    country_name: 'Tokelau',
  },
  {
    country_code: 'TL',
    lat: -8.874217,
    lng: 125.727539,
    country_name: 'Timor-Leste',
  },
  {
    country_code: 'TM',
    lat: 38.969719,
    lng: 59.556278,
    country_name: 'Turkmenistan',
  },
  {
    country_code: 'TN',
    lat: 33.886917,
    lng: 9.537499,
    country_name: 'Tunisia',
  },
  {
    country_code: 'TO',
    lat: -21.178986,
    lng: -175.198242,
    country_name: 'Tonga',
  },
  {
    country_code: 'TR',
    lat: 38.963745,
    lng: 35.243322,
    country_name: 'Turkey',
  },
  {
    country_code: 'TT',
    lat: 10.691803,
    lng: -61.222503,
    country_name: 'Trinidad and Tobago',
  },
  {
    country_code: 'TV',
    lat: -7.109535,
    lng: 177.64933,
    country_name: 'Tuvalu',
  },
  {
    country_code: 'TW',
    lat: 23.69781,
    lng: 120.960515,
    country_name: 'Taiwan',
  },
  {
    country_code: 'TZ',
    lat: -6.369028,
    lng: 34.888822,
    country_name: 'Tanzania',
  },
  {
    country_code: 'UA',
    lat: 48.379433,
    lng: 31.16558,
    country_name: 'Ukraine',
  },
  {
    country_code: 'UG',
    lat: 1.373333,
    lng: 32.290275,
    country_name: 'Uganda',
  },
  {
    country_code: 'US',
    lat: 37.09024,
    lng: -95.712891,
    country_name: 'United States',
  },
  {
    country_code: 'UY',
    lat: -32.522779,
    lng: -55.765835,
    country_name: 'Uruguay',
  },
  {
    country_code: 'UZ',
    lat: 41.377491,
    lng: 64.585262,
    country_name: 'Uzbekistan',
  },
  {
    country_code: 'VA',
    lat: 41.902916,
    lng: 12.453389,
    country_name: 'Vatican City',
  },
  {
    country_code: 'VC',
    lat: 12.984305,
    lng: -61.287228,
    country_name: 'Saint Vincent and the Grenadines',
  },
  {
    country_code: 'VE',
    lat: 6.42375,
    lng: -66.58973,
    country_name: 'Venezuela',
  },
  {
    country_code: 'VG',
    lat: 18.420695,
    lng: -64.639968,
    country_name: 'British Virgin Islands',
  },
  {
    country_code: 'VI',
    lat: 18.335765,
    lng: -64.896335,
    country_name: 'U.S. Virgin Islands',
  },
  {
    country_code: 'VN',
    lat: 14.058324,
    lng: 108.277199,
    country_name: 'Vietnam',
  },
  {
    country_code: 'VU',
    lat: -15.376706,
    lng: 166.959158,
    country_name: 'Vanuatu',
  },
  {
    country_code: 'WF',
    lat: -13.768752,
    lng: -177.156097,
    country_name: 'Wallis and Futuna',
  },
  {
    country_code: 'WS',
    lat: -13.759029,
    lng: -172.104629,
    country_name: 'Samoa',
  },
  {
    country_code: 'XK',
    lat: 42.602636,
    lng: 20.902977,
    country_name: 'Kosovo',
  },
  {
    country_code: 'YE',
    lat: 15.552727,
    lng: 48.516388,
    country_name: 'Yemen',
  },
  {
    country_code: 'YT',
    lat: -12.8275,
    lng: 45.166244,
    country_name: 'Mayotte',
  },
  {
    country_code: 'ZA',
    lat: -30.559482,
    lng: 22.937506,
    country_name: 'South Africa',
  },
  {
    country_code: 'ZM',
    lat: -13.133897,
    lng: 27.849332,
    country_name: 'Zambia',
  },
  {
    country_code: 'ZW',
    lat: -19.015438,
    lng: 29.154857,
    country_name: 'Zimbabwe',
  },
];

export { eventMapCountries };
