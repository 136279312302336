import { TRegionCode } from '../locale';

const ENVIRONMENT_NAMES = [
  'local_dev',
  'example_dev',
  'playground',
  'development',
  'cms_preview_development',
  'stage',
  'cms_preview_stage',
  'production_test',
  'production',
] as const;

type TAllEnvironments = typeof ENVIRONMENT_NAMES[number];
type TEnvironments = {
  [K in TAllEnvironments as `${Uppercase<string & K>}`]: TAllEnvironments;
};
const ENVIRONMENTS: TEnvironments = Object.fromEntries(
  ENVIRONMENT_NAMES.map((name) => [[`${name.toUpperCase()}`], name])
);

type TBemerHostnames = {
  [K in TAllEnvironments]: string;
};

/**
 * A map of environment names to the domain it runs on.
 */
const BEMER_HOSTNAMES: TBemerHostnames = {
  local_dev: 'localhost',
  // in order to use example.org, run `yarn addHostname` first
  example_dev: 'example.org',

  playground: 'dev.bemer.services',

  development: 'dev.bemer.services',
  cms_preview_development: 'dev.bemer.services',

  stage: 'stage.bemer.services',
  cms_preview_stage: 'stage.bemer.services',

  production_test: 'b3m3r.com',
  production: 'bemergroup.com',
};

/**
 * A list of subdomains which are allowed in the BEMER universe, but must not be resolved as partner handles.
 *
 */
const IGNORED_PARTNER_HANDLES = ['www'];

/**
 * An array of all available domains.
 *
 * The `new Set` makes them unique.
 */
const BEMER_DOMAINS: string[] = [
  ...new Set(
    Object.keys(BEMER_HOSTNAMES).map(
      (environment) => BEMER_HOSTNAMES[environment as TAllEnvironments]
    )
  ),
];

type TBemerDomain = typeof BEMER_DOMAINS[number];

/**
 * Helper to get a domain for a given hostname.
 *
 * The given hostname can contain a partner subdomain like "partnerABC.bemergroup.com".
 * Such a hostname would return the domain "bemergroup.com", since it is included in
 * the hostname.
 *
 * @param hostname
 */
const getDomainForHostname = (hostname: string): TBemerDomain => {
  const foundDomain = BEMER_DOMAINS.find(
    (domain) => hostname.indexOf(domain) > -1
  );
  if (!foundDomain) {
    throw new Error('No known domain found in the given hostname.');
  }
  return foundDomain;
};

/**
 * Convenience helper to get a domain for a given location. Calls getDomainForHostname.
 *
 * @param location
 */
const getDomainForLocation = (location: Location): TBemerDomain =>
  getDomainForHostname(location.hostname);

/**
 * Helper to get the cookie domain params for the current URL.
 */
const getCookieDomain = (): string => {
  // Do nothing when SSR.
  if (typeof window === 'undefined') {
    throw new Error(
      'It is not possible to get the cookie domain in a server environment.'
    );
  }

  return window.location.hostname === 'localhost'
    ? ''
    : ` ;domain=${getDomainForHostname(window.location.hostname)}`;
};

const PROTOCOL = 'https://';

// ENVIRONMENT_BEMER_MARKETING can be one of the ENVIRONMENTS.
const environment =
  process.env.GATSBY_ENVIRONMENT ||
  process.env.ENVIRONMENT_BEMER_MARKETING ||
  process.env.SANITY_STUDIO_ENVIRONMENT_BEMER_MARKETING ||
  process.env.GATSBY_ENVIRONMENT_BEMER_MARKETING;
const fallbackEnvironment =
  process.env.NODE_ENV === 'development'
    ? ENVIRONMENTS.PLAYGROUND
    : ENVIRONMENTS.STAGE;
const currentEnvironment = environment || fallbackEnvironment;

let endpointUrlPrefixBemerMarketing: string;
// TODO configure endpoints separate from base package
// let endpointUrlPrefixBemerPartnerLibrary: string;

type TMarketingSiteHostUrls = {
  [k in TRegionCode]: string;
};

const getHostUrl = (regionHandle?: string): string => {
  const subdomain = regionHandle ? `${regionHandle}.` : '';
  return `${PROTOCOL}${subdomain}${
    BEMER_HOSTNAMES[currentEnvironment as TAllEnvironments]
  }`;
};

const bemerMarketingSiteUrls: TMarketingSiteHostUrls = {
  USA: getHostUrl(),
  EUROPE: getHostUrl(),
  ITALY: getHostUrl(),
  WORLD: getHostUrl(),
  CANADA: getHostUrl(),
};

// use these alternative values for local testing of language picker functionality
// const bemerMarketingSiteUrls: TMarketingSiteHostUrls = {
//   USA: getHostUrl(),
//   EUROPE: 'http://localhost:8000',
//   ITALY: 'http://localhost:8000',
//   WORLD: 'http://localhost:8000',
//   CANADA: 'http://localhost:8000',
// };

/**
 * Returns the marketingSite hosturl for a specific region
 *
 * @param localeIdentifier
 */
const getMarketingSiteHostUrlForRegion = (regionCode: TRegionCode): string => {
  const hostUrl = bemerMarketingSiteUrls[regionCode];
  if (!hostUrl) {
    throw new Error(
      `No known hostUrl found for the given region: ${regionCode}.`
    );
  }
  return hostUrl;
};

switch (currentEnvironment) {
  case ENVIRONMENTS.DEVELOPMENT:
  case ENVIRONMENTS.PLAYGROUND:
  case ENVIRONMENTS.STAGE:
  case ENVIRONMENTS.PRODUCTION:
  case ENVIRONMENTS.CMS_PREVIEW_DEVELOPMENT:
  case ENVIRONMENTS.CMS_PREVIEW_STAGE: {
    endpointUrlPrefixBemerMarketing = `${PROTOCOL}${BEMER_HOSTNAMES[currentEnvironment]}`;
    break;
  }
  default: {
    endpointUrlPrefixBemerMarketing = '';
  }
}

const API_ENDPOINTS = {
  BEMER_MARKETING: `${endpointUrlPrefixBemerMarketing}/api`,
  PARTNER_FINDER: `${endpointUrlPrefixBemerMarketing}/address`,
  EVENTS: `${endpointUrlPrefixBemerMarketing}/ep`,
};

export {
  BEMER_HOSTNAMES,
  BEMER_DOMAINS,
  IGNORED_PARTNER_HANDLES,
  getDomainForHostname,
  getDomainForLocation,
  getCookieDomain,
  ENVIRONMENTS,
  TAllEnvironments,
  API_ENDPOINTS,
  getMarketingSiteHostUrlForRegion,
};
