import { TLocaleId, TRegionCode } from '../locale';

interface ICountry {
  isRegionMaster: boolean;
  region: {
    regionCode: TRegionCode;
  };
}

interface ILocaleIdentifier {
  _id: string;
  localeId: TLocaleId;
  country: ICountry;
}
interface IInternalRouteRawDataParentRootPage {
  _id: string;
  _type: 'rootPage';
  title: string;
}

interface IInternalRouteRawDataParentPage
  extends Omit<IInternalRouteRawDataParentRootPage, '_type'> {
  _type: 'page';
  internalName: string;
  slug: {
    current: string;
  };
  localeIdentifier: ILocaleIdentifier;
  parentPage:
    | IInternalRouteRawDataParentPage
    | IInternalRouteRawDataParentRootPage;
}

type TInternalRoutePageTree =
  | IInternalRouteRawDataParentPage
  | IInternalRouteRawDataParentRootPage;

const getMasterPagesUriPrefix = (country: ICountry): string =>
  `${
    country.isRegionMaster ? `___MASTER_${country.region.regionCode}___` : ''
  }`;

const buildInternalRoute = (
  tree?: TInternalRoutePageTree,
  localeIdentifier?: ILocaleIdentifier
): string => {
  const routes: string[] = [];

  // 1. Handle all cases where just a slash is returned.
  if (!tree || !tree._id || !tree._type) {
    return '/';
  }
  if (tree._type === 'rootPage') {
    return '/';
  }
  if (
    !tree.slug ||
    !tree.slug.current ||
    !tree.localeIdentifier ||
    !tree.localeIdentifier.localeId ||
    !tree.localeIdentifier.country ||
    !tree.localeIdentifier.country.region ||
    !tree.localeIdentifier.country.region.regionCode
  ) {
    return '/';
  }

  // 2. Set the localIdentifier to use.
  const actualLocalIdentifier = localeIdentifier || tree.localeIdentifier;

  // 3. Add the slug of the page.
  routes.push(tree.slug.current);

  // 4. Add all other parent page slugs.
  const addRouteSegments = (
    currentPage: TInternalRoutePageTree,
    routeArray: string[]
  ) => {
    if (!currentPage || !currentPage._id || !currentPage._type) {
      throw new Error('Error while processing page tree.');
    }
    // Reached the end, stop here.
    if (currentPage._type === 'rootPage') {
      return;
    }
    if (!currentPage.slug || !currentPage.slug.current) {
      throw new Error('Error while processing page tree.');
    }

    routeArray.push(currentPage.slug.current);
    addRouteSegments(currentPage.parentPage, routeArray);
  };

  try {
    addRouteSegments(tree.parentPage, routes);
  } catch (error) {
    return '/';
  }

  // 5. Add the locale id.
  routes.push(actualLocalIdentifier.localeId);

  // 6. Add additional prefix for master pages.
  const masterPagesUriPrefix = getMasterPagesUriPrefix(
    actualLocalIdentifier.country
  );
  if (masterPagesUriPrefix) {
    routes.push(masterPagesUriPrefix);
  }

  // 7. Build the route with a leading slash and remove all double slashes and tailing slashes.
  return `/${routes.reverse().join('/')}`.replace('//', '/').replace(/\/$/, '');
};

/**
 * Helper function to get an internalRoutePageTree from a route string.
 *
 * This should only be uses in tests or storybook to reduce the required code when working
 * with routes.
 *
 * @param route string
 */
const getInternalRoutePageTreeForRoute = (
  route = '/'
): TInternalRoutePageTree => {
  const rootPage = {
    _id: 'rootPage',
    _type: 'rootPage',
    title: 'Bemer',
  } as const;

  if (!route || route === '/' || route === '#') {
    return rootPage;
  }

  // Get the localeId
  const sanitizedRoute = route[0] === '/' ? route : `/${route}`;
  const separateLocaleFromRouteMatch = sanitizedRoute.match(
    /(\/)([a-z]{2}_[A-Z]{2})(\/)(.*)/
  );
  if (
    !separateLocaleFromRouteMatch ||
    !separateLocaleFromRouteMatch[2] ||
    !separateLocaleFromRouteMatch[4]
  ) {
    return rootPage;
  }
  const localeId = separateLocaleFromRouteMatch[2] as TLocaleId;
  const splitRoutes = separateLocaleFromRouteMatch[4].split('/');
  const routes = splitRoutes.reverse();

  const buildPage = (index: number): TInternalRoutePageTree => {
    if (!routes || !routes[index]) {
      return rootPage;
    }

    const nextIndex = routes[index + 1] === localeId ? index + 2 : index + 1;
    return {
      _id: `page-${index}`,
      internalName: `P000${index}`,
      _type: 'page',
      title: `${routes[index][0].toUpperCase()}${routes[index].slice(1)}`,
      slug: {
        current: routes[index],
      },
      localeIdentifier: {
        _id: `localeIdentifier-${localeId}`,
        localeId,
        country: {
          isRegionMaster: false,
          region: {
            regionCode: 'EUROPE',
          },
        },
      },
      parentPage: buildPage(nextIndex),
    };
  };

  return buildPage(0);
};

export {
  buildInternalRoute,
  getInternalRoutePageTreeForRoute,
  TInternalRoutePageTree,
  ILocaleIdentifier,
};
