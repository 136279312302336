import { httpStatusCodes } from './HttpStatusCodes';
import { ValidationError } from './ValidationError';

class NoDataFoundError extends ValidationError {
  errorCode = httpStatusCodes.NO_DATA_FOUND.statusCode;

  errorType = httpStatusCodes.NO_DATA_FOUND.errorType;

  message = 'No data in response found';

  constructor(private customMessage?: string) {
    super(customMessage);
    Object.setPrototypeOf(this, NoDataFoundError.prototype);
  }

  serializeErrors() {
    return [
      {
        message: this.message,
        customMessage: this.customMessage || '',
        errorCode: this.errorCode,
        errorType: this.errorType,
      },
    ];
  }
}
export { NoDataFoundError };
