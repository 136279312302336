import { httpStatusCodes } from './HttpStatusCodes';
import { ValidationError } from './ValidationError';

class NoLongitudeError extends ValidationError {
  errorCode = httpStatusCodes.NO_LONGITUDE.statusCode;

  errorType = httpStatusCodes.NO_LONGITUDE.errorType;

  message = 'No longitude given.';

  constructor(private customMessage?: string) {
    super(customMessage);
    Object.setPrototypeOf(this, NoLongitudeError.prototype);
  }

  serializeErrors() {
    return [
      {
        message: this.message,
        customMessage: this.customMessage || '',
        errorCode: this.errorCode,
        errorType: this.errorType,
      },
    ];
  }
}
export { NoLongitudeError };
