const DURATION = {
  NONE: 0,
  SHORT: 0.2,
  MEDIUM: 0.4,
  LONG: 0.6,
  VERY_LONG: 1,
};

// TODO: define global transitions here, only applies to css transitions not
// framer-motion transitions

const transitions = {};

export { transitions as default, DURATION };
