import { getObjectKeys } from './utils/getObjectKeys';
/**
 * All available currencies in the BEMER universe.
 */

const currencies = {
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HUF: 'HUF',
  MYR: 'MYR',
  NOK: 'NOK',
  PLN: 'PLN',
  RON: 'RON',
  RSD: 'RSD',
  SEK: 'SEK',
  TWD: 'TWD',
  USD: 'USD',
  ZAR: 'ZAR',
} as const;

/**
 * All available languages in the BEMER universe.
 */
const languages = {
  czech: {
    languageName: 'Čeština',
    languageCode: 'cs',
    quotes: { start: '„', end: '“' },
  },
  danish: {
    languageName: 'Dansk',
    languageCode: 'da',
    quotes: { start: '»', end: '«' },
  },
  dutch: {
    languageName: 'Nederlands',
    languageCode: 'nl',
    quotes: { start: '“', end: '”' },
  },
  english: {
    languageName: 'English',
    languageCode: 'en',
    quotes: { start: '“', end: '”' },
  },
  finnish: {
    languageName: 'Suomalainen',
    languageCode: 'fi',
    quotes: { start: '”', end: '”' },
  },
  french: {
    languageName: 'Français',
    languageCode: 'fr',
    quotes: { start: '«', end: '»' },
  },
  german: {
    languageName: 'Deutsch',
    languageCode: 'de',
    quotes: { start: '„', end: '“' },
  },
  hungary: {
    languageName: 'Magyarország',
    languageCode: 'hu',
    quotes: { start: '„', end: '“' },
  },
  italian: {
    languageName: 'Italiano',
    languageCode: 'it',
    quotes: { start: '«', end: '»' },
  },
  lithuanian: {
    languageName: 'Lietuviai',
    languageCode: 'lt',
    quotes: { start: '„', end: '“' },
  },
  malay: {
    languageName: 'မလေး',
    languageCode: 'my',
    quotes: { start: '“', end: '“' },
  },
  mandarin: {
    languageName: '普通话',
    languageCode: 'zh',
    quotes: { start: '“', end: '”' },
  },
  norwegian: {
    languageName: 'Norsk',
    languageCode: 'no',
    quotes: { start: '«', end: '»' },
  },
  polish: {
    languageName: 'Polskie',
    languageCode: 'pl',
    quotes: { start: '„', end: '”' },
  },
  portuguese: {
    languageName: 'Português',
    languageCode: 'pt',
    quotes: { start: '«', end: '»' },
  },
  romanian: {
    languageName: 'Română',
    languageCode: 'ro',
    quotes: { start: '„', end: '”' },
  },
  serbian: {
    languageName: 'Српски',
    languageCode: 'sr',
    quotes: { start: '„', end: '”' },
  },
  slovak: {
    languageName: 'Slovenčina',
    languageCode: 'sk',
    quotes: { start: '„', end: '“' },
  },
  spanish: {
    languageName: 'Español',
    languageCode: 'es',
    quotes: { start: '«', end: '»' },
  },
  swedish: {
    languageName: 'Svenska',
    languageCode: 'sv',
    quotes: { start: '”', end: '”' },
  },
} as const;

/**
 * All available countries in the BEMER universe.
 *
 * THE ORDER OF THE LANGUAGES ARRAY MATTERS!
 * The first language in the languages array is the default language of that country.
 */
const allCountries = {
  austria: {
    countryName: 'Österreich',
    countryCode: 'AT',
    icon: '🇦🇹',
    currency: currencies.EUR,
    languages: [languages.german, languages.english],
  },
  belgium: {
    countryName: 'België',
    countryCode: 'BE',
    icon: '🇧🇪',
    currency: currencies.EUR,
    languages: [
      languages.german,
      languages.dutch,
      languages.french,
      languages.english,
    ],
  },
  brazil: {
    countryName: 'Brasil',
    countryCode: 'BR',
    icon: '🇧🇷',
    currency: currencies.BRL,
    languages: [languages.portuguese, languages.english],
  },
  canada: {
    countryName: 'Canada',
    countryCode: 'CA',
    icon: '🇨🇦',
    currency: currencies.CAD,
    languages: [languages.english, languages.french],
  },
  czechRepublic: {
    countryName: 'Česká republika',
    countryCode: 'CZ',
    icon: '🇨🇿',
    currency: currencies.CZK,
    languages: [languages.czech, languages.english],
  },
  denmark: {
    countryName: 'Danmark',
    countryCode: 'DK',
    icon: '🇩🇰',
    currency: currencies.DKK,
    languages: [languages.danish, languages.english],
  },
  finland: {
    countryName: 'Suomi',
    countryCode: 'FI',
    icon: '🇫🇮',
    currency: currencies.EUR,
    languages: [languages.finnish, languages.english],
  },
  france: {
    countryName: 'France',
    countryCode: 'FR',
    icon: '🇫🇷',
    currency: currencies.EUR,
    languages: [languages.french, languages.english],
  },
  germany: {
    countryName: 'Deutschland',
    countryCode: 'DE',
    icon: '🇩🇪',
    currency: currencies.EUR,
    languages: [languages.german, languages.english],
  },
  unitedKingdom: {
    countryName: 'United Kingdom',
    countryCode: 'GB',
    icon: '🇬🇧',
    currency: currencies.GBP,
    languages: [languages.english],
  },
  hungary: {
    countryName: 'Magyarország',
    countryCode: 'HU',
    icon: '🇭🇺',
    currency: currencies.HUF,
    languages: [languages.hungary, languages.english],
  },
  ireland: {
    countryName: 'Éireann',
    countryCode: 'IE',
    icon: '🇮🇪',
    currency: currencies.EUR,
    languages: [languages.english],
  },
  italy: {
    countryName: 'Italia',
    countryCode: 'IT',
    icon: '🇮🇹',
    currency: currencies.EUR,
    languages: [languages.italian, languages.english],
  },
  liechtenstein: {
    countryName: 'Liechtenstein',
    countryCode: 'LI',
    icon: '🇱🇮',
    currency: currencies.CHF,
    languages: [languages.german, languages.english],
  },
  lithuania: {
    countryName: 'Lietuva',
    countryCode: 'LT',
    icon: '🇱🇹',
    currency: currencies.EUR,
    languages: [languages.lithuanian, languages.english],
  },
  luxembourg: {
    countryName: 'Luxembourg',
    countryCode: 'LU',
    icon: '🇱🇺',
    currency: currencies.EUR,
    languages: [languages.german, languages.french, languages.english],
  },
  malaysia: {
    countryName: 'မလေးရှား',
    countryCode: 'MY',
    icon: '🇲🇾',
    currency: currencies.MYR,
    languages: [languages.malay, languages.english],
  },
  netherlands: {
    countryName: 'Nederland',
    countryCode: 'NL',
    icon: '🇳🇱',
    currency: currencies.EUR,
    languages: [languages.dutch, languages.english],
  },
  norway: {
    countryName: 'Norge',
    countryCode: 'NO',
    icon: '🇳🇴',
    currency: currencies.NOK,
    languages: [languages.norwegian, languages.english],
  },
  poland: {
    countryName: 'Polska',
    countryCode: 'PL',
    icon: '🇵🇱',
    currency: currencies.PLN,
    languages: [languages.polish, languages.english],
  },
  romania: {
    countryName: 'România',
    countryCode: 'RO',
    icon: '🇷🇴',
    currency: currencies.RON,
    languages: [languages.romanian, languages.english],
  },
  serbia: {
    countryName: 'Србија',
    countryCode: 'RS',
    icon: '🇷🇸',
    currency: currencies.RSD,
    languages: [languages.serbian, languages.english],
  },
  slovakia: {
    countryName: 'Slovensko',
    countryCode: 'SK',
    icon: '🇸🇰',
    currency: currencies.EUR,
    languages: [languages.slovak, languages.english],
  },
  southAfrica: {
    countryName: 'South Africa',
    countryCode: 'ZA',
    icon: '🇿🇦',
    currency: currencies.ZAR,
    languages: [languages.english],
  },
  spain: {
    countryName: 'España',
    countryCode: 'ES',
    icon: '🇪🇸',
    currency: currencies.EUR,
    languages: [languages.spanish, languages.english],
  },
  sweden: {
    countryName: 'Sverige',
    countryCode: 'SE',
    icon: '🇸🇪',
    currency: currencies.SEK,
    languages: [languages.swedish, languages.english],
  },
  switzerland: {
    countryName: 'Schweiz',
    countryCode: 'CH',
    icon: '🇨🇭',
    currency: currencies.CHF,
    languages: [
      languages.german,
      languages.italian,
      languages.french,
      languages.english,
    ],
  },
  taiwan: {
    countryName: '台湾',
    countryCode: 'TW',
    icon: '🇹🇼',
    currency: currencies.TWD,
    languages: [languages.mandarin, languages.english],
  },
  unitedStates: {
    countryName: 'United States',
    countryCode: 'US',
    icon: '🇺🇸',
    currency: currencies.USD,
    languages: [languages.english, languages.spanish],
  },
} as const;

/**
 * All available regions in the BEMER universe.
 */
const regions = {
  europe: {
    regionCode: 'EUROPE',
    regionName: 'Europe',
    icon: '🇪🇺',
    globeIcon: '🌍',
    countries: [
      allCountries.austria,
      allCountries.belgium,
      allCountries.czechRepublic,
      allCountries.denmark,
      allCountries.finland,
      allCountries.france,
      allCountries.germany,
      allCountries.hungary,
      allCountries.ireland,
      allCountries.liechtenstein,
      allCountries.lithuania,
      allCountries.luxembourg,
      allCountries.netherlands,
      allCountries.norway,
      allCountries.poland,
      allCountries.slovakia,
      allCountries.spain,
      allCountries.sweden,
      allCountries.switzerland,
      allCountries.unitedKingdom,
    ],
  },
  canada: {
    regionCode: 'CANADA',
    regionName: 'Canada',
    icon: '🍁',
    globeIcon: '🌎',
    countries: [allCountries.canada],
  },
  usa: {
    regionCode: 'USA',
    regionName: 'USA',
    icon: '🗽',
    globeIcon: '🌎',
    countries: [allCountries.unitedStates],
  },
  italy: {
    regionCode: 'ITALY',
    regionName: 'Italy',
    icon: '🛵',
    globeIcon: '🌍',
    countries: [allCountries.italy, allCountries.serbia, allCountries.romania],
  },
  world: {
    regionCode: 'WORLD',
    regionName: 'World',
    icon: '🗺',
    globeIcon: '🌏',
    countries: [
      allCountries.southAfrica,
      allCountries.malaysia,
      allCountries.taiwan,
      allCountries.brazil,
    ],
  },
} as const;

type TCurrency = keyof typeof currencies;

type TLanguage = typeof languages[keyof typeof languages];
type TLanguageCode = TLanguage['languageCode'];
type TLanguageName = TLanguage['languageName'];

type TCountry = typeof allCountries[keyof typeof allCountries];
type TCountryKey = keyof typeof allCountries;
type TCountryCode = TCountry['countryCode'];
type TCountryName = TCountry['countryName'];
type TCountries = Record<TCountryKey, TCountry>;

type TLocaleMapper<Country, Separator = '_'> = {
  [CountryKey in keyof Country]: Country[CountryKey] extends TCountry
    ? `${Country[CountryKey]['languages'][number]['languageCode']}${Separator &
        string}${Country[CountryKey]['countryCode'] & string}`
    : never;
}[keyof Country & string];

type TLocaleId = TLocaleMapper<typeof allCountries, '_'>;
type TLocaleIdBcp47 = TLocaleMapper<typeof allCountries, '-'>;

type TRegion = typeof regions[keyof typeof regions];
type TRegionCode = TRegion['regionCode'];
type TRegionName = TRegion['regionName'];
type TRegionKey = keyof typeof regions;

interface ILocale {
  id: TLocaleId;
  bcp47Id: TLocaleIdBcp47;
  languageCode: TLanguageCode;
  countryCode: TCountryCode;
}

type TQuotesMap = {
  [key in TLocaleId]: {
    start: string;
    end: string;
  };
};

const generatedQuotes = {} as TQuotesMap;
getObjectKeys(allCountries).forEach((countryKey) => {
  const c = allCountries[countryKey];
  c.languages.forEach((language) => {
    generatedQuotes[`${language.languageCode}_${c.countryCode}` as TLocaleId] =
      language.quotes;
  });
});

/**
 * The exported quotes map including some country specific overwrites.
 */
const quotesMap: TQuotesMap = {
  ...generatedQuotes,
  pt_BR: { start: '“', end: '”' },
  en_GB: { start: '‘', end: '’' },
  de_CH: { start: '«', end: '»' },
  en_CH: { start: '«', end: '»' },
};

export {
  currencies,
  languages,
  allCountries,
  regions,
  quotesMap,
  TCurrency,
  TLanguage,
  TLanguageCode,
  TLanguageName,
  TCountry,
  TCountries,
  TCountryKey,
  TCountryCode,
  TCountryName,
  TRegion,
  TRegionCode,
  TRegionName,
  TRegionKey,
  TLocaleId,
  TLocaleIdBcp47,
  ILocale,
};
