import colors from '../colors';

const DEFAULT_COLOR = colors.headingSeparatorLine;
const HUMAN_LINE_COLOR = colors.headingSeparatorLineOnAccentBackgroundHumanLine;

const TILDE_WIDTH_MOBILE = 20;
const TILDE_HEIGHT_MOBILE = 10;

const TILDE_WIDTH_TABLET = 30;
const TILDE_HEIGHT_TABLET = 20;

const TILDE_WIDTH_DESKTOP = 40;
const TILDE_HEIGHT_DESKTOP = 30;

const getHeadingSeparatorLineUrl = (hexColor = DEFAULT_COLOR): string =>
  `url("data:image/svg+xml,<svg viewBox='0 0 100 2' width='100' xmlns='http://www.w3.org/2000/svg' color='${encodeURIComponent(
    hexColor
  )}'><line x1='0' y1='0' x2='100' y2='0' stroke='currentColor' stroke-width='2px'/></svg>")`;

const getHeadingSeparatorLinePseudoElement = (
  hexColor = DEFAULT_COLOR
): any => ({
  '&:after': {
    content: getHeadingSeparatorLineUrl(hexColor),
  },
});

const getHeadingSeparatorLinePseudoElementHumanLine = (): any =>
  getHeadingSeparatorLinePseudoElement(HUMAN_LINE_COLOR);

const getHeadingSeparatorTildeUrl = (
  width: number,
  height: number,
  hexColor = DEFAULT_COLOR
): string =>
  `url("data:image/svg+xml,<svg width='${width}' height='${height}' id='Tilde_Horse-Set' data-name='Tilde Horse-Set' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 283.66 102.66'><path fill='${encodeURIComponent(
    hexColor
  )}' class='cls-1' d='M297.74,38.32c0,29.63-26.95,77.07-86.37,77.07-24.67,0-41.59-7.62-55.46-17.1C133.07,82.68,118.49,62,91.21,62c-27.06,0-46.27,32.42-56.45,45.48l-.14.18a10,10,0,0,1-7.76,3.7c-8.84,0-12.78-10.59-12.78-21.59,0-29.63,27-77.07,86.37-77.07,24.67,0,41.59,7.62,55.46,17.1,22.84,15.61,37.42,36.26,64.7,36.26,27.06,0,46.27-32.42,56.45-45.48l.14-.18a10,10,0,0,1,7.76-3.7C293.8,16.73,297.74,27.32,297.74,38.32Z' transform='translate(-14.08 -12.73)'> </path></svg>")`;

const getHeadingSeparatorTildePseudoElement = (
  hexColor = DEFAULT_COLOR
): any => [
  getHeadingSeparatorTildeUrl(
    TILDE_WIDTH_MOBILE,
    TILDE_HEIGHT_MOBILE,
    hexColor
  ),
  getHeadingSeparatorTildeUrl(
    TILDE_WIDTH_TABLET,
    TILDE_HEIGHT_TABLET,
    hexColor
  ),
  getHeadingSeparatorTildeUrl(
    TILDE_WIDTH_DESKTOP,
    TILDE_HEIGHT_DESKTOP,
    hexColor
  ),
];
const getHeadingSeparatorTildePseudoElementVeterinary = (): any =>
  getHeadingSeparatorTildePseudoElement();

export {
  getHeadingSeparatorLineUrl,
  getHeadingSeparatorLinePseudoElement,
  getHeadingSeparatorLinePseudoElementHumanLine,
  getHeadingSeparatorTildeUrl,
  getHeadingSeparatorTildePseudoElement,
  getHeadingSeparatorTildePseudoElementVeterinary,
};
