abstract class CustomError extends Error {
  abstract errorType: string;

  abstract errorCode: number;

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  abstract serializeErrors(): { message: string; property?: string }[];
}
export { CustomError };
