import { httpStatusCodes } from './HttpStatusCodes';
import { ValidationError } from './ValidationError';

class NoWindowError extends ValidationError {
  errorCode = httpStatusCodes.NO_WINDOW.statusCode;

  errorType = httpStatusCodes.NO_WINDOW.errorType;

  message =
    'It is not possible to get/set the visitorId in a server environment.';

  constructor(private customMessage?: string) {
    super(customMessage);
    Object.setPrototypeOf(this, NoWindowError.prototype);
  }

  serializeErrors() {
    return [
      {
        message: this.message,
        customMessage: this.customMessage || '',
        errorCode: this.errorCode,
        errorType: this.errorType,
      },
    ];
  }
}
export { NoWindowError };
