import { TStyles } from './moduleTypes';
import { buttonTextCapsize } from './typography';

// Styles are based on https://projects.invisionapp.com/share/UWVPYO83CTX#/screens/455314527

const baseStyle = {
  fontWeight: 700,
  borderRadius: 0,
  px: [4, 6, 10],
  py: 5,
  cursor: 'pointer',
  borderWidth: '2px',
  borderStyle: 'solid',
  letterSpacing: '0.5px',
  outline: 'none',
  fontFamily: 'body',
  width: ['100%', 'auto', 'auto'],
  textTransform: 'uppercase' as const,

  ...buttonTextCapsize,
};

const BOX_SHADOW_PADDING = '0 8px 16px';
const ITEM_EXPANDER_OUTLINE_SMALL_SIZE = [6, 6, 6];
const ITEM_EXPANDER_SIZE = [10, 12, 12];
const SINGLE_ICON_SIZE = 14;

const sharedPseudoStatesPrimary = {
  '&:hover, &.storybook_pseudo_class--hover': {
    color: 'buttonPseudoStatesText',
    bg: 'buttonPseudoStatesBackground',
    borderColor: 'buttonPseudoStatesBorder',
    boxShadow: `${BOX_SHADOW_PADDING} rgba(255, 100, 0, 0.55)`,
  },
  '&:focus, &.storybook_pseudo_class--focus': {
    color: 'buttonPseudoStatesTextFocus',
    bg: 'buttonPseudoStatesBackgroundFocus',
    borderColor: 'buttonPseudoStatesBorderFocus',
  },
  '&:focus:hover, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover':
    {
      boxShadow: `${BOX_SHADOW_PADDING} rgba(255, 100, 0, 0.65)`,
    },
  '&:active, &.storybook_pseudo_class--active': {
    color: 'buttonPseudoStatesTextActive',
    bg: 'buttonPseudoStatesBackgroundActive',
    borderColor: 'buttonPseudoStatesBorderActive',
  },
  '&:disabled, &:disabled:hover, &.storybook_pseudo_class--disabled, &.storybook_pseudo_class--hover:disabled, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover:disabled':
    {
      color: 'buttonPseudoStatesTextDisabled',
      bg: 'buttonPseudoStatesBackgroundDisabled',
      boxShadow: 'none',
      borderColor: 'buttonPseudoStatesBorderDisabled',
      cursor: 'not-allowed',
    },
};

const sharedPseudoStatesSecondary = {
  '&:hover, &.storybook_pseudo_class--hover': {
    color: 'buttonSecondaryPseudoStatesText',
    bg: 'buttonSecondaryPseudoStatesBackground',
    borderColor: 'buttonPseudoStatesBorder',
    boxShadow: `${BOX_SHADOW_PADDING} rgba(45, 186, 232, 0.55)`,
  },
  '&:focus, &.storybook_pseudo_class--focus': {
    color: 'buttonSecondaryPseudoStatesTextFocus',
    bg: 'buttonSecondaryPseudoStatesBackgroundFocus',
    borderColor: 'buttonPseudoStatesBorderFocus',
  },
  '&:focus:hover, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover':
    {
      boxShadow: `${BOX_SHADOW_PADDING} rgba(23, 151, 203, 0.65)`,
    },
  '&:active, &.storybook_pseudo_class--active': {
    color: 'buttonSecondaryPseudoStatesTextActive',
    bg: 'buttonSecondaryPseudoStatesBackgroundActive',
    borderColor: 'buttonPseudoStatesBorderActive',
  },
  '&:disabled, &:disabled:hover, &.storybook_pseudo_class--disabled, &.storybook_pseudo_class--hover:disabled, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover:disabled':
    {
      color: 'buttonSecondaryPseudoStatesTextDisabled',
      bg: 'buttonSecondaryPseudoStatesBackgroundDisabled',
      boxShadow: 'none',
      borderColor: 'buttonPseudoStatesBorderDisabled',
      cursor: 'not-allowed',
    },
};

const primary = {
  ...baseStyle,

  color: 'buttonPrimaryText',
  bg: 'buttonPrimaryBackground',
  borderColor: 'buttonPrimaryBorder',

  ...sharedPseudoStatesPrimary,
};

const secondary = {
  ...baseStyle,

  color: 'buttonSecondaryText',
  bg: 'buttonSecondaryBackground',
  borderColor: 'buttonSecondaryBorder',

  ...sharedPseudoStatesSecondary,
};

const tertiary = {
  ...baseStyle,

  color: 'buttonTertiaryText',
  bg: 'buttonTertiaryBackground',
  borderColor: 'buttonTertiaryBorder',
  '&:disabled, &:disabled:hover, &.storybook_pseudo_class--disabled, &.storybook_pseudo_class--hover:disabled':
    {
      color: 'textMuted',
      bg: 'transparent',
      borderColor: 'transparent',
      cursor: 'not-allowed',
    },
};

const light = {
  ...tertiary,
  color: 'buttonLightText',
  bg: 'buttonLightBackground',
  '&:disabled, &:disabled:hover, &.storybook_pseudo_class--disabled, &.storybook_pseudo_class--hover:disabled':
    {
      color: 'buttonLightDisabled',
      cursor: 'not-allowed',
    },
};

const small = {
  variant: 'text.buttonLabelSmall',
  px: 7,
};

const lightSmall = {
  ...light,
  ...small,
};

const primarySmall = {
  ...primary,
  ...small,
};

const secondarySmall = {
  ...secondary,
  ...small,
};

const tertiarySmall = {
  ...tertiary,
  ...small,
};

const ghostLight = {
  ...baseStyle,
  color: 'ghostLightButtonText',
  borderColor: 'ghostLightButtonBorder',
  backgroundColor: 'ghostLightButtonBackground',
  '&:hover, &.storybook_pseudo_class--hover': {
    bg: 'ghostLightButtonBackgroundHover',
  },
  '&:disabled, &:disabled:hover, &.storybook_pseudo_class--disabled, &.storybook_pseudo_class--hover:disabled':
    {
      cursor: 'not-allowed',
      color: 'ghostLightButtonTextDisabled',
      borderColor: 'ghostLightButtonTextDisabled',
    },
};

const ghostDark = {
  ...baseStyle,
  color: 'ghostDarkButtonText',
  borderColor: 'ghostDarkButtonBorder',
  backgroundColor: 'ghostDarkButtonBackground',
  '&:hover, &.storybook_pseudo_class--hover': {
    bg: 'ghostDarkButtonBackgroundHover',
  },
  '&:disabled, &:disabled:hover, &.storybook_pseudo_class--disabled, &.storybook_pseudo_class--hover:disabled':
    {
      cursor: 'not-allowed',
      color: 'ghostDarkButtonTextDisabled',
    },
};

const singleIcon = {
  ...baseStyle,

  color: 'buttonSecondaryPseudoStatesText',
  bg: 'buttonPrimaryBackgroundOnPageBackgroundNeutral',
  borderColor: 'buttonPseudoStatesBorder',

  width: SINGLE_ICON_SIZE,
  height: SINGLE_ICON_SIZE,
  p: [0, 0, 0],
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,

  ...sharedPseudoStatesSecondary,
};

const play = {
  ...singleIcon,
};

const pagination = {
  ...singleIcon,
};

const itemExpander = {
  ...baseStyle,

  color: 'buttonSecondaryText',
  bg: 'white',
  borderColor: 'buttonSecondaryBorder',

  ...sharedPseudoStatesSecondary,

  right: 0,
  position: 'absolute' as const,
  p: [0, 0, 0],
  borderRadius: 'full',
  width: ITEM_EXPANDER_SIZE,
  height: ITEM_EXPANDER_SIZE,
  ml: 8,
  flexShrink: 0,
  lineHeight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const itemExpanderOutlineSmall = {
  ...ghostLight,

  bg: 'transparent',
  borderWidth: '2px',
  borderColor: 'currentColor',
  borderStyle: 'solid',
  right: 0,
  position: 'absolute' as const,
  p: [0, 0, 0],
  borderRadius: 'full',
  width: ITEM_EXPANDER_OUTLINE_SMALL_SIZE,
  height: ITEM_EXPANDER_OUTLINE_SMALL_SIZE,
  ml: 8,
  mr: 8,
  flexShrink: 0,
  lineHeight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const nav = {
  variant: 'links.nav',
};

const linkDefault = {
  variant: 'links.default',
};

const textNav = {
  variant: 'text.default',
};

const breadcrumbText = {
  variant: 'text.h5',
  color: 'breadCrumbText',
};

const breadcrumbLink = {
  textDecoration: 'none',
  color: 'breadCrumbLink',
};

const defaultClickableWrapper = {
  borderColor: 'currentColor',
  background: 'none',
  padding: 0,
  font: 'inherit',
  outline: 'inherit',
  borderRadius: 0,
};

const textWrapper = {
  ...defaultClickableWrapper,
  '&:hover, &.active': {
    color: 'text',
  },
  '&:focus-visible, &.storybook_pseudo_class--focus-visible': {
    color: 'text',
    borderBottom: '2px solid',
  },
};
const divWrapper = {
  ...defaultClickableWrapper,
  color: 'text',
  width: '100%',
  '&:focus-visible, &.storybook_pseudo_class--focus-visible': {
    borderColor: 'buttonPseudoStatesBorderFocus',
    border: '2px solid',
  },
};
const avatarWrapper = {
  ...defaultClickableWrapper,
  '&:focus-visible, &.storybook_pseudo_class--focus-visible': {
    borderColor: 'currentColor',
  },
};

const styles = {
  default: primary,
  primary,
  primarySmall,
  secondary,
  secondarySmall,
  tertiary,
  tertiarySmall,
  textWrapper,
  divWrapper,
  avatarWrapper,
  light,
  lightSmall,
  ghostLight,
  ghostDark,
  play,
  itemExpander,
  itemExpanderOutlineSmall,
  pagination,
  nav,
  linkDefault,
  textNav,
  breadcrumbText,
  breadcrumbLink,
};

const buttons: TStyles<typeof styles> = styles;
type TButtonVariant = `buttons.${keyof typeof buttons}`;

export {
  buttons as default,
  primary,
  primarySmall,
  secondary,
  secondarySmall,
  tertiary,
  tertiarySmall,
  textWrapper,
  divWrapper,
  avatarWrapper,
  light,
  lightSmall,
  ghostLight,
  ghostDark,
  play,
  itemExpander,
  itemExpanderOutlineSmall,
  pagination,
  nav,
  linkDefault,
  textNav,
  TButtonVariant,
};
