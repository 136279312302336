import { IGraphqlInternalLink } from '../types';
import {
  buildInternalRoute,
  TInternalRoutePageTree,
} from './buildInternalRoute';

const nextPath = (
  path: TInternalRoutePageTree,
  prevLinks?: IGraphqlInternalLink[]
): IGraphqlInternalLink[] => {
  const links: IGraphqlInternalLink[] = prevLinks || [];
  links.push({
    _key: `key-internalLink-${buildInternalRoute(path)}-${path.title}`,
    _type: 'internalLink',
    label: path.title,
    to: { ...path },
  });

  // eslint-disable-next-line no-underscore-dangle
  if (path._type === 'rootPage') {
    return links;
  }
  return nextPath(path.parentPage, links);
};

const buildPathNamesLink = (
  pathNames: TInternalRoutePageTree
): IGraphqlInternalLink[] => nextPath(pathNames).reverse();

export { buildPathNamesLink };
