import { CustomError } from './CustomError';
import { ErrorNames } from './HttpStatusCodes';

class ValidationError extends CustomError {
  errorCode = ErrorNames.E000;

  errorType = 'VALIDATION_ERROR';

  message = 'VALID';

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ValidationError.prototype);
  }

  serializeErrors() {
    return [
      {
        message: this.message || '',
        errorCode: this.errorCode,
        errorType: this.errorType,
      },
    ];
  }
}
export { ValidationError };
