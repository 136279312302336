import {
  NoAddressError,
  NoDataFoundError,
  NoPartnerFoundError,
  NoResponseReceivedError,
} from '../errors';
import { ILocale } from '../locale';
import { API_ENDPOINTS } from '../utils';

interface IAddressSuggestionData {
  geoCity: string;
  geoCountryCodeIso2: string;
  geoCountryCodeIso3: string;
  geoCountryName: string;
  geoFederalStateAbbr: string;
  geoFederalStateName: string;
  geoFormattedAddress: string;
  geoLatitude: number;
  geoLongitude: number;
  geoMatch: string;
  geoPlaceId: string;
  geoPostcode: string;
  geoStreet: string;
  geoStreetNumber: string;
}

/**
 * Fetches the address suggestions from the backend for the given address and optional locale.
 *
 * @param searchString
 * @param locale
 */
const fetchAddressSuggestions = async (
  searchString?: string,
  locale?: ILocale
): Promise<IAddressSuggestionData[]> => {
  if (!searchString) {
    throw new NoAddressError();
  }
  // The AddressService uses the same endpoint as the PartnerService.
  const url = `${API_ENDPOINTS.PARTNER_FINDER}/selectBox`;
  const localeParam = locale
    ? `, country: "${locale?.countryCode.toLowerCase()}", language: "${locale?.languageCode.toLowerCase()}"`
    : '';
  const query = `
  {
    suggestions(query: "${searchString}"${localeParam}) {
      geoCity
      geoCountryCodeIso2
      geoCountryCodeIso3
      geoCountryName
      geoFederalStateAbbr
      geoFederalStateName
      geoFormattedAddress
      geoLatitude
      geoLongitude
      geoMatch
      geoPlaceId
      geoPostcode
      geoStreet
      geoStreetNumber
    }
  }`;

  const requestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  try {
    const response = await fetch(url, requestInit);
    const parsedResponse = await response.json();

    if (!response) {
      throw new NoResponseReceivedError();
    }
    if (!parsedResponse) {
      throw new NoPartnerFoundError();
    }
    if (!parsedResponse.data) {
      throw new NoDataFoundError();
    }
    if (!parsedResponse.data.suggestions) {
      throw new Error('No suggestions in response found');
    }
    return parsedResponse.data.suggestions;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { fetchAddressSuggestions, IAddressSuggestionData };
