const SHADOW_RGB_COLOR = '89, 98, 106';
const SHADOW_OPACITY_DEFAULT = '0.24';

const shadows = {
  none: `0 12px 32px rgba(${SHADOW_RGB_COLOR}, 0)`,
  cardShadow: `0 12px 32px rgba(${SHADOW_RGB_COLOR}, ${SHADOW_OPACITY_DEFAULT})`,
  smallCardShadow: `0 8px 16px rgba(${SHADOW_RGB_COLOR}, ${SHADOW_OPACITY_DEFAULT})`,
  bigCardShadow: `0 10px 16px rgba(${SHADOW_RGB_COLOR}, 0.54)`,
  mapBoxControlsShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
};

export { shadows as default };
