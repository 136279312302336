import { httpStatusCodes } from './HttpStatusCodes';
import { ValidationError } from './ValidationError';

class NoHandleError extends ValidationError {
  errorCode = httpStatusCodes.NO_HANDLE.statusCode;

  errorType = httpStatusCodes.NO_HANDLE.errorType;

  message = 'No handle given.';

  constructor(private customMessage?: string) {
    super(customMessage);
    Object.setPrototypeOf(this, NoHandleError.prototype);
  }

  serializeErrors() {
    return [
      {
        message: this.message,
        customMessage: this.customMessage || '',
        errorCode: this.errorCode,
        errorType: this.errorType,
      },
    ];
  }
}
export { NoHandleError };
