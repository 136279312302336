import { httpStatusCodes } from './HttpStatusCodes';
import { ValidationError } from './ValidationError';

class BadRequestError extends ValidationError {
  errorCode = httpStatusCodes.NOT_ALLOWED.statusCode;

  errorType = httpStatusCodes.NOT_ALLOWED.errorType;

  message = 'Not all required parameters given.';

  constructor(private customMessage?: string) {
    super(customMessage);
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }

  serializeErrors() {
    return [
      {
        message: this.message,
        customMessage: this.customMessage || '',
        errorCode: this.errorCode,
        errorType: this.errorType,
      },
    ];
  }
}
export { BadRequestError };
