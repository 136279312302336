import { createStyleObject } from '@capsizecss/core';
import buildCapsizeStyles from './utils/buildCapsizeStyles';
import { getHeadingSeparatorLineUrl } from './utils/headingSeparatorLineHelpers';

interface IFontMetrics {
  capHeight: number;
  ascent: number;
  descent: number;
  lineGap: number;
  unitsPerEm: number;
}

// The Google font OpenSans. Values are from https://seek-oss.github.io/capsize/
const fontMetricsOpenSans: IFontMetrics = {
  capHeight: 1462,
  ascent: 2189,
  descent: -600,
  lineGap: 0,
  unitsPerEm: 2048,
};

// The Google font Barlow. Values are from https://seek-oss.github.io/capsize/
const fontMetricsBarlow: IFontMetrics = {
  capHeight: 720,
  ascent: 969,
  descent: -251,
  lineGap: 0,
  unitsPerEm: 1000,
};

const capSizes = {
  '6': { capHeight: 6, lineGap: 8 },
  '7': { capHeight: 7, lineGap: 8 },
  '8': { capHeight: 8, lineGap: 8 },
  '9': { capHeight: 9, lineGap: 8 },
  '10': { capHeight: 10, lineGap: 12 },
  '11': { capHeight: 11, lineGap: 13 },
  '12': { capHeight: 12, lineGap: 13 },
  '14': { capHeight: 14, lineGap: 13 },
  '16': { capHeight: 16, lineGap: 14 },
  '20': { capHeight: 20, lineGap: 14 },
  '27': { capHeight: 27, lineGap: 18 },
  '30': { capHeight: 30, lineGap: 22 },
};

type TCapSizeSize = keyof typeof capSizes;
interface ICapSizes {
  [key: string]: TCapSizeSize;
}
const CAP_SIZES: ICapSizes = {
  SIZE_6: '6',
  SIZE_7: '7',
  SIZE_8: '8',
  SIZE_9: '9',
  SIZE_10: '10',
  SIZE_11: '11',
  SIZE_12: '12',
  SIZE_14: '14',
  SIZE_16: '16',
  SIZE_20: '20',
  SIZE_27: '27',
  SIZE_30: '30',
};

const getCapSizeOptions = (size: TCapSizeSize, fontMetrics: IFontMetrics) => ({
  fontMetrics,
  ...capSizes[size],
});

const capsizes = (sizes: TCapSizeSize[], fontMetrics = fontMetricsOpenSans) =>
  buildCapsizeStyles(
    sizes.map((size: TCapSizeSize) =>
      createStyleObject(getCapSizeOptions(size, fontMetrics))
    )
  );

const tinyTextCapsize = capsizes([
  CAP_SIZES.SIZE_8,
  CAP_SIZES.SIZE_9,
  CAP_SIZES.SIZE_9,
]);
const smallTextCapsize = capsizes([
  CAP_SIZES.SIZE_9,
  CAP_SIZES.SIZE_10,
  CAP_SIZES.SIZE_10,
]);
const defaultTextCapsize = capsizes([
  CAP_SIZES.SIZE_10,
  CAP_SIZES.SIZE_11,
  CAP_SIZES.SIZE_11,
]);

const buttonTextCapsize = capsizes([
  CAP_SIZES.SIZE_9,
  CAP_SIZES.SIZE_10,
  CAP_SIZES.SIZE_10,
]);

const headingSeparatorLine = {
  position: 'relative' as const,

  '&:after': {
    content: getHeadingSeparatorLineUrl(),
    color: 'accentBackgroundHumanLine',
    position: 'relative' as const,
    display: 'block',
    width: '100%',
    mb: [-6, -7, -9],
  },
};

const headingSeparatorLineForHeadlineWithoutPaddingBottom = {
  ...headingSeparatorLine,
  '&:after': {
    ...headingSeparatorLine['&:after'],
  },
};

const headingSeparatorLineCentered = {
  ...headingSeparatorLine,

  '&:after': {
    ...headingSeparatorLine['&:after'],
    display: 'block',
    width: '100%',
    textAlign: 'center' as const,
  },
};

const h1 = {
  color: 'text',
  ...capsizes(
    [CAP_SIZES.SIZE_20, CAP_SIZES.SIZE_27, CAP_SIZES.SIZE_30],
    fontMetricsBarlow
  ),
};
const h2 = {
  color: 'text',
  ...capsizes(
    [CAP_SIZES.SIZE_16, CAP_SIZES.SIZE_20, CAP_SIZES.SIZE_27],
    fontMetricsBarlow
  ),
};

const h4 = {
  color: 'text',
  ...capsizes(
    [CAP_SIZES.SIZE_11, CAP_SIZES.SIZE_12, CAP_SIZES.SIZE_14],
    fontMetricsBarlow
  ),
};

const text = {
  default: {
    color: 'text',
    ...defaultTextCapsize,
  },

  tiny: {
    color: 'text',
    ...tinyTextCapsize,
  },
  small: {
    color: 'text',
    ...smallTextCapsize,
  },

  bodyText: {
    color: 'text',
    ...defaultTextCapsize,
  },

  caption: {
    big: {
      color: 'text',
      fontFamily: 'heading',
      ...capsizes(
        [CAP_SIZES.SIZE_12, CAP_SIZES.SIZE_14, CAP_SIZES.SIZE_16],
        fontMetricsBarlow
      ),
    },
    small: {
      color: 'text',
      fontFamily: 'heading',
      ...capsizes(
        [CAP_SIZES.SIZE_10, CAP_SIZES.SIZE_12, CAP_SIZES.SIZE_14],
        fontMetricsBarlow
      ),
    },
    tiny: {
      color: 'textTinyCaption',
      fontFamily: 'heading',
      ...capsizes(
        [CAP_SIZES.SIZE_7, CAP_SIZES.SIZE_8, CAP_SIZES.SIZE_8],
        fontMetricsBarlow
      ),
    },
  },
  topline: {
    color: 'textTopline',
    ...capsizes([CAP_SIZES.SIZE_8, CAP_SIZES.SIZE_9, CAP_SIZES.SIZE_9]),
  },
  nav: {
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'normal',
    letterSpacing: '0.2px',
    cursor: 'pointer',
    textDecoration: 'none',
    borderBottom: '2px solid',
    borderColor: 'transparent',
    '&:hover, &:focus, &.active': {
      color: 'text',
      borderColor: 'currentColor',
    },
  },
  navWithoutHover: {
    color: 'textNavWithoutHover',
    fontFamily: 'body',
    fontWeight: 'bold',
    letterSpacing: '1px',
    py: 4,
    textTransform: 'uppercase' as const,
    ...capsizes([CAP_SIZES.SIZE_6, CAP_SIZES.SIZE_7, CAP_SIZES.SIZE_8]),
  },
  flyoutText: {
    '&, &:link, &:hover, &:focus, &.active': {
      cursor: 'pointer',
      color: 'text',
      fontWeight: 'normal',
      textDecoration: 'none',
      ...smallTextCapsize,
    },
  },

  formInputError: {
    ...capsizes([CAP_SIZES.SIZE_9, CAP_SIZES.SIZE_10, CAP_SIZES.SIZE_10]),

    fontStyle: 'normal',
    px: [2, 3, 4],
    py: [2, 3, 4],
    color: 'danger',
    bg: 'danger.1',
    borderWidth: 'px',
    borderStyle: 'solid',
    borderColor: 'danger.2',
  },
  heading: {
    color: 'text',
    fontFamily: 'body',
    ...capsizes(
      [CAP_SIZES.SIZE_14, CAP_SIZES.SIZE_20, CAP_SIZES.SIZE_27],
      fontMetricsBarlow
    ),
  },
  h1,
  h1WithSeparator: {
    ...h1,
    ...headingSeparatorLine,
  },
  h1WithSeparatorForHeadlineWithoutPaddingBottom: {
    ...h1,
    ...headingSeparatorLineForHeadlineWithoutPaddingBottom,
  },
  h1WithSeparatorCentered: {
    ...h1,
    ...headingSeparatorLineCentered,
  },
  h2,
  h2WithSeparator: {
    ...h2,
    ...headingSeparatorLine,
  },
  h2WithSeparatorForHeadlineWithoutPaddingBottom: {
    ...h2,
    ...headingSeparatorLineForHeadlineWithoutPaddingBottom,
  },
  h2WithSeparatorCentered: {
    ...h2,
    ...headingSeparatorLineCentered,
  },
  h3: {
    color: 'text',
    ...capsizes(
      [CAP_SIZES.SIZE_14, CAP_SIZES.SIZE_16, CAP_SIZES.SIZE_20],
      fontMetricsBarlow
    ),
  },
  h4,
  h4Bold: {
    ...h4,
    fontWeight: 'bold',
  },
  h5: {
    color: 'text',
    ...capsizes(
      [CAP_SIZES.SIZE_9, CAP_SIZES.SIZE_10, CAP_SIZES.SIZE_11],
      fontMetricsBarlow
    ),
  },
  h6: {
    color: 'text',
    letterSpacing: '1px',
    textTransform: 'uppercase' as const,
    fontWeight: 400,
    ...capsizes([CAP_SIZES.SIZE_9, CAP_SIZES.SIZE_10, CAP_SIZES.SIZE_10]),
  },
  buttonLabelSmall: {
    ...capsizes([CAP_SIZES.SIZE_8, CAP_SIZES.SIZE_9, CAP_SIZES.SIZE_9]),
  },
};

const fonts = {
  body: '"Barlow", HelveticaNeue, "Helvetica Neue", Helvetica, sans-serif',
  heading: '"Barlow", serif',
  monospace: 'monospace',
};
const fontWeights = {
  body: 400,
  heading: 400,
  bold: 700,
};

const letterSpacings = [0, '0.0625rem', '0.125rem'];

// "fontSize" is not useful to determine the height of a character in css, therefore the theme-ui fontSizes are not used.
const fontSizes = {};

// "lineHeight" is not useful to determine the distance between two baselines in css, therefore the theme-ui lineHeights are not used.
const lineHeights = {};

export {
  text,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  defaultTextCapsize,
  tinyTextCapsize,
  smallTextCapsize,
  buttonTextCapsize,
};
