/**
 * This check has 2 purposes:
 * a) to verify whether a key exists in an object.
 * b) to show TypeScript the key exists and can be used without any casts.
 *
 * @param obj
 * @param key
 */
const isKeyInObject = <ObjectToCheck extends Record<string, unknown>>(
  obj: ObjectToCheck,
  key: string | number | symbol
): key is keyof ObjectToCheck => key in obj;

export { isKeyInObject };
