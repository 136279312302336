// CMS
// ----------------------------------------------------------------------
const SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_PROJECT_ID = 'milq733q';
// generate key for SANITY_STUDIO_API_PARTNER_LIBRARY_PRODUCTION_PROJECT_ID
const SANITY_STUDIO_API_PARTNER_LIBRARY_PRODUCTION_PROJECT_ID = 'milq733q';
const SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_DEV_DATASET = 'playground';
const SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_STAGE_DATASET = 'stage';
const SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_PROD_DATASET = 'prod';
const SANITY_API_VERSION = '2021-04-23';

// functions
// ----------------------------------------------------------------------
let region;
if (!process.env.SANITY_STUDIO_API_REGION_CODE) {
  console.warn('SANITY_STUDIO_API_REGION_CODE is missing.');
  region = 'europe';
} else {
  region = process.env.SANITY_STUDIO_API_REGION_CODE.toLocaleLowerCase();
}

const FUNCTIONS_BASE_PATH_PREFIX =
  process.env.NODE_ENV === 'development' ||
  process.env.SANITY_STUDIO_PACKAGE_NAME === 'partner-library'
    ? ''
    : `/cms-${region}`;
const FUNCTIONS_ENDPOINT_URL_PREFIX = `${FUNCTIONS_BASE_PATH_PREFIX}/.netlify/functions/`;

const LEGAL_CMS_BASE_PATH_PREFIX =
  process.env.NODE_ENV === 'development' ? '' : '/legal-cms';

// This is a read only token to the production project in Sanity.io.
// https://www.sanity.io/organizations/o85IzTuxf/project/milq733q
const SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_READ_ONLY_TOKEN =
  'skE80vjXYBF3Ru44gpIxFuTiBSgZPGQx2cOyGxLWUxGh1Gp0P9QZSXICrAYDeS0FOZbamEvTdoCSV4dhbp8woqmoNdv0h3jJElLPgupHyESlfNvYAF89PYriCVLHQ6HTdNk5F96bQcILO5g4d8s3HY5DuRxYAO7p3KlK8UZgJIiDUd3Y8AOz';

// External services
const CONSENTMANAGER_CDN_URL = 'cdn.consentmanager.net';
const CURATOR_CDN_URL = 'cdn.curator.io';
const CURATOR_ENDPOINT = `https://${CURATOR_CDN_URL}/published/`;
const GOOGLE_TAG_MANAGER_URL = 'https://www.googletagmanager.com';
const GSTATIC_FONTS_URL = 'https://fonts.gstatic.com';
const MAP_BOX_SEARCH_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const SANITY_CDN_URL = 'cdn.sanity.io';
const TYPEKIT_URL = 'https://use.typekit.net';
const XSTATE_INSPECT_URL = 'https://statecharts.io/inspect';
const CLOUDINARY_HOSTNAME = 'https://res.cloudinary.com';
const MUX_STREAM_HOSTNAME = 'https://stream.mux.com';
const MUX_IMAGE_HOSTNAME = 'https://image.mux.com';
const MUX_API_VIDEO_ASSET_URL = 'https://api.mux.com/video/v1/assets';

// Bemer hostnames
const BEMER_ADDRESS_API_HOST_DEV = 'https://dev.bemer.services/address';

// Bemer sites
const BEMER_EVENT_PORTAL_URL = 'https://events.bemergroup.com';

/**
 * Bemer Partner Level
 *
 * @see https://bergwerkio.atlassian.net/wiki/spaces/BEMER/pages/4054122543/bersicht+Bemer+Partnerlevel+Landesagenturen
 */
const BEMER_PARTNER_LEVELS = {
  IBDT: 35,
  BP: 40,
  BPPLUS: 50,
  GL: 60,
  TM: 70,
  TMPLUS: 80,
  OD: 90,
  ODPLUS: 99,
};

export {
  BEMER_ADDRESS_API_HOST_DEV,
  BEMER_EVENT_PORTAL_URL,
  CONSENTMANAGER_CDN_URL,
  CURATOR_ENDPOINT,
  GOOGLE_TAG_MANAGER_URL,
  GSTATIC_FONTS_URL,
  LEGAL_CMS_BASE_PATH_PREFIX,
  MAP_BOX_SEARCH_API,
  SANITY_API_VERSION,
  SANITY_CDN_URL,
  SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_DEV_DATASET,
  SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_PROD_DATASET,
  SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_PROJECT_ID,
  SANITY_STUDIO_API_PARTNER_LIBRARY_PRODUCTION_PROJECT_ID,
  SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_READ_ONLY_TOKEN,
  FUNCTIONS_ENDPOINT_URL_PREFIX,
  SANITY_STUDIO_API_MARKETING_SITE_PRODUCTION_STAGE_DATASET,
  TYPEKIT_URL,
  XSTATE_INSPECT_URL,
  CLOUDINARY_HOSTNAME,
  MUX_STREAM_HOSTNAME,
  MUX_IMAGE_HOSTNAME,
  MUX_API_VIDEO_ASSET_URL,
  BEMER_PARTNER_LEVELS,
};
