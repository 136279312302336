const enum ErrorNames {
  E000 = 0,
  E001 = 1,
  E002 = 2,
  E003 = 3,
  E004 = 4,
  E005 = 5,
  E006 = 6,
  E007 = 7,
  E008 = 8,
  E009 = 9,
  E010 = 10,
  E011 = 11,
  E012 = 12,
}

const httpStatusCodes = {
  OK: { errorType: 'Ok', statusCode: 200 },
  BAD_REQUEST: { errorType: 'BAD_REQUEST', statusCode: 400 },
  NOT_FOUND: { errorType: 'NOT_FOUND', statusCode: 404 },
  INTERNAL_SERVER: { errorType: 'INTERNAL-SERVER_ERROR', statusCode: 500 },
  NOT_ALLOWED: { errorType: 'NOT_ALLOWED', statusCode: 405 },
  NO_RESPONSE: { errorType: 'NO_RESPONSE', statusCode: 444 },
  NO_RESPONSE_RECEIVED: {
    errorType: 'NO_RESPONSE_RECEIVED',
    statusCode: ErrorNames.E001,
  },
  NO_DATA_FOUND: { errorType: 'NO_DATA_FOUND', statusCode: ErrorNames.E002 },
  NO_SUGGESTION_FOUND: {
    errorType: 'NO_SUGGESTION_FOUND',
    statusCode: ErrorNames.E003,
  },
  NO_LOCALE: { errorType: 'NO_LOCALE', statusCode: ErrorNames.E004 },
  NO_LONGITUDE: { errorType: 'NO_LONGITUDE', statusCode: ErrorNames.E005 },
  NO_LATITUDE: { errorType: 'NO_LATITUDE', statusCode: ErrorNames.E006 },
  NO_WINDOW: { errorType: 'NO_WINDOW', statusCode: ErrorNames.E007 },
  NO_PARAMETER: { errorType: 'NO_PARAMETER', statusCode: ErrorNames.E008 },
  NO_ADDRESS: { errorType: 'NO_ADDRESS', statusCode: ErrorNames.E009 },
  NO_HANDLE: { errorType: 'NO_HANDLE', statusCode: ErrorNames.E010 },
  UNKNOWN_ERROR: { errorType: 'UNKNOWN_ERROR', statusCode: ErrorNames.E011 },
  NO_PARTNER_FOUND: {
    errorType: 'NO_PARTNER_FOUND',
    statusCode: ErrorNames.E012,
  },
};

export { httpStatusCodes, ErrorNames };
