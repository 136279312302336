import { THEME_NAMES } from '@bemer/base';
import { Theme } from 'theme-ui';
import borderWidths from './borderWidths';
import buttons from './buttons';
import colors from './colors';
import forms from './forms';
import grids from './grids';
import links from './links';
import opacities from './opacities';
import radii from './radii';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import transitions from './transitions';
import {
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  text,
} from './typography';
import {
  getHeadingSeparatorLinePseudoElementHumanLine,
  getHeadingSeparatorTildePseudoElementVeterinary,
} from './utils/headingSeparatorLineHelpers';

// Constructor function to have specific types of the theme.
const makeTheme = <T extends Theme>(t: T) => t;
const BOX_SHADOW_PADDING = '0 8px 16px';

// Please follow https://theme-ui.com/theme-spec
const theme = makeTheme({
  // Defining n breakpoints allows to set n+1 css values.
  // i.e. ['600px', '1120px'] => 3 ranges:
  //    1. 0px to 599px
  //    2. 600px to 1119px
  //    3. 1120px to infinity
  breakpoints: ['600px', '1120px'],
  colors,

  space,
  sizes,
  radii,
  borderWidths,

  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  text,

  grids,
  buttons,
  links,

  shadows,
  transitions,

  opacities,

  forms,

  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
    },
    p: {
      pb: 8,
    },
    a: {
      ...links.default,
    },
    img: {
      maxWidth: '100%',
    },
  },
});

// Creates the type of the theme which is used in the application.
type TTheme = typeof theme;
const defaultTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    background: theme.colors.background,
    text: theme.colors.text,
    textMuted: theme.colors.textMuted,
  },
};

// Theme modifications
// ================================================================================================

const buttonBoxShadowHumanLineTheme = {
  '&:hover, &.storybook_pseudo_class--hover': {
    boxShadow: `${BOX_SHADOW_PADDING} rgba(0,0,0, 0.35)`,
  },
  '&:focus:hover, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover':
    {
      boxShadow: `${BOX_SHADOW_PADDING} rgba(0,0,0, 0.45)`,
    },
};
const humanLineTheme = {
  ...theme,
  buttons: {
    primary: buttonBoxShadowHumanLineTheme,
    primarySmall: buttonBoxShadowHumanLineTheme,
  },
  links: {
    buttonPrimary: buttonBoxShadowHumanLineTheme,
    buttonPrimarySmall: buttonBoxShadowHumanLineTheme,
  },
  colors: {
    ...theme.colors,
    background: theme.colors.accentBackgroundHumanLine,
    backgroundGradientStart:
      theme.colors.accentBackgroundGradientStartHumanLine,
    backgroundGradientEnd: theme.colors.accentBackgroundGradientEndHumanLine,
    accentColorOppositeTheme: theme.colors.accentBackgroundVeterinaryLine,

    text: theme.colors.textOnAccentBackgroundHumanLine,
    textSecondary: theme.colors.textSecondaryOnAccentBackgroundHumanLine,
    textTinyCaption: theme.colors.textTinyCaptionOnAccentBackgroundHumanLine,
    textTopline: theme.colors.textToplineOnAccentBackgroundHumanLine,
    textNavWithoutHover:
      theme.colors.textNavWithoutHoverOnAccentBackgroundHumanLine,
    textMuted: theme.colors.textMutedOnAccentBackgroundHumanLine,

    buttonPrimaryText:
      theme.colors.buttonPrimaryTextOnAccentBackgroundHumanLine,
    buttonPrimaryBackground:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundHumanLine,
    buttonPrimaryBorder:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundHumanLine,

    buttonSecondaryBackground:
      theme.colors.buttonSecondaryBackgroundOnAccentBackgroundHumanLine,

    buttonPseudoStatesText:
      theme.colors.buttonPrimaryTextOnAccentBackgroundHumanLine,
    buttonPseudoStatesBackground:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundHumanLineHover,
    buttonPseudoStatesBorder:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundHumanLine,

    buttonPseudoStatesTextFocus:
      theme.colors.buttonPrimaryTextOnAccentBackgroundHumanLine,
    buttonPseudoStatesBackgroundFocus:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundHumanLineFocus,
    buttonPseudoStatesBorderFocus:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundHumanLine,

    buttonPseudoStatesTextActive:
      theme.colors.buttonPrimaryTextOnAccentBackgroundHumanLine,
    buttonPseudoStatesBackgroundActive:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundHumanLineFocus,
    buttonPseudoStatesBorderActive:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundHumanLine,

    ghostLightButtonText:
      theme.colors.ghostLightButtonTextOnAccentBackgroundHumanLine,
    ghostLightButtonBorder:
      theme.colors.ghostLightButtonBorderOnAccentBackgroundHumanLine,
    ghostLightButtonBackgroundHover:
      theme.colors.ghostLightButtonBackgroundOnAccentBackgroundHoverHumanLine,
    ghostLightButtonTextDisabled:
      theme.colors.ghostLightButtonTextOnAccentBackgroundHumanLineDisabled,
    buttonTertiaryText:
      theme.colors.buttonTertiaryTextOnAccentBackgroundHumanLine,
    ghostDarkButtonText:
      theme.colors.ghostDarkButtonTextOnAccentBackgroundHumanLine,
    breadCrumbText: theme.colors.breadCrumbTextOnAccentBackgroundHumanLine,
    breadCrumbLink: theme.colors.breadCrumbLinkOnAccentBackgroundHumanLine,
    link: theme.colors.linkOnAccentBackgroundHumanLine,
    linkHover: theme.colors.linkOnAccentBackgroundHumanLineHover,
    linkActive: theme.colors.linkOnAccentBackgroundHumanLineActive,
    footerLinkHover: theme.colors.footerLinkOnAccentBackgroundHumanLineHover,
    footerLinkActive: theme.colors.footerLinkOnAccentBackgroundHumanLineActive,
    logoColor: theme.colors.logoColorOnAccentBackgroundHumanLine,
    logoAccentColor: theme.colors.logoAccentColorOnAccentBackgroundHumanLine,
  },
  text: {
    h1WithSeparator: getHeadingSeparatorLinePseudoElementHumanLine(),
    h1WithSeparatorForHeadlineWithoutPaddingBottom:
      getHeadingSeparatorLinePseudoElementHumanLine(),
    h1WithSeparatorCentered: getHeadingSeparatorLinePseudoElementHumanLine(),
    h2WithSeparator: getHeadingSeparatorLinePseudoElementHumanLine(),
    h2WithSeparatorForHeadlineWithoutPaddingBottom:
      getHeadingSeparatorLinePseudoElementHumanLine(),
    h2WithSeparatorCentered: getHeadingSeparatorLinePseudoElementHumanLine(),
  },
};
const buttonBoxShadowNeutralTheme = {
  '&:hover, &.storybook_pseudo_class--hover': {
    boxShadow: `${BOX_SHADOW_PADDING} rgba(23, 151, 203, 0.55)`,
  },
  '&:focus:hover, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover':
    {
      boxShadow: `${BOX_SHADOW_PADDING} rgba(23, 151, 203, 0.65)`,
    },
};
const neutralTheme = {
  ...theme,
  buttons: {
    primary: buttonBoxShadowNeutralTheme,
    primarySmall: buttonBoxShadowNeutralTheme,
  },
  links: {
    buttonPrimary: buttonBoxShadowNeutralTheme,
    buttonPrimarySmall: buttonBoxShadowNeutralTheme,
  },
  colors: {
    ...theme.colors,

    background: theme.colors.accentBackgroundNeutral,
    backgroundGradientStart: theme.colors.accentBackgroundGradientStartNeutral,
    backgroundGradientEnd: theme.colors.accentBackgroundGradientEndNeutral,
    accentColorOppositeTheme: theme.colors.white,
    text: theme.colors.textOnAccentBackgroundNeutral,
    textSecondary: theme.colors.textSecondaryOnAccentBackgroundNeutral,
    textTinyCaption: theme.colors.textTinyCaptionOnAccentBackgroundNeutral,
    textTopline: theme.colors.textToplineOnAccentBackgroundNeutral,
    textNavWithoutHover:
      theme.colors.textNavWithoutHoverOnAccentBackgroundNeutral,
    textMuted: theme.colors.textMutedOnAccentBackgroundVeterinaryLine,

    buttonPrimaryText: theme.colors.buttonPrimaryTextOnAccentBackgroundNeutral,
    buttonPrimaryBackground:
      theme.colors.buttonPrimaryBackgroundOnPageBackgroundNeutral,
    buttonPrimaryBorder:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundNeutral,

    buttonPseudoStatesText:
      theme.colors.buttonPrimaryTextOnAccentBackgroundNeutral,
    buttonPseudoStatesBackground:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundNeutralHover,
    buttonPseudoStatesBorder:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundNeutral,

    buttonPseudoStatesTextFocus:
      theme.colors.buttonPrimaryTextOnAccentBackgroundNeutral,
    buttonPseudoStatesBackgroundFocus:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundNeutralFocus,
    buttonPseudoStatesBorderFocus:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundNeutral,

    buttonPseudoStatesTextActive:
      theme.colors.buttonPrimaryTextOnAccentBackgroundNeutral,
    buttonPseudoStatesBackgroundActive:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundNeutralFocus,
    buttonPseudoStatesBorderActive:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundNeutral,

    ghostLightButtonText:
      theme.colors.ghostLightButtonTextOnAccentBackgroundNeutral,
    ghostLightButtonBorder:
      theme.colors.ghostLightButtonBorderOnAccentBackgroundNeutral,
    ghostLightButtonBackgroundHover:
      theme.colors.ghostLightButtonBackgroundOnAccentBackgroundNeutralHover,
    ghostLightButtonTextDisabled:
      theme.colors.ghostLightButtonTextOnAccentBackgroundNeutralDisabled,
    ghostDarkButtonBorder:
      theme.colors.ghostDarkButtonBorderOnAccentBackgroundNeutral,
    ghostDarkButtonText:
      theme.colors.ghostDarkButtonTextOnAccentBackgroundNeutral,
    ghostDarkButtonTextDisabled:
      theme.colors.ghostDarkButtonTextOnAccentBackgroundNeutralDisabled,
    breadCrumbLink: theme.colors.breadCrumbLinkOnAccentBackgroundNeutral,
    link: theme.colors.linkOnAccentBackgroundNeutral,
    linkHover: theme.colors.linkOnAccentBackgroundNeutralHover,
    linkActive: theme.colors.linkOnAccentBackgroundNeutralActive,
  },
};

const headingWithSeperatorVeterinaryLine = {
  '&:after': {
    content: getHeadingSeparatorTildePseudoElementVeterinary(),
    position: 'relative' as const,
    display: 'block',
    width: '100%',
    pt: [2, 4, 6],
    mb: [-6, -6, -6],
  },
};

const h1WithSeperatorCenteredVeterinaryLine = {
  '&:after': {
    ...headingWithSeperatorVeterinaryLine['&:after'],
    textAlign: 'center' as const,
    pt: [0, 3, 6],
    mb: [-7, -12, -16],
  },
};

const h2WithSeperatorCenteredVeterinaryLine = {
  '&:after': {
    ...h1WithSeperatorCenteredVeterinaryLine['&:after'],
    pt: [0, 3, 4],
    mb: [-5, -10, -12],
  },
};

const headingWithSeparatorForHeadlineWithoutPaddingBottom = {
  '&:after': {
    ...headingWithSeperatorVeterinaryLine['&:after'],
    pt: [1, 4, 6],
    mb: [-7, -10, -12],
  },
};
const veterinaryLineTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    // background: {
    //   ...theme.colors.darkPetrol,
    //   __default: theme.colors.darkPetrol['7'],
    // },
    background: theme.colors.accentBackgroundVeterinaryLine,
    backgroundGradientStart:
      theme.colors.accentBackgroundGradientStartVeterinaryLine,
    backgroundGradientEnd:
      theme.colors.accentBackgroundGradientEndVeterinaryLine,
    accentColorOppositeTheme: theme.colors.accentBackgroundHumanLine,
    text: theme.colors.textOnAccentBackgroundVeterinaryLine,
    textSecondary: theme.colors.textSecondaryOnAccentBackgroundVeterinaryLine,
    textTinyCaption:
      theme.colors.textTinyCaptionOnAccentBackgroundVeterinaryLine,
    textTopline: theme.colors.textToplineOnAccentBackgroundVeterinaryLine,
    textNavWithoutHover:
      theme.colors.textNavWithoutHoverOnAccentBackgroundVeterinaryLine,
    textMuted: theme.colors.textMutedOnAccentBackgroundNeutral,

    ghostLightButtonText:
      theme.colors.ghostLightButtonTextOnAccentBackgroundVeterinaryLine,
    ghostLightButtonBorder:
      theme.colors.ghostLightButtonBorderOnAccentBackgroundVeterinaryLine,
    ghostLightButtonBackgroundHover:
      theme.colors
        .ghostLightButtonBackgroundOnAccentBackgroundVeterinaryLineHover,
    ghostLightButtonTextDisabled:
      theme.colors.ghostLightButtonTextOnAccentBackgroundVeterinaryLineDisabled,
    ghostDarkButtonText:
      theme.colors.ghostDarkButtonTextOnAccentBackgroundVeterinaryLine,
    ghostDarkButtonBackgroundHover:
      theme.colors
        .ghostDarkButtonBackgroundOnAccentBackgroundVeterinaryLineHover,
    ghostDarkButtonTextDisabled:
      theme.colors.ghostDarkButtonTextOnAccentBackgroundVeterinaryLineDisabled,
    breadCrumbText: theme.colors.breadCrumbTextOnAccentBackgroundVeterinaryLine,
    breadCrumbLink: theme.colors.breadCrumbLinkOnAccentBackgroundVeterinaryLine,
    link: theme.colors.linkOnAccentBackgroundVeterinaryLine,
    linkHover: theme.colors.linkOnAccentBackgroundVeterinaryLineHover,
    linkActive: theme.colors.linkOnAccentBackgroundVeterinaryLineActive,
    logoColor: theme.colors.logoColorOnAccentBackgroundVeterinaryLine,
    logoAccentColor:
      theme.colors.logoAccentColorOnAccentBackgroundVeterinaryLine,
  },
  text: {
    h1WithSeparator: {
      ...headingWithSeperatorVeterinaryLine,
    },
    h1WithSeparatorForHeadlineWithoutPaddingBottom: {
      ...headingWithSeparatorForHeadlineWithoutPaddingBottom,
    },
    h1WithSeparatorCentered: {
      ...h1WithSeperatorCenteredVeterinaryLine,
    },
    h2WithSeparator: {
      ...headingWithSeperatorVeterinaryLine,
    },
    h2WithSeparatorForHeadlineWithoutPaddingBottom: {
      ...headingWithSeparatorForHeadlineWithoutPaddingBottom,
    },
    h2WithSeparatorCentered: {
      ...h2WithSeperatorCenteredVeterinaryLine,
    },
  },
};

const lightTextTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    text: 'white',
  },
  // will be used in the future
  // links: {
  //   ...theme.links,
  //   buttonTertiary: {
  //     ...theme.links.buttonTertiary,
  //   }
  // }
};

//
const darkTextTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    text: 'black',
  },
};
const buttonBoxShadowDefaultVeterinaryTheme = {
  '&:hover, &.storybook_pseudo_class--hover': {
    boxShadow: `${BOX_SHADOW_PADDING} rgba(0, 37, 37, 0.55)`,
  },
  '&:focus:hover, &.storybook_pseudo_class--focus&.storybook_pseudo_class--hover':
    {
      boxShadow: `${BOX_SHADOW_PADDING} rgba(0, 37, 37, 0.65)`,
    },
};
const defaultVeterinaryTheme = {
  ...theme,
  buttons: {
    primary: buttonBoxShadowDefaultVeterinaryTheme,
    primarySmall: buttonBoxShadowDefaultVeterinaryTheme,
  },
  links: {
    buttonPrimary: buttonBoxShadowDefaultVeterinaryTheme,
    buttonPrimarySmall: buttonBoxShadowDefaultVeterinaryTheme,
  },
  colors: {
    ...theme.colors,

    buttonPrimaryText:
      theme.colors.buttonPrimaryTextOnAccentBackgroundDefaultVeterinary,
    buttonPrimaryBackground:
      theme.colors.buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinary,
    buttonPrimaryBorder:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundDefaultVeterinary,

    buttonPseudoStatesText:
      theme.colors.buttonPrimaryTextOnAccentBackgroundDefaultVeterinary,
    buttonPseudoStatesBackground:
      theme.colors
        .buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryHover,
    buttonPseudoStatesBorder:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundDefaultVeterinary,

    buttonPseudoStatesTextFocus:
      theme.colors.buttonPrimaryTextOnAccentBackgroundDefaultVeterinary,
    buttonPseudoStatesBackgroundFocus:
      theme.colors
        .buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryFocus,
    buttonPseudoStatesBorderFocus:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundDefaultVeterinary,

    buttonPseudoStatesTextActive:
      theme.colors.buttonPrimaryTextOnAccentBackgroundDefaultVeterinary,
    buttonPseudoStatesBackgroundActive:
      theme.colors
        .buttonPrimaryBackgroundOnAccentBackgroundDefaultVeterinaryFocus,
    buttonPseudoStatesBorderActive:
      theme.colors.buttonPrimaryBorderOnAccentBackgroundDefaultVeterinary,
  },
  text: {
    ...theme.text,
    h1WithSeparator: {
      ...theme.text.h1WithSeparator,
      ...headingWithSeperatorVeterinaryLine,
    },
    h1WithSeparatorForHeadlineWithoutPaddingBottom: {
      ...theme.text.h1WithSeparatorForHeadlineWithoutPaddingBottom,
      ...headingWithSeparatorForHeadlineWithoutPaddingBottom,
    },
    h1WithSeparatorCentered: {
      ...theme.text.h1WithSeparatorCentered,
      ...h1WithSeperatorCenteredVeterinaryLine,
    },
    h2WithSeparator: {
      ...headingWithSeperatorVeterinaryLine,
    },
    h2WithSeparatorForHeadlineWithoutPaddingBottom: {
      ...theme.text.h2WithSeparatorForHeadlineWithoutPaddingBottom,
      ...headingWithSeparatorForHeadlineWithoutPaddingBottom,
    },
    h2WithSeparatorCentered: {
      ...theme.text.h2WithSeparatorCentered,
      ...h2WithSeperatorCenteredVeterinaryLine,
    },
  },
};

const lightTextDefaultVeterinaryTheme = {
  ...defaultVeterinaryTheme,
  colors: {
    ...theme.colors,
    text: 'white',
  },
};

const darkTextDefaultVeterinaryTheme = {
  ...defaultVeterinaryTheme,
  colors: {
    ...theme.colors,
    text: 'black',
  },
};

const themes = {
  [THEME_NAMES.HUMAN_LINE]: humanLineTheme,
  [THEME_NAMES.VETERINARY_LINE]: veterinaryLineTheme,
  [THEME_NAMES.NEUTRAL]: neutralTheme,
  [THEME_NAMES.DEFAULT]: defaultTheme,
  [THEME_NAMES.LIGHT_TEXT]: lightTextTheme,
  [THEME_NAMES.DARK_TEXT]: darkTextTheme,
  [THEME_NAMES.DEFAULT_VETERINARY]: defaultVeterinaryTheme,
  [THEME_NAMES.LIGHT_TEXT_DEFAULT_VETERINARY]: lightTextDefaultVeterinaryTheme,
  [THEME_NAMES.DARK_TEXT_DEFAULT_VETERINARY]: darkTextDefaultVeterinaryTheme,
};

// Default export is still required by ThemeUi to work.
export {
  theme as default,
  theme,
  defaultTheme,
  humanLineTheme,
  veterinaryLineTheme,
  lightTextTheme,
  darkTextTheme,
  neutralTheme,
  defaultVeterinaryTheme,
  darkTextDefaultVeterinaryTheme,
  lightTextDefaultVeterinaryTheme,
  TTheme,
  themes,
};
