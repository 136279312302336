import {
  allCountries as countries,
  ILocale,
  languages,
  regions,
  TCountryCode,
  TLanguageCode,
  TLocaleId,
  TLocaleIdBcp47,
} from '../locale';
import { IGraphqlLocaleIdentifier } from '../types';

const DEFAULT_LOCALE_ID: TLocaleId = 'en_LI';
const DEFAULT_LOCALE_NAME = languages.english.languageName;
const DEFAULT_LOCALE_LANGUAGE_CODE = languages.english.languageCode;
const DEFAULT_LOCALE_COUNTRY_NAME = countries.liechtenstein.countryName;
const DEFAULT_LOCALE_COUNTRY_CODE = countries.liechtenstein.countryCode;
const DEFAULT_LOCALE_REGION_NAME = regions.europe.regionName;
const DEFAULT_LOCALE_REGION_CODE = regions.europe.regionCode;

const getCountryCodeFromLocale = (localeId: TLocaleId): TCountryCode =>
  localeId.split('_')[1] as TCountryCode;

const getLanguageCodeFromLocale = (localeId: TLocaleId): TLanguageCode =>
  localeId.split('_')[0] as TLanguageCode;

const buildLocale = (localeId: TLocaleId): ILocale => ({
  id: localeId,
  bcp47Id: localeId.replace('_', '-') as TLocaleIdBcp47,
  languageCode: getLanguageCodeFromLocale(localeId),
  countryCode: getCountryCodeFromLocale(localeId),
});

// The parameters for the defaultLocale have to be confirmed by the CMS admins to be the desired ones.
const defaultLocale = buildLocale(DEFAULT_LOCALE_ID);

const defaultLocaleIdentifier: IGraphqlLocaleIdentifier = {
  _id: 'defaultLocaleIdentifier',
  _key: 'defaultLocaleIdentifier',
  _type: 'localeIdentifier',
  localeId: DEFAULT_LOCALE_ID,
  active: true,
  default: true,
  languageName: DEFAULT_LOCALE_NAME,
  languageCode: DEFAULT_LOCALE_LANGUAGE_CODE,
  country: {
    _id: 'defaultLocaleIdentifier-country',
    _key: 'defaultLocaleIdentifier-country',
    _type: 'country',
    countryName: DEFAULT_LOCALE_COUNTRY_NAME,
    countryCode: DEFAULT_LOCALE_COUNTRY_CODE,
    region: {
      _id: 'defaultLocaleIdentifier-region',
      _key: 'defaultLocaleIdentifier-region',
      _type: 'region',
      regionName: DEFAULT_LOCALE_REGION_NAME,
      regionCode: DEFAULT_LOCALE_REGION_CODE,
    },
    isRegionMaster: false,
  },
};
export {
  getCountryCodeFromLocale,
  getLanguageCodeFromLocale,
  buildLocale,
  defaultLocale,
  defaultLocaleIdentifier,
};
